import { isMobile } from "react-device-detect";
import qs from "qs";
import i18next from "i18next";

import axios from "libs/axios";

import { FORM_CONTENT_TYPE } from "actions/config";
import { deleteDownRejectAuth } from "actions/setting.vodreject.list.action";

import { DELETE } from "constant/method";

import { toastr } from "components/toastr";

export const FETCH_VODAUTHLIST = "FETCH_VODAUTHLIST";
export const FETCH_VODAUTHLIST_SUCCESS = "FETCH_VODAUTHLIST_SUCCESS";
export const FETCH_VODAUTHLIST_FAILURE = "FETCH_VODAUTHLIST_FAILURE";

export const DELETE_VODAUTHLIST_SUCCESS = "DELETE_VODAUTHLIST_SUCCESS";
export const DELETE_VODAUTHLIST_FAILURE = "DELETE_VODAUTHLIST_FAILURE";

export const STORE_VODAUTHLIST_SUCCESS = "STORE_VODAUTHLIST_SUCCESS";
export const STORE_VODAUTHLIST_FAILURE = "STORE_VODAUTHLIST_FAILURE";

export const CLEAR_VODAUTHLIST = "CLEAR_VODAUTHLIST";

export const STORE_VOD_AUTH_SUCCESS = "STORE_VOD_AUTH_SUCCESS";
export const DELETE_VOD_AUTH_SUCCESS = "DELETE_VOD_AUTH_SUCCESS";

/**
 * vod 권한 리스트 조회
 * @param page
 * @param word
 * @param type
 * @returns {function(...[*]=)}
 */
export const fetchVodAuthList = (page = 1, word = "", type = "user_id") => async (dispatch) => {
    dispatch({ type: FETCH_VODAUTHLIST });

    try {
        const params = { page, mobile: isMobile ? 1 : 0 };
        if (word) {
            params.word = word;
            params.type = type;
        }

        const response = await axios.get(`/${global.bj_id}/vodauthlist`, { params });
        dispatch({
            type: FETCH_VODAUTHLIST_SUCCESS,
            payload: response,
        });
    } catch (error) {
        dispatch({
            type: FETCH_VODAUTHLIST_FAILURE,
            payload: error ? error : { code: 0, message: error },
        });
    }
};

/**
 * VOD 권한리스트에서 제거
 * @param idx
 * @returns {function(...[*]=)}
 */
export const deleteVodAuthListApi = (idx) => async (dispatch) => {
    try {
        const data = qs.stringify({ _method: DELETE, mobile: isMobile ? 1 : 0 });
        const response = await axios.post(`/${global.bj_id}/vodauthlist/${idx}`, data, { headers: FORM_CONTENT_TYPE });

        toastr.success(i18next.t("VOD 권한이 해제됐습니다."), {
            timeOut: 2000,
        });

        dispatch({
            type: DELETE_VODAUTHLIST_SUCCESS,
            payload: response,
        });
    } catch (error) {
        toastr.error(error.message, {
            timeOut: 2000,
        });

        dispatch({
            type: DELETE_VODAUTHLIST_FAILURE,
            payload: error ? error : { code: 0, message: error },
        });

        throw error;
    }
};

/**
 * VOD 권한 리스트에 아이디 추가
 * @param data
 * @returns {function(...[*]=)}
 */
export const storeVodAuthListApi = (data) => async (dispatch) => {
    try {
        const { user_id = "" } = data;
        const response = await axios.post(`/${global.bj_id}/vodauthlist`, qs.stringify({ user_id }));

        dispatch({
            type: STORE_VODAUTHLIST_SUCCESS,
            payload: response,
        });

        toastr.success(i18next.t("VOD 권한이 추가됐습니다."), {
            timeOut: 2000,
        });
    } catch (error) {
        toastr.error(error.message, {
            timeOut: 2000,
        });

        dispatch({
            type: STORE_VODAUTHLIST_FAILURE,
            payload: error ? error : { code: 0, message: error },
        });

        throw error;
    }
};

/**
 * VOD 권한 추가
 * @param userId
 * @param type
 * @returns {function(...[*]=)}
 */
export const storeVodAuth = (userId, type) => async (dispatch) => {
    try {
        // 업로더 권한 추가
        await axios.post(`/${global.bj_id}/vodauth`, qs.stringify({ user_id: userId, type }), {
            headers: FORM_CONTENT_TYPE,
        });

        dispatch({ type: STORE_VOD_AUTH_SUCCESS, payload: { user_id: userId, type } });
    } catch (error) {
        // 다운로드 제한이 걸려 있을 경우
        if (error.code === 5000) {
            if (window.confirm(error.message)) {
                await dispatch(deleteDownRejectAuth(userId)); // 다운로드 제한 해제
                await dispatch(storeVodAuth(userId, type)); // 저장 다시 시도
            }
            return;
        }

        toastr.error(error.message, {
            timeOut: 2000,
        });
    }
};

/**
 * VOD 권한 삭제
 * @param userId
 * @param type
 * @returns {function(...[*]=)}
 */
export const deleteVodAuth = (userId, type) => async (dispatch) => {
    try {
        // 업로더 권한 삭제
        await axios.post(`/${global.bj_id}/vodauth/${type}`, qs.stringify({ _method: DELETE, user_id: userId }), {
            headers: FORM_CONTENT_TYPE,
        });

        dispatch({ type: DELETE_VOD_AUTH_SUCCESS, payload: { user_id: userId, type } });
    } catch (error) {
        toastr.error(error.message);
    }
};

/**
 * VOD 권한 리스트 초기화
 * @returns {{type: string}}
 */
export const clearVodAuthList = () => ({ type: CLEAR_VODAUTHLIST });
