import { getSessionItem } from "helpers";
import React from "react";
import { Trans } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";
import Live from "components/left/Live";
import Menu from "components/left/Menu";
import Profile from "components/left/Profile";
import InfoTopFan from "components/contents/InfoTopFan";

const Leftpane = (props) => {

    const fixBarRef = React.useRef(null);
    
    const {
        sticker_top,
        starballoon_top,
        station,
        broad,
        is_partner_bj,
        is_best_bj,
        is_owner,
        is_manager,
        profile_image,
    } = props.stationInfo.data;
    
    const { home } = useSelector(({homeReducer})=>{
        const { home = false } =  homeReducer;
        return {
            home: home
        }
    },shallowEqual);

    const { id } = useParams();
    const location = useLocation();
    const history = useHistory();
    
    const handleWrite = (e) => {
        const aPath = location.pathname.split("/");
        let bbs_no;
        if (aPath[2] === "post" && aPath[3] === "write") {
            e.stopPropagation();
            e.preventDefault();
            return;
        } else if (aPath[2] === "posts") {
            bbs_no = parseInt(aPath[3]);
        } else if (aPath[2] === "post") {
            bbs_no = getSessionItem("post_bbs_no");
        }
        const writeUrl = bbs_no ? `/${global.bj_id}/redirect/write/${bbs_no}` : `/${global.bj_id}/redirect/write`;
        history.push(writeUrl);
    };

    return (
        <div id="bs-navi" className="">
            <div className="bs-navi_contents sticky-menu">
                <article className="article_bj_box">
                    {/* BJ 정보 */}
                    <Profile
                        id = {id}
                        path = {location.pathname}
                        station={station}
                        is_partner_bj={is_partner_bj}
                        is_best_bj={is_best_bj}
                        is_owner={is_owner}
                        profile_image={profile_image}
                    />
                    </article>
                    <article>
                    {/* //라이브 미리보기 */}
                    {broad && <Live broad={broad} />}
                    {/* //BJ 정보 */}
                    <button type="button" className="btn_dft" onClick={handleWrite}>
                        <span>
                            <Trans>글쓰기</Trans>
                        </span>
                    </button>
                    {/* 열혈팬 리스트 */}
                    <InfoTopFan topfans={starballoon_top} isHome={home} printType="STARBALLOON" />
                    {/* 서포터 리스트 */}
                    <InfoTopFan topfans={sticker_top} isHome={home} printType="SUPPORTER" />

                    {/* //라이브 미리보기 */}
                    {(is_owner || is_manager) && (
                        <div className="btn_adm_wrap">
                            <Link to={`/${id}/setting`} className="btn_dft adm">
                                <span>
                                    <Trans>채널 관리</Trans>
                                </span>
                            </Link>
                        </div>
                    )}
                </article>
                <Menu fixBar={fixBarRef} />
            </div>
        </div>
    );
}

export default Leftpane;