import React, { Component } from "react";
import { connect } from "react-redux";
import ReactSwipe from "react-swipe";
import classNames from "classnames";
import { fetchFlotingBanner } from "../../../actions/banner.action";
import { setClickLogapi } from "helpers";
class FloatingBanner extends Component {
	constructor(props) {
		super(props);
		this.state = { selectedIndex: 0 };
	}

	componentDidMount() {
		this.props.fetchFlotingBanner();
	}
	handleSwipeCallback = (index) => {
		this.setState({
			selectedIndex: index,
		});
	}

	handleNavigateClick = (index) => {
		this.reactSwipeEl.slide(index);
	}

	renderBanner = (banner, index = 0) => {
		return (
			<a key={index} href={banner.link || "javascript:;"} target={banner.link ? "_blank" : ""} rel="noopener noreferrer" 
				onClick={() => setClickLogapi('station_click', { button_group: 'banner', button_type: 'right_banner'})}>
				<img src={banner.url} alt={banner.alt} />
			</a>
		);
	};
	render() {
		const { selectedIndex } = this.state;
		const { bannerReducer } = this.props;
		const { banners = [] } = bannerReducer;
		if (banners.length > 0) {
			return (
				<React.Fragment>
					<ReactSwipe
						swipeOptions={{
							speed: 600,
							continuous: true,
							stopPropagation: true,
							auto: 5000,
							loop: true,
							freeMode: true,
							startSlide: selectedIndex,
							transitionEnd: (index) => this.handleSwipeCallback(index),
						}}
						ref={(ref) => (this.reactSwipeEl = ref)}
					>
						{banners.map((banner, index) => (
							<div key={index} className="bnr_wrap">
								<div>{this.renderBanner(banner, index)}</div>
							</div>
						))}
						{/* 2개면 4개를 만들어서 슬라이딩을 함 */}
						{banners.length === 2 &&
							banners.map((banner, index) => (
								<div key={index + 10} className="bnr_wrap">
									<div>{this.renderBanner(banner, index)}</div>
								</div>
							))}
					</ReactSwipe>
					{banners.length > 1 && (
						<div className="indicator">
							{banners.map((banner, index) => (
								<button
									type="button"
									key={index}
									className={classNames({ on: selectedIndex === index })}
									onClick={() => this.handleNavigateClick(index)}
								>
									{index}
								</button>
							))}
						</div>
					)}
				</React.Fragment>
			);
		} else {
			return null;
		}
	}
}

const mapStateToProps = (state) => {
	return {
		bannerReducer: state.bannerReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchFlotingBanner: () => {
			dispatch(fetchFlotingBanner());
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(FloatingBanner);
