import { BJ_API_DOMAIN } from "constant/config";
import {  handleFetchErrors, handleFailedToFetch } from "../helpers";

export const FETCH_STATISTIC = "FETCH_STATISTIC";
export const FETCH_STATISTIC_SUCCESS = "FETCH_STATISTIC_SUCCESS";
export const FETCH_STATISTIC_FAILURE = "FETCH_STATISTIC_FAILURE";

export function fetchStatistic() {
	return (dispatch) => {
		const url = `${BJ_API_DOMAIN}/${global.bj_id}/setting/statistic`;
		dispatch({ type: FETCH_STATISTIC });
		fetch(url, {
			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then((response) => {				
				dispatch({
					type: FETCH_STATISTIC_SUCCESS,
					payload: response,
				});
			})
			.catch((error) => {
				dispatch({
					type: FETCH_STATISTIC_FAILURE,
					payload: error ? error : { code: 0, message: error },
				});
			});
	};
}