window.VOTE_FRAME = [];

export const addVoteIFrame = (iframe) => {
    console.log('window.VOTE_FRAME.indexOf(iframe)', window.VOTE_FRAME.indexOf(iframe))
    if (window.VOTE_FRAME.indexOf(iframe) > -1)
        return false;
    window.VOTE_FRAME = [...window.VOTE_FRAME, iframe]
}
export const getVoteIFrames = () => {
    return window.VOTE_FRAME
}