import qs from "qs";

import axios from "libs/axios";

import { OLD_LIVE_DOMAIN } from "constant/config";
import { getDefaultMessage } from "constant/error";
import { CLEAR } from "constant/status";

export const FETCH_SIGNATURE_EMOTICON_LIST = "FETCH_SIGNATURE_EMOTICON_LIST";
export const FETCH_SIGNATURE_EMOTICON_LIST_FAILURE = "FETCH_SIGNATURE_EMOTICON_LIST_FAILURE";
export const FETCH_SIGNATURE_EMOTICON_LIST_SUCCESS = "FETCH_SIGNATURE_EMOTICON_LIST_SUCCESS";

// 시그니처 이모티콘 리스트 조회
export const fetchSignatureEmoticonList = () => async (dispatch, getState) => {
    // 이미 데이터가 있거나 로딩 중일 경우 실행 중지
    const { emoticonSignatureReducer } = getState();
    if (emoticonSignatureReducer.status !== CLEAR) return;

    dispatch({ type: FETCH_SIGNATURE_EMOTICON_LIST });

    const params = {
        work: "list",
        szBjId: global.bj_id,
        nState: 2,
        v:'tier'
    };

    try {
        const response = await axios.post(`${OLD_LIVE_DOMAIN}/api/signature_emoticon_api.php`, qs.stringify(params), {withCredentials: true});

        // 에러 확인
        if (response.result !== 1) {
            throw new Error();
        }

        const { data = [], img_path: path = "", tier_type: tier = '0' } = response;

        dispatch({ type: FETCH_SIGNATURE_EMOTICON_LIST_SUCCESS, payload: { data, path, loginUserTier: tier } });
    } catch (error) {
        dispatch({ type: FETCH_SIGNATURE_EMOTICON_LIST_FAILURE, payload: getDefaultMessage() });
    }
};
