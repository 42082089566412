import React from "react";
import classNames from "classnames";
const Footer = ({ className }) => {
	return (
		<div id="footer" className={classNames(className)}>
			ⓒ SOOP Corp.
		</div>
	);
};
export default Footer;
