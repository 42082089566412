import React, { useState, useEffect, useCallback } from "react";
import useWindowScrollY from "components/hook/useWindowScrollY";

const TopButton = () => {
	const scrollTop = useWindowScrollY();
	const [display, setDisplay] = useState(false);

	useEffect(() => {
		if (scrollTop > 2000) {
			setDisplay(true);
		} else {
			setDisplay(false);
		}
	}, [scrollTop]);

	// TOP 버튼 클릭시 스무스하게 이동
	const onClickTop = useCallback((e) => {
		e.preventDefault();
		// window.$("body, html").animate(
		// 	{
		// 		scrollTop: 0,
		// 	},
		// 	100,
		// );
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		})
	}, []);

	return (
		<div id="pagetop" style={display ? { display: "block" } : { display: "none" }}>
			<a href="#" onClick={onClickTop}>
				<span className="ir">상단 바로가기</span>
			</a>
		</div>
	);
}

export default TopButton;
