import { EVENT_FETCH, EVENT_FETCH_SUCCESS, EVENT_FETCH_ERROR, EVENT_ACTIVE_CHECK, EVENT_ACTIVE_FETCH_SUCCESS, EVENT_ACTIVE_FETCH_ERROR } from "actions/gift.event.action";
import { produce } from "immer";

const INITIAL_STATE = {
    error: false,
    loading: false,
    data: {},
};

const giftEventReducer = produce((state = INITIAL_STATE, action) => {
    switch (action.type) {
        case EVENT_FETCH:
            return {
                ...state,
                loading: true,
                error: action.payload
            };

        case EVENT_FETCH_SUCCESS:
            return {
				...state,
				data: action.payload,
				loading: false,
            };

        case EVENT_FETCH_ERROR:
            return {
				...state,
				loading: false,
				error: action.payload,
            };

    }
}, INITIAL_STATE);

export default giftEventReducer;