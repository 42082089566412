import React, { useMemo } from "react";
import { Helmet } from "react-helmet";
import { TITLE_TAIL_MAIN_STATION, TITLE_TAIL_SEARCH_MENU, TITLE_TAIL_SEARCH_STATION, TITLE_TAIL_PLAIN, MAIN_STATION, SEARCH_MENU, SEARCH_STATION } from "constant/helmet.title";

const HelmetTitle = ({title, description, keyword , canonicalPath , type}) => {
	
	const titleTail = useMemo(() => {
		switch(type){
			case MAIN_STATION :
				return TITLE_TAIL_MAIN_STATION();

			case SEARCH_MENU :
				return TITLE_TAIL_SEARCH_MENU();

			case SEARCH_STATION :
				return TITLE_TAIL_SEARCH_STATION();

			default:
				return TITLE_TAIL_PLAIN();
		}
	},[type]);

	return (
		<Helmet>
            {(title)? <title>{title} | {titleTail} </title> : <title> {titleTail} </title>}
			{(description) && <meta name = "description" content={description} />}            
			{(keyword) && <meta name = "keyword" content = {keyword} />}
			{(canonicalPath) && <link rel="canonical" href= {canonicalPath} />}
        </Helmet>
	);
}

export default HelmetTitle;
