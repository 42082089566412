import produce from "immer";

import { REMOVE_STORAGE_ITEM, SET_STORAGE_ITEM } from "actions/use.storage.item.action";

const INITIAL_STATE = [];

const useStorageItemReducer = produce((state, { type, payload }) => {
	switch (type) {
		// storage 데이터 세팅
		case SET_STORAGE_ITEM: {
			const { storage, key, value } = payload;
			const storageInfo = state.find((storageInfo) => storageInfo.type === storage);
			if (!storageInfo) {
				state.push({
					type: storage,
					data: {
						[key]: value,
					},
				});
				return;
			}

			storageInfo.data[key] = value;
			return;
		}

		// storage 데이터 제거
		case REMOVE_STORAGE_ITEM: {
			const { storage, key } = payload;
			const storageInfo = state.find((storageInfo) => storageInfo.type === storage);
			if (!storageInfo) {
				return;
			}

			if (storageInfo.data[key]) {
				delete storageInfo.data[key];
			}
			return;
		}

		default:
	}
}, INITIAL_STATE);

export default useStorageItemReducer;
