import { allowLanguage } from "helpers";
import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";

const lang = allowLanguage();
document.documentElement.lang = lang;

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .use(Backend)
    .init(
        {
            lng: lang,

            debug: false,

            keySeparator: false, // we do not use keys in form messages.welcome

            //whitelist: ["ko", "en", "en-US", "ja", "th", "zh-CN", "zh-TW", "zh"],

            fallbackLng: "ko",

            load: "currentOnly",

            react: {
                useSuspense: false,
            },
            interpolation: {
                escapeValue: false, // react already safes from xss
            },

            saveMissing: false, //번역데이터가 없을때 ajax로 호출할지
            backend: {
                loadPath: "{{lng}}",
                parse: (data) => {
                    return data;
                },
                ajax: (url, options, callback, data) => {
                    import(`./locales/${url}`).then((response) => {
                        callback(response.lang, { status: "200" });
                        /**
                         * preact-notification
                         * locales 리소스 가져오기
                         * */
                        import(`preact-notification/libs/locales/${url}.js`).then((response) => {
                            i18n.addResources(url, "translation", response.default);
                        });
                    });
                },
            },

            nsSeparator: false,
        },
        (err, t) => {
            if (err) {
                console.error("error while initialising i18next ::: " + JSON.stringify(err));
            }
        },
    );

export default i18n;
