import { BJ_API_DOMAIN } from "constant/config";
import { handleFetchErrors, handleFailedToFetch } from "../helpers";

export const FETCH_SUPPORTER = "FETCH_SUPPORTER";
export const FETCH_SUPPORTER_SUCCESS = "FETCH_SUPPORTER_SUCCESS";
export const FETCH_SUPPORTER_FAILURE = "FETCH_SUPPORTER_FAILURE";

export function fetchSupporter(word = "", type = "sticker_user_id", page = 1) {
	return (dispatch) => {
		let word_url = "";
		if (word) {
			word_url = `&word=${window.encodeURIComponent(word)}&type=${type}`;
		}

		dispatch({ type: FETCH_SUPPORTER });

		fetch(`${BJ_API_DOMAIN}/${global.bj_id}/supporter?page=${page}` + word_url, {
			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then((response) => {
				dispatch({
					type: FETCH_SUPPORTER_SUCCESS,
					payload: response,
				});
			})
			.catch((error) => {
				dispatch({
					type: FETCH_SUPPORTER_FAILURE,
					payload: error ? error : { code: 0, message: error },
				});
			});
	};
}
