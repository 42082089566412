import produce from "immer";

import { REMOVE_COOKIE_ITEM, SET_COOKIE_ITEM } from "actions/use.cookie.action";

const INITIAL_STATE = {};

const useCookieReducer = produce((state, { type, payload }) => {
	switch (type) {
		// cookie 데이터 세팅
		case SET_COOKIE_ITEM: {
			const { key, value } = payload;

			state[key] = value;
			return;
		}

		// cookie 데이터 제거
		case REMOVE_COOKIE_ITEM: {
			const { key } = payload;

			delete state[key];
			return;
		}

		default:
	}
}, INITIAL_STATE);

export default useCookieReducer;
