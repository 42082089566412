import querystring from "querystring";
import cookie from "react-cookies";
import { BJ_API_DOMAIN } from "constant/config";
import { POST, DELETE, PUT } from "../constant/method";
import { toastr } from "../components/toastr";
import { MANAGER, FANCLUB } from "../constant/fanclub";
import { handleFetchErrors, handleFailedToFetch } from "../helpers";
import { isMobile } from "react-device-detect";

export const FETCH_FANCLUB = "FETCH_FANCLUB";
export const FETCH_FANCLUB_SUCCESS = "FETCH_FANCLUB_SUCCESS";
export const FETCH_FANCLUB_FAILURE = "FETCH_FANCLUB_FAILURE";

export const FETCH_MANAGER = "FETCH_MANAGER";
export const FETCH_MANAGER_SUCCESS = "FETCH_MANAGER_SUCCESS";
export const FETCH_MANAGER_FAILURE = "FETCH_MANAGER_FAILURE";

export const FETCH_AUTO_MANAGER = "FETCH_AUTO_MANAGER";
export const FETCH_AUTO_MANAGER_SUCCESS = "FETCH_AUTO_MANAGER_SUCCESS";
export const FETCH_AUTO_MANAGER_FAILURE = "FETCH_AUTO_MANAGER_FAILURE";

export const CHANGE_KEYWORD = "CHANGE_KEYWORD";

export function fetchFanclub(mode = FANCLUB, word = "", type = "user_id", page = 1) {
	return (dispatch) => {
		let queryString = "";
		if (word) {
			queryString = `&word=${window.encodeURIComponent(word)}&type=${type}`;
		}

		dispatch({ type: FETCH_FANCLUB });

		fetch(`${BJ_API_DOMAIN}/${global.bj_id}/setting/fanclub?page=${page}&mode=${mode}` + queryString, {
			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then((response) => {
				dispatch({
					type: FETCH_FANCLUB_SUCCESS,
					payload: response,
				});
			})
			.catch((error) => {
				dispatch({
					type: FETCH_FANCLUB_FAILURE,
					payload: error ? error : { code: 0, message: error },
				});
			});
	};
}

export function fetchManager(page = 1, word = "", type = "user_id") {
	return (dispatch) => {
		let queryString = "";
		if (word) {
			queryString = `&word=${word}&type=${type}`;
		}
		dispatch({ type: FETCH_MANAGER });

		fetch(`${BJ_API_DOMAIN}/${global.bj_id}/setting/fanclub?page=${page}&mode=MANAGER` + queryString, {
			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then((response) => {
				dispatch({
					type: FETCH_MANAGER_SUCCESS,
					payload: response,
				});
				if (isMobile && page === 1) {
					dispatch({
						type: CHANGE_KEYWORD,
						payload: "",
					});
				}
			})
			.catch((error) => {
				dispatch({
					type: FETCH_MANAGER_FAILURE,
					payload: error ? error : { code: 0, message: error },
				});
			});
	};
}

export function fetchAutoManager(page = 1) {
	return (dispatch) => {
		dispatch({ type: FETCH_AUTO_MANAGER });

		fetch(`${BJ_API_DOMAIN}/${global.bj_id}/setting/fanclub?page=${page}&mode=AUTOMANAGER`, {
			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then((response) => {
				dispatch({
					type: FETCH_AUTO_MANAGER_SUCCESS,
					payload: response,
				});
				if (isMobile && page === 1) {
					dispatch({
						type: CHANGE_KEYWORD,
						payload: "",
					});
				}
			})
			.catch((error) => {
				dispatch({
					type: FETCH_AUTO_MANAGER_FAILURE,
					payload: error ? error : { code: 0, message: error },
				});
			});
	};
}

//매니저 추가
export const STORE_MANAGER = "STORE_MANAGER";
export const STORE_MANAGER_SUCCESS = "STORE_MANAGER_SUCCESS";
export const STORE_MANAGER_FAILURE = "STORE_MANAGER_FAILURE";
export const STORE_MANAGER_MOBILE_SUCCESS = "STORE_MANAGER_MOBILE_SUCCESS";
//매니저 삭
export const DESTROY_MANAGER = "DESTROY_MANAGER";
export const DESTROY_MANAGER_SUCCESS = "DESTROY_MANAGER_SUCCESS";
export const DESTROY_MANAGER_FAILURE = "DESTROY_MANAGER_FAILURE";
export const DESTROY_MANAGER_MOBILE_SUCCESS = "DESTROY_MANAGER_MOBILE_SUCCESS";

/**
 * 매니저 설정
 * @param {*} user_id
 */
export function storeManager(user_id, callback_action = "") {
	return (dispatch) => {
		//로딩
		fetch(`${BJ_API_DOMAIN}/${global.bj_id}/setting/manager`, {
			method: POST,
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: querystring.stringify({ user_id, _token: cookie.load("XSRF-TOKEN") }),
			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then((response) => {
				dispatch({
					type: isMobile ? STORE_MANAGER_MOBILE_SUCCESS : STORE_MANAGER_SUCCESS,
					payload: response,
				});
				if (isMobile && callback_action !== "") {
					dispatch(callback_action);
				} else {
					toastr.success(response.message);
				}
			})
			.catch((error) => {
				dispatch({
					type: STORE_MANAGER_FAILURE,
					payload: { manager_id: user_id },
				});
				toastr.error(error.message, {
					timeOut: 2000,
				});
			});
	};
}

/**
 * 매니저 삭제
 * @param {*} bj_id
 */
export function destroyManager(user_id) {
	return (dispatch) => {
		//로딩
		fetch(`${BJ_API_DOMAIN}/${global.bj_id}/setting/manager/${user_id}`, {
			method: POST,
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: querystring.stringify({ _method: DELETE, mode: MANAGER, _token: cookie.load("XSRF-TOKEN") }),
			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then((response) => {
				dispatch({
					type: isMobile ? DESTROY_MANAGER_MOBILE_SUCCESS : DESTROY_MANAGER_SUCCESS,
					payload: response,
				});
				toastr.success(response.message);
			})
			.catch((error) => {
				dispatch({
					type: DESTROY_MANAGER_FAILURE,
					payload: { manager_id: user_id },
				});
				toastr.error(error.message, {
					timeOut: 2000,
				});
			});
	};
}

//공지권한  추가
export const UPDATE_NOTICE_SUCCESS = "UPDATE_NOTICE_SUCCESS";
export const UPDATE_NOTICE_FAILURE = "UPDATE_NOTICE_FAILURE";

/**
 * 공지권한  변경
 * @param {*} user_id
 */
export function updateManagerNotice(user_id, notice_flag) {
	return (dispatch) => {
		//로딩
		fetch(`${BJ_API_DOMAIN}/${global.bj_id}/setting/manager/${user_id}`, {
			method: POST,
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: querystring.stringify({ _method: PUT, notice_flag, _token: cookie.load("XSRF-TOKEN") }),
			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then((response) => {
				dispatch({
					type: UPDATE_NOTICE_SUCCESS,
					payload: response,
				});
				toastr.success(response.message);
			})
			.catch((error) => {
				dispatch({
					type: UPDATE_NOTICE_FAILURE,
					payload: { manager_id: user_id },
				});
				toastr.error(error.message, {
					timeOut: 2000,
				});
			});
	};
}

//공지권한  추가
export const UPDATE_AUTO_MANAGER_SUCCESS = "UPDATE_AUTO_MANAGER_SUCCESS";
export const UPDATE_AUTO_MANAGER_FAILURE = "UPDATE_AUTO_MANAGER_FAILURE";
export const UPDATE_AUTO_MANAGER_MOBILE_SUCCESS = "UPDATE_AUTO_MANAGER_MOBILE_SUCCESS";
/**
 * 공지권한  변경
 * @param {*} user_id
 */
export function updateFanclubChatManager(user_id, auto_manager, callback_action = "") {
	return (dispatch) => {
		//로딩
		fetch(`${BJ_API_DOMAIN}/${global.bj_id}/setting/fanclub/${user_id}`, {
			method: POST,
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: querystring.stringify({ _method: PUT, auto_manager, _token: cookie.load("XSRF-TOKEN") }),
			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then((response) => {
				dispatch({
					type: isMobile ? UPDATE_AUTO_MANAGER_MOBILE_SUCCESS : UPDATE_AUTO_MANAGER_SUCCESS,
					payload: response,
				});
				if (isMobile && callback_action !== "") {
					dispatch(callback_action);
				} else {
					toastr.success(response.message);
				}
			})
			.catch((error) => {
				dispatch({
					type: UPDATE_AUTO_MANAGER_FAILURE,
					payload: { user_id: user_id },
				});
				toastr.error(error.message, {
					timeOut: 2000,
				});
			});
	};
}
