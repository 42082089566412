import i18next from "i18next";

//비로그인
export const NO_LOGIN = 0;
//일반유저
export const USER = 1;
//클럽BJ
export const CLUBBJ = 2;
//팬(애청자)
export const FAVORITE = 4;
//팬클럽
export const FANCLUB = 8;
//자동매니저
export const AUTOMANAGER = 16;
//매니저
export const MANAGER = 32;
//주인
export const OWNER = 64;
//구독팬
export const SUBSCRIPTION = 128;
// 업로더
export const UPLOADER = 256;
// 서포터
export const SUPPORTER = 512;
// 열혈팬
export const TOPFAN = 1024;

//전체 읽기
export const READ_ALL = 101;
//비공개
export const PRIVATE = 102;
//팬(애청자) 읽기
export const READ_FAVORITE = 103;
//팬클럽 읽기
export const READ_FANCLUB = 104;
//매니저 읽기
export const READ_MANAGER = 106;
//구독 읽기
export const READ_SUBSCRIPTION = 107;

export function getReadAuthOptions() {
    return [
        { value: READ_ALL, name: i18next.t("전체 공개") },
        { value: READ_FAVORITE, name: i18next.t("애청자 공개") },
        { value: READ_FANCLUB, name: i18next.t("팬클럽 공개") },
        { value: READ_MANAGER, name: i18next.t("매니저 공개") },
        { value: READ_SUBSCRIPTION, name: i18next.t("구독팬 공개") },
        { value: PRIVATE, name: i18next.t("비공개") },
    ];
}

export function getAuthText(read_auth_no) {
    const option = getReadAuthOptions().filter((val) => val.value === parseInt(read_auth_no, 10));
    return option.length > 0 ? option[0].name : "";
}

export function getWriteAuthDefault(read_auth_no) {
    const all_auth = {
        write_all: true,
        write_favorite: true,
        write_fanclub: true,
        write_subscription: true,
        write_manager: true,
    };
    const none_auth = {
        write_all: false,
        write_favorite: false,
        write_fanclub: false,
        write_subscription: false,
        write_manager: false,
    };
    switch (parseInt(read_auth_no, 10)) {
        case READ_ALL:
            return all_auth;
        case PRIVATE:
            return none_auth;
        case READ_FAVORITE:
            return {
                ...none_auth,
                write_favorite: true,
                write_manager: true,
            };
        case READ_FANCLUB:
            return {
                ...none_auth,
                write_fanclub: true,
                write_manager: true,
            };
        case READ_MANAGER:
            return {
                ...none_auth,
                write_manager: true,
            };
        case READ_SUBSCRIPTION:
            return {
                ...none_auth,
                write_manager: true,
                write_subscription: true,
            };
        default:
            return none_auth;
    }
}

/**
 * 댓글 권한 bitset 추출
 * @param {string} commentYn
 * @param {{[string]: boolean}} commentAuthList
 * @returns {number}
 */
export const getCommentAuthNo = (commentYn, commentAuthList) => {
    const authNoList = {
        fanclub: FANCLUB,
        manager: MANAGER,
        subscription: SUBSCRIPTION,
        supporter: SUPPORTER,
        topfan: TOPFAN,
    };

    let commentAuthNo = 0;
    switch (commentYn) {
        // 일부 허용
        case "2":
            for (const name in commentAuthList) {
                if (commentAuthList[name]) {
                    commentAuthNo |= authNoList[name];
                }
            }

            break;

        //  전체 허용
        case "1":
            for (const name in authNoList) {
                commentAuthNo |= authNoList[name];
            }
            break;
        default:
    }

    return commentAuthNo;
};

/**
 * 댓글 권한 리스트 추출
 * @param {string} commentYn
 * @param {number|null} commentAuthNo
 * @returns {{manager: boolean, fanclub: boolean, subscription: boolean, topfan: boolean, supporter: boolean}}
 */
export const getCommentAuthList = (commentYn, commentAuthNo) => {
    switch (commentYn) {
        // 허용 안함
        case "0":
            return {
                fanclub: false,
                manager: false,
                subscription: false,
                supporter: false,
                topfan: false,
            };

        // 일부 허용
        case "2":
            if (commentAuthNo === null) {
                break;
            }

            return {
                fanclub: (commentAuthNo & FANCLUB) > 0,
                manager: (commentAuthNo & MANAGER) > 0,
                subscription: (commentAuthNo & SUBSCRIPTION) > 0,
                supporter: (commentAuthNo & SUPPORTER) > 0,
                topfan: (commentAuthNo & TOPFAN) > 0,
            };

        default:
    }

    return {
        fanclub: true,
        manager: true,
        subscription: true,
        supporter: true,
        topfan: true,
    };
};

// 답글 권한 체크
export const getReplyAuthList = (commentYn) => {
    switch (commentYn) {
        // 허용 안함
        case "0":
            return "0";

        // 일부 허용 2, 전체 허용 1
        case "2":
        case "1":
        default:
            return "1";
    }
};


export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGING_FAILURE = "LOGING_FAILURE";
