import { Map } from "immutable";
import {
	COMMENT_IMAGE_UPLOAD,
	COMMENT_IMAGE_UPLOAD_SUCCESS,
	COMMENT_IMAGE_UPLOAD_FAILURE,
	COMMENT_IMAGE_UPLOAD_PROGRESS,
	COMMENT_IMAGE_DELETE,
	COMMENT_IMAGE_RESET,
} from "../actions/comment.image.action";

const INITIAL_STATE = Map({});

export default function commentImageReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case COMMENT_IMAGE_UPLOAD:
			return state.set(
				`${action.comment_type}_${action.p_comment_no}_${action.c_comment_no}`,
				Map({
					type: action.type,
					progress: 0,
					image: {},
				}),
			);
		case COMMENT_IMAGE_UPLOAD_PROGRESS:
			return state.set(
				`${action.comment_type}_${action.p_comment_no}_${action.c_comment_no}`,
				Map({
					type: action.type,
					progress: action.progress,
					image: {},
				}),
			);
		case COMMENT_IMAGE_UPLOAD_SUCCESS:
			return state.set(
				`${action.comment_type}_${action.p_comment_no}_${action.c_comment_no}`,
				Map({
					type: action.type,
					progress: 100,
					image: action.payload,
				}),
			);
		case COMMENT_IMAGE_UPLOAD_FAILURE:
			return state.delete(`${action.comment_type}_${action.p_comment_no}_${action.c_comment_no}`);
		case COMMENT_IMAGE_DELETE:
			return state.delete(`${action.comment_type}_${action.p_comment_no}_${action.c_comment_no}`);
		case COMMENT_IMAGE_RESET:
			return INITIAL_STATE;
		default:
			return state;
	}
}
