import React from "react";
import { withTranslation, Trans } from "react-i18next";
import { isMobile } from "react-device-detect";
import ReactPopup from "components/popup/ReactPopup";
import ModalWindow from "components/ModalWindow";
import { BadgeMore } from "components/contents/BadgeMore";
export const Badge = withTranslation()((props) => {
	const { groups, user_nick } = props;
	const explode_badge = groups.slice(0,3);//배지 노출은 3개
	if (isMobile) {
		return (
			<div className="bj_badge">
                {explode_badge.map((explode_badge, index) => {
                        return (
                            <i key={index} style = {{backgroundColor : explode_badge.info.group_background_color}}>
                                <Trans>{explode_badge.info.group_name}</Trans>
                            </i>
                        );
                })}
                {groups.length > 3 && (
					<ModalWindow
						classNames={{modal: "bs-wrap "}}
						trigger={
							<button type="button" className="btn_badge_more">더보기</button>
						}
					>
                    {(close) => <BadgeMore groups={groups} user_nick={user_nick} onClose={close} />}
					</ModalWindow>
				)}
            </div>
		);
	} else {
		return (
            <div className="bj_badge">
                {explode_badge.map((explode_badge, index) => {
                        return (
                            <i key={index} style = {{backgroundColor : explode_badge.info.group_background_color}}>
                                <Trans>{explode_badge.info.group_name}</Trans>
                            </i>
                        );
                })}
                {groups.length > 3 &&
                    <ReactPopup
                        offsetY={10}
                        trigger={<button type="button" className="btn_badge_more">더보기</button>}
                    >
                    {(close) => <BadgeMore groups={groups} user_nick={user_nick} key={groups} close={close} />}
                    </ReactPopup>
                }
            </div>
		);
	}
});
