import ReactPopup from "components/popup/ReactPopup";
import { ADBALLOON_DOMAIN, ITEM_DOMAIN, JAVASCRIPT_VOID, LOGIN_DOMAIN, SOTONG_DOMAIN, DOMAIN_URL, HELP_DOMAIN } from "constant/config";
import { setClickLogapi } from "helpers";
import React, { useCallback, useState } from "react";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import DarkMode from "./DarkMode";
import NotificationBarWrapper from "./NotificationBarWrapper";
import { UserArea } from "./UserArea";

export default function LoginArea() {
    const authenticationReducer = useSelector((state) => state.authenticationReducer);
    const { is_login, nickname, note_cnt } = authenticationReducer;
    const [userArea, setUserArea] = useState('main'||'profit');

    const handleGoLogin = useCallback(() => {
        window.location = `${LOGIN_DOMAIN}/afreeca/login.php?szFrom=full&request_uri=${window.encodeURIComponent(window.location.href)}`;
    }, []);

    const handleSetClickLog = useCallback((type) => {
        if (type) {
            setClickLogapi("gnb_00000002", { button_type: type });
        }
    }, []);

    const handleSwitchUserArea = (areaType) => {
        setUserArea(areaType);
    };

    if (is_login) {
        return (
            <div id="logArea" className="log_area">
                <NotificationBarWrapper />

                <ReactPopup
                    trigger={
                        <a href={JAVASCRIPT_VOID} className="nickname">
                            {note_cnt > 0 && <span className="new">New</span>}
                            {nickname}
                            <em />
                        </a>
                    }
                    onOpen={()=>handleSwitchUserArea('main')} 
                >
                    <UserArea authenticationReducer={authenticationReducer} handleSwitchUserArea={handleSwitchUserArea} userArea={userArea} />
                </ReactPopup>
            </div>
        )
    } else {
        return (
            <div id="logArea" className="log_area">
                {/* 로그인 전 */}
                <span className="join_area">
                    {document.documentElement.lang === "ko" && (
                        <ReactPopup
                            stopPropagation={true}
                            trigger={
                                <button type="button" className="btn_more" tip="설정">
                                    설정
                                </button>
                            }
                        >
                            <div
                                className="modeSetting"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.nativeEvent.stopImmediatePropagation();
                                }}
                            >
                                <ul>
                                    <li>
                                        <a
                                            href={`${ITEM_DOMAIN}/quickview.php`}
                                            target="_blank"
                                            onClick={() => handleSetClickLog("item")}
                                            className="my_item"
                                        >
                                            <span>아이템</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href={`${ITEM_DOMAIN}/starballoon.php`}
                                            target="_blank"
                                            onClick={() => handleSetClickLog("balloon")}
                                            className="my_balloon"
                                        >
                                            <span>별풍선</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href={`${ITEM_DOMAIN}/subscription.php`}
                                            target="_blank"
                                            onClick={() => handleSetClickLog("subscription")}
                                            className="my_gd"
                                        >
                                            <span>구독</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href={ADBALLOON_DOMAIN}
                                            target="_blank"
                                            onClick={() => handleSetClickLog("adballoon")}
                                            className="my_spon"
                                        >
                                            <span>애드벌룬</span>
                                        </a>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <a
                                            href={SOTONG_DOMAIN}
                                            target="_blank"
                                            onClick={() => handleSetClickLog("sotong")}
                                            className="my_sotong"
                                        >
                                            <span>소통센터</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href={`${HELP_DOMAIN}/atv.php`}
                                            target="_blank"
                                            onClick={() => handleSetClickLog("customer")}
                                            className="my_custom"
                                        >
                                            <span>고객센터</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href={`${DOMAIN_URL}/afreeca_intro.htm`}
                                            target="_blank"
                                            onClick={() => handleSetClickLog("service")}
                                            className="my_service"
                                        >
                                            <span>서비스 소개</span>
                                        </a>
                                    </li>
                                </ul>
                                <DarkMode />
                            </div>
                        </ReactPopup>
                    )}

                    <a href={JAVASCRIPT_VOID} className="login" onClick={handleGoLogin}>
                        <Trans>로그인</Trans>
                    </a>
                </span>
            </div>
        );
    }
}
