import debounce from "lodash.debounce";
import throttle from "lodash.throttle";
import React, { useCallback, useEffect, useRef } from "react";
import { isIOS } from "react-device-detect";
import { withRouter } from "react-router";

const setScrollTop = (value) => {
    const element = document.documentElement && isIOS === false ? document.documentElement : document.body;
    element.scrollTop = value;
};
const getScrollTop = () => {
    const element = document.documentElement && isIOS === false ? document.documentElement : document.body;
    return element.scrollTop;
};

/**
 * React location가 repace할때 componentDidUpdate 되어서
 * window replaceState 사용
 * @param {*} location
 * @param {*} state
 */
const replaceState = (state) => {
    const windowState = window.history.state ? window.history.state.state : {};
    window.history.replaceState(
        {
            ...window.history.state,
            state: { ...windowState, ...state },
        },
        null,
    );
};
/**
 *
 */
const getState = () => {
    return window.history.state ? window.history.state.state || {} : {};
};

/**
 * delay 만큰 반복
 * @param {*} callback
 * @param {*} delay
 * @param {*} count
 */
const setTimeoutCount = (callback, delay, count) => {
    var remaningTime = count;
    return window.setTimeout(() => {
        callback();
        if (count > 1) {
            setTimeoutCount(callback, delay, remaningTime - 1);
        }
    }, delay);
};

const RestoredScroll = withRouter(({ children, className, history }) => {
    const [minHeight, setMinHeight] = React.useState(null);
    const containerRef = useRef(null);

    /**
     * componentDidMount
     */
    useEffect(() => {
        const { clientHeight } = getState();
        //Dom 크기조정
        setMinHeight(history.action === "PUSH" ? 0 : clientHeight);
    }, [history.action]);

    /**
     * minHeight 바뀌고난후에 scroll 함
     */
    useEffect(() => {
        const { scrollTop = 0, force = false, disableScroll = false } = getState();
        /**
         * 페이지변경체크를 위해 꼭필요
         * useEffect(()=>{
         * },[history.location.pathname]);
         */

        /**
         * RestoredScroll > setTimeoutCount 3초 이슈로 본문 하단 리스트로 이동 시 상단 스크롤 변경을 위해 적용 됨
         * 알림 및 MY 내 글 댓글의 해시 태그를 통한 랜딩일 경우 스크롤 조정이 되므로 예외 처리
         */
        const landingComment = window.location.hash.match(/^(#comment(_noti)?)([0-9]+)$/) || '';
        const landingReply = window.location.hash.match(/^(#reply(_noti)?)([0-9]+)$/) || '';
        const commentArr = ['#comment', '#comment_noti'];
        const replyArr = ['#reply', '#reply_noti'];

        // console.log("history.location.pathname", history.location.pathname);

        if (
            disableScroll === false 
            && !commentArr.includes(landingComment[1])
            && !replyArr.includes(landingReply[1])
            ) {
            setTimeoutCount(
                () => {
                    // console.log("setScrollTop", history.action === "PUSH" && force === false ? 0 : scrollTop);
                    setScrollTop(history.action === "PUSH" && force === false ? 0 : scrollTop);
                },
                100,
                3,
            );
        }
    }, [history.action, minHeight, history.location.pathname]);

    /**
     * scroll debounce로 스크롤후 0.2후에 state에 저장
     */
    const handleScroll = useCallback(
        debounce(() => {
            replaceState({ scrollTop: getScrollTop() });
        }, 200),
        [],
    );

    /**
     * 하위Dom의 변화가 있을경우 크기를 0.2초가 state에 저장
     */
    const handleResize = useCallback(
        throttle(() => {
            if (containerRef.current) {
                //state에  dom크기 저장함
                replaceState({ clientHeight: containerRef.current.clientHeight });

                //복구한 크기보다 DOM이 작아졌을경우
                setTimeout(() => {
                    if (containerRef.current && containerRef.current.clientHeight > containerRef.current.children[0].clientHeight) {
                        setMinHeight(containerRef.current.children[0].clientHeight);
                    }
                }, 1000);
            }
        }, 200),
        [],
    );

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        containerRef.current.addEventListener("DOMSubtreeModified", handleResize);
        return () => {
            window.removeEventListener("scroll", handleScroll);
            containerRef.current.removeEventListener("DOMSubtreeModified", handleResize);
        };
    }, [handleResize, handleScroll]);

    return (
        <div className={className} ref={containerRef} style={{ minHeight: minHeight }}>
            <div>{children}</div>
        </div>
    );
});
export default RestoredScroll;
