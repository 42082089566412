import React from "react";
import { StationError } from "./StationError";
import { withRouter } from "react-router";
import { Trans } from "react-i18next";
import { isWebview } from "helpers";
export const StationErrorMobile = withRouter((props) => {
	const handleGoBack = () => {
		if (isWebview && props.history.length <= 1) {
			window.close();
		} else if (props.history.length <= 1) {
			props.history.push(`/${global.bj_id}`);
		} else {
			props.history.goBack();
		}
	};

	return [
		<header key={1}>
			<section className="basic-section">
				<div className="grid-start">
					<button type="button" className="btn-back" onClick={handleGoBack}>
						<span>
							<Trans>뒤로가기</Trans>
						</span>
					</button>
				</div>
				<h2 className="title">
					<Trans>SOOP</Trans>
				</h2>
			</section>
		</header>,
		<StationError key={2} {...props} />,
	];
});
