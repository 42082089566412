import React from "react";
import {Trans, useTranslation} from "react-i18next";
import { isMobile } from "react-device-detect";
import classNames from "classnames";
import {ProfileImage2} from "components/ImageHandle";
import { STARBALLOON } from "constant/position.path";
import Nickname from "./Nickname";
import {JAVASCRIPT_VOID} from "constant/config";
import ReactPopup from "components/popup/ReactPopup";
import StickerTopMore from "components/left/StickerTopMore";
import StarballoonTopMore from "components/left/StarballoonTopMore";
import {numberCommaString} from "helpers";

const InfoTopFan = ({topfans = [] , isHome , printType }) => {

    /**
     * StarballonTop, StickerTop, InfoMobile 내용 합체
     * 모두 분리 되어 있어서 하나의 컴포넌트로 수정
     *
     * PC의 경우 3개 미리 노출 + 레이어 팝업
     * 모바일의 경우 limit 없음
     *
     */

    const { t } = useTranslation();
    const fanName = printType === "STARBALLOON" ? "열혈팬" : "서포터";
    const topfans_3 = topfans.length > 3 ? topfans.slice(0, 3) : topfans;

    if (topfans.length <= 0) {
        return null;
    }

    if (!isMobile) {
        return (
            <section className={classNames("rank-list", { "fan" :  printType === "STARBALLOON" } ) }>
                <h3>
                    {/* 열혈 서포터 구분*/}
                    <Trans>{fanName}</Trans>
                </h3>
                    {/* 홈일 경우에만 리스트 출력 */}
                {isHome &&
                    <ul>
                        {topfans_3.map((sticker, idx) => {
                            return (
                                <li
                                    key={idx}
                                    className={classNames({
                                        first: idx === 0,
                                        top: idx > 0,
                                    })}
                                >
                                    <ProfileImage2 userId={sticker.user_id} className="thum" src={sticker.profile_image} position={STARBALLOON }/>
                                    <Nickname
                                        item={{
                                            user_id: sticker.user_id,
                                            user_nick: sticker.user_nick,
                                        }}
                                    >
                                        <a href={JAVASCRIPT_VOID}>{sticker.user_nick}</a>
                                    </Nickname>
                                    <em>{idx === 0 ? t("회장") : (idx === 1 ? t("부회장") : t("{{num}}등", { num: idx + 1 }))}</em>
                                </li>
                            );
                        })}
                    </ul>
                }
                <ReactPopup
                    offsetX={255}
                    offsetY={10}
                    trigger={
                        <button type="button" className="more" tip={t("{{fanName}} 더보기", fanName)}>
							<span>
								<Trans>{fanName} 더보기</Trans>
							</span>
                        </button>
                    }
                >
                    {(close) => printType === "STARBALLOON" ?
                        <StarballoonTopMore starballoon_top={topfans} close={close} key="starballoon_top" />
                        : <StickerTopMore sticker_top={topfans} close={close} key="sticker_top" />}
                </ReactPopup>
            </section>
        );
    } else {
        return (
            <div className={classNames("rank-list", { "fan" :  printType === "STARBALLOON" })}>
                <ul>
                    {topfans.map((user, idx) => {
                        return (
                            <li key={idx} className="thum">
                                <ProfileImage2 userId={user.user_id} className="thum" src={user.profile_image} position={STARBALLOON}/>
                                <Nickname item={user}>
                                    <p>{user.user_nick}</p>
                                </Nickname>

                                <div className="rt">
                                    {user.total_gift ? (
                                        <span>{t("{{num}}개", { num: numberCommaString(user.total_gift) })}</span>
                                    ) : (
                                        <em>
                                            {idx === 0 ? t("회장") : idx === 1 ? t("부회장") : t("{{num}}등", { num: idx + 1 })}
                                        </em>
                                    )}
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }
}

export default InfoTopFan;
