import React from "react";
import { isMobile } from "react-device-detect";
import { Trans } from "react-i18next";
import { getMedalDescription, getBasicMedalImg, getMedalName} from "constant/medal.js";
import i18next from "i18next";

export const MedalMore = (props) => {
	const { medals, lang } = props;
	if (isMobile) {
		return (
			<div className="ui-pop-m medal_layer_mo">
				<div className="pop-title">
						<Trans>메달 정보</Trans>
				</div>
				<div className="pop-body">
					<ul>
						{medals.map((medal, index) => (
							<li key={index}>
								<span className="img_medal">
									<img src={ getBasicMedalImg(lang,medal.id) } 
									alt={medal.name}></img>
								</span>
								<div className="desc">
									<h4>{getMedalName(medal.name)}</h4>
									<p>{getMedalDescription(medal.id,medal.description)}</p>
								</div>
							</li>
						))}
					</ul>
				</div>
				<div className="pop-btn" onClick={props.onClose}>
					<button type="button" className="btn_done"> {i18next.t("확인")} </button>
				</div>
			</div>
		);
	} else {
		return (
			<div className="medal_layer" style={{display :"block"}}>
				<h3> <Trans>메달 정보</Trans> </h3>
				<ul>
					{medals.map((medal, index) => (
						<li key={index}>
							<span className="img_medal">
								<img src={getBasicMedalImg(lang,medal.id)} 
								alt={medal.name}></img>
							</span>
							<div className="desc">
								<h4>{getMedalName(medal.name)}</h4>
								<p>{getMedalDescription(medal.id,medal.description)}</p>
							</div>
						</li>
					))}
				</ul>
				<button type="button" className="btn_close"> 닫기 </button>
			</div>
		);
	}
};
