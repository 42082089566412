import { CLEAR } from "constant/status";
import { 
    TAG_SEARCH_START,
    TAG_SEARCH_LOADING,
    TAG_SEARCH_DONE,
    TAG_SEARCH_DONE_MORE,
    TAG_SEARCH_FAIL,
    TAG_SEARCH_END,
    TAG_SEARCH_INIT,
    TAG_SELECT_CHECK } from "actions/bj.search.action";

const INITIAL_STATE = {
    loading : false,
    data: [],
    page: 1,
    total_cnt: -1,
    word: ''
};

export default function reportReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case TAG_SEARCH_START:
            return { ...state, loading: false};
		case TAG_SEARCH_LOADING:
            return { ...state, loading: true};
        case TAG_SEARCH_DONE:
            return { ...state, loading: false, data: action.payload.DATA, word: action.payload.WORD, page: 1, total_cnt: action.payload.TOTAL_CNT};
        case TAG_SEARCH_DONE_MORE:
            return { ...state, loading: false, data : state.data.concat(action.payload.DATA), page: state.page + 1, total_cnt: action.payload.TOTAL_CNT};
        case TAG_SEARCH_FAIL:
            return INITIAL_STATE;
        case TAG_SEARCH_END:
            return { ...state, loading: false};
        case TAG_SEARCH_INIT:
            return state= INITIAL_STATE;
        default:
			return state;
	}
}
