import { destroyFavorite, destroyFavoritePush, storeFavorite, storeFavoritePush } from "actions/station.action";
import classNames from "classnames";
import { ButtonLink } from "components/ButtonLink";
import InfoMore from "components/contents/InfoMore";
import { ProfileImage2 } from "components/ImageHandle";
import ModalWindow from "components/ModalWindow";
import ReactPopup from "components/popup/ReactPopup";
import AdballoonEventLayer from "components/event/AdballoonEventLayer";
import { Badge } from "components/contents/Badge";
import { SMR } from "constant/maintype";
import { displayNumber, goGiftAdBalloon, goGiftStarBalloon, goLogin, gtag, popupGiftSticker, popupSubscription, getCeremony, getCeremonyImg, handleCeremonyImgError, setClickLogapi } from "helpers";
import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";
import { Link } from "react-router-dom";
import ProfileMessage from "./ProfileMessage";
import GiftEvent from "pages/station/home/event/GiftEvent";
import { toastr } from "components/toastr";

import { fetchDrawalUserInfo } from "actions/userWithDrawal.action";
import {LNB_PROFILE} from "constant/position.path";
class Info extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMoreButton: false,
            isEventModal: false,
            extend: false,
            showModal: false,
            profileText: props.stationInfo.data.station.display.profile_text,
            isProfileEdit: false,
            modalActionName : '',
            modalImageUrl : '',
            fanclub_order: 0,
            gift_count: 0,
            withDraw: 0
        };
        this.profileTextRef = React.createRef();
        this.STARBALLOON = "STARBALLOON";
        this.ADBALLOON = "ADBALLOON";
    }

    componentDidMount() {
        this._reset();
        this.setState({ isMoreButton: this.isEllipsisActive() });
    }

    componentDidUpdate(prevProps) {
        if (this.props.onDidUpdate) {
            this.props.onDidUpdate();
        }

        /**
         * 다른페이지에서 홈으로 이동했을때,
         */
        if (prevProps.homeReducer.home != this.props.homeReducer.home) {
            if (this.props.homeReducer.home) {
                this.setState({ isMoreButton: this.isEllipsisActive() });
            } else {
                this._reset();
            }
        }
        /***
         * 프로필메시지 바뀐면 다시계산
         */
        if (prevProps.stationInfo.data.station.display.profile_text != this.props.stationInfo.data.station.display.profile_text) {
            this.setState(
                {
                    profileText: this.props.stationInfo.data.station.display.profile_text,
                },
                () => {
                    this.setState({ isMoreButton: this.isEllipsisActive() });
                },
            )
        }
    }

    /**
     * 프로필메시지가 말줄임 됐는지 확인
     */
    isEllipsisActive(e) {
        if (!this.profileTextRef.current) return false;

        return this.profileTextRef.current.scrollHeight > 20;
    }

    _reset = () => {
        this.setState((prevState) => ({
            extend: false,
        }));
        this.setState({ isMoreButton: false });
    };

    handleExpend = () => {
        this.setState(
            (prevState) => ({
                extend: !prevState.extend,
            }),
            () => {
                if (this.props.onExtend) {
                    this.props.onExtend();
                }
            },
        );
    };
    
    
    handleModalClose = () => {
        this.setState({
            showModal: false,
            isEventModal: false,
            modalActionName: '',
        });
        setTimeout(() => {
            this.setState({
                gift_count: 0,
            });
        }, 350);
    };


    // 별풍선 애드벌룬 세레머니 모달 action
    handleVisibilityState = async (openName) => {
        const { isActiveEvent } = this.props;
        if(openName === "ADBALLOON" && isActiveEvent) {
            (async() => {
                const response = await getCeremony(openName);
                if (response.data !== null) {
                    this.setState({
                        fanclub_order: response.data.fanclub_order ,
                        gift_count : response.data.gift_count,
                        
                    });
                    const oCeremony = {
                        giftType: openName,
                        giftCnt: response.data.gift_count,
                        ceremony_starballoon: response.ceremony_starballoon,
                        ceremony_adballon_img : response.ceremony_adballon_img
                    }

                    const returnData = getCeremonyImg(oCeremony);
                    this.setState({
                        modalImageUrl: returnData.imgUrl
                    })
                    this.handleOpenEventModal();
                }
            })();
            return false;
        }
        
        if (document.visibilityState === 'visible' && !this.state[openName]) {
            (async() => {
                const response = await getCeremony(openName);
                if (response.data !== null) {
                    this.setState({
                        modalActionName: openName,
                        fanclub_order: response.data.fanclub_order ,
                        gift_count : response.data.gift_count
                    });

                    const oCeremony = {
                        giftType: openName,
                        giftCnt: response.data.gift_count,
                        ceremony_starballoon: response.ceremony_starballoon,
                        ceremony_adballon_img : response.ceremony_adballon_img
                    }

                    const returnData = getCeremonyImg(oCeremony);
                    this.setState({
                        modalImageUrl: returnData.imgUrl
                    })
                }
            })();
        }
    }

    /**
     * 
     * @param {*} openPopupId window.popup return obejct
     * @param {*} openName setState key Name
     */
    handleOpenModal = (openPopupId, openName) => {
        this.setState({
            fanclub_order: 0,
            gift_count: 0,
        });

        if (openPopupId) {
            const timer = setInterval(() => {
                if (openPopupId.closed) {
                    clearInterval(timer);
                    this.handleVisibilityState(openName);
                }
            }, 1000);
        } else {
            document.addEventListener("visibilitychange", 
                () => {
                    this.handleVisibilityState(openName);
                });
        }
    }

    handleOpenEventModal = () => {
        this.setState({
            isEventModal: true
        });
    }

    handleGift = (type) => (e) => {
        const { t, authenticationReducer, stationInfo, isActiveEvent } = this.props;
        const { is_favorite, is_mobile_push } = stationInfo.data;

        if (authenticationReducer.is_login === false) {
            goLogin();
            return false;
        }

        if(!["favorite", "push"].includes(type)) {
            (async() => {
                let withDraw = await fetchDrawalUserInfo(); 

                this.setState({
                    withDraw: withDraw
                });

                if(withDraw && !["favorite", "push"].includes(type)) {
                    let message = t("현재 탈퇴 처리중인 유저입니다.") + "\n";
                    message += t("후원할 수 없습니다.");
                    toastr.success(message, {
                        timeOut: 2000,
                        showCloseButton: false,
                        textCenter: true
                    });
                    return false;
                }

                switch (type) {
                    case "subscription":
                        popupSubscription(global.bj_id);
                        setClickLogapi('station_click', { button_group : 'station_info', button_type : 'sub'});
                        break;
                    case "starballon":
                        // 별풍선 세레머니 추가
                        const starballonPopupId = goGiftStarBalloon(global.bj_id);
                        this.handleOpenModal(starballonPopupId, this.STARBALLOON);
                        setClickLogapi('station_click', { button_group : 'station_info', button_type : 'starballoon'});
                        break;
                    case "sticker":
                        popupGiftSticker(global.bj_id);
                        setClickLogapi('station_click', { button_group : 'station_info', button_type : 'sticker'});
                        break;
                    case "adballoon":
                        if(isActiveEvent) {
                            // 애드벌룬 이벤트일 경우 모델 변경
                            this.handleOpenEventModal();
                        } else {
                            // 애드벌룬 세레머니 추가
                            const adballonPopupId = goGiftAdBalloon(global.bj_id);
                            this.handleOpenModal(adballonPopupId, this.ADBALLOON);
                        }
                        //애드벌룬 아이콘 클릭 시 이벤트 유무 관계없이 클릭로그
                        setClickLogapi('station_click', { button_group : 'station_info', button_type : 'adballoon'});
                        break;
                    default:
                        break;
                }
            })();
        }

        switch(type){
            case "favorite":
                if (is_favorite) {
                    this.props.destroyFavorite();
                    setClickLogapi('station_click', { button_group : 'station_info', button_type : 'favorite', action_type : 'delete'});
                } else {
                    this.props.storeFavorite();
                    setClickLogapi('station_click', { button_group : 'station_info', button_type : 'favorite', action_type : 'add'});
                }
                break;
            case "push":
                if (is_mobile_push) {
                    this.props.destroyFavoritePush();
                    setClickLogapi('station_click', { button_group : 'station_info', button_type : 'fav_alarm', action_type : 'off'});
                } else {
                    this.props.storeFavoritePush();
                    setClickLogapi('station_click', { button_group : 'station_info', button_type : 'fav_alarm', action_type : 'on'});
                }
                break;
        }

    };

    handleModifyProfile = (flag) => () => {
        const { stationInfo } = this.props;
        const { is_owner } = stationInfo.data;
        if (is_owner) {
            this.setState({ isProfileEdit: flag });
        }
    };

    render() {
        const { t, stationInfo, payload, isActiveEvent, adballoonEvent } = this.props;
        const { profileText } = this.state;
        const {
            station,
            subscription,
            profile_image,
            is_partner_bj,
            is_best_bj,
            is_owner,
            is_manager,
            is_favorite,
            is_subscription,
            is_mobile_push,
        } = stationInfo.data;

        const { station_name, upd, user_nick, groups, display } = station;
        const { main_type } = display;

        const bsClassName = `bs-infomation ${main_type === SMR ? 'brand' : ''} ${isActiveEvent ? 'adballonEvent' : ''}`;
        const lang = document.documentElement.lang;

        if (isMobile) {
            return (
                <section className="bs-infomation_wrap" ref={this.props.infoRef}>
                    {/* 본인 채널 - my-bs 추가 */}
                    <div className={bsClassName}>
                        <div className="bs-autor">
                            <ProfileImage2 className="thum" userId={global.bj_id} src={profile_image} position={LNB_PROFILE}/>

                            <div className="nick">
                                <p>{display.main_type === SMR ? station.station_name : user_nick}</p>
                                {(is_owner || is_manager) && (
                                    <ButtonLink type="button" className="btn-bsAdm" to={`/${global.bj_id}/setting`}>
                                        채널 관리
                                    </ButtonLink>
                                )}
                            </div>
                            <div className="viewers">
                                <span>
                                    <Trans>애청자</Trans> <em>{displayNumber(upd.fan_cnt)}</em>
                                </span>
                                <span>
                                    <Trans>구독팬</Trans> <em>{displayNumber(subscription.total)}</em>
                                </span>
                            </div>
                        </div>
                        {(is_best_bj || is_partner_bj || groups.length > 0) && (
                            <Badge groups={groups} user_nick={user_nick}/>
                        )}
                        {/* <div className="rating">
                            {is_partner_bj && (
                                <span className="partner">
                                    <Trans>파트너BJ</Trans>
                                </span>
                            )}
                            {is_best_bj && (
                                <span className="best">
                                    <Trans>베스트BJ</Trans>
                                </span>
                            )}
                            {groups &&
                                groups.map((group, index) => {
                                    return (
                                        <span key={index} className={classNames(`rate${group.group_no}`)}>
                                            {group.info.group_name}
                                        </span>
                                    );
                                })}
                        </div> */}
                        <div className={classNames("intro_text", { active: this.state.extend })}>
                            {this.state.isMoreButton && (
                                <button type="button" className="btn-more" onClick={this.handleExpend}>
                                    {this.state.extend ? t("접기") : t("더 보기")}
                                </button>
                            )}

                            <p onClick={this.handleModifyProfile(true)}>
                                <span ref={this.profileTextRef}>{profileText}</span>
                            </p>
                            <Modal
                                closeOnOverlayClick={true}
                                open={this.state.isProfileEdit}
                                onClose={this.handleModalClose}
                                showCloseIcon={false}
                                classNames={{
                                    overlay: "modal_overlay_style",
                                    modal: "modal_modal_style",
                                }}
                            >
                                <ProfileMessage profile_text={profileText} onClose={this.handleModifyProfile(false)} />
                            </Modal>
                        </div>
                        { lang === "ko" && isActiveEvent && (
                            <>
                            <GiftEvent percent={adballoonEvent.percent} onOpen={this.state.isEventModal} onOpenModal={this.handleGift("adballoon")} />
                            <Modal
                                open={this.state.isEventModal || this.state.modalActionName === this.ADBALLOON} 
                                onClose={this.handleModalClose}                                
                                showCloseIcon={false}
                                classNames={
                                    {modal: "adballonLayerBottom"}}
                            >
                                <AdballoonEventLayer onClose={this.handleModalClose} onOpenModal={this.handleOpenModal} gift={this.state.gift_count > 0 ? {gift_cnt: this.state.gift_count, fanclub_order: this.state.fanclub_order, gift_img: this.state.modalImageUrl} : null} />
                                                                            
                            </Modal>
                            </>
                        )}
                    </div>
                </section>
            );
        } else {
            return (
                <section className="bs-infomation_wrap" ref={this.props.infoRef}>
                    <div className={classNames("bs-infomation ", { "my-bs": is_owner, 'ballonEvent': isActiveEvent })}>
                        <div className="title-wrap">
                            <div className="title_area">
                                <h2>
                                    <Link to={`/${global.bj_id}`}>{station_name}</Link>
                                </h2>
                                <div className="bs-info">
                                    <ReactPopup
                                        offsetX={200}
                                        offsetY={15}
                                        trigger={
                                            <button
                                                type="button"
                                                className="btn_info"
                                                tip={t("채널 정보")}
                                                onClick={gtag("station_button", "info_layer")}
                                            >
                                                <span 
                                                    onClick={() => setClickLogapi("station_click", {button_group: "station_info", button_type: "info_icon"})}
                                                >
                                                    <Trans>채널 정보</Trans>
                                                </span>
                                            </button>
                                        }
                                    >
                                        {(close) => <InfoMore close={close} stationInfo={stationInfo} key="infomore" />}
                                    </ReactPopup>
                                </div>
                            </div>

                            <div className={classNames("explanation", { active: this.state.extend })}>
                                <p>
                                    <span ref={this.profileTextRef}>
                                        {profileText === ""
                                            ? this.props.t("안녕하세요. {{user_nick}}님의 채널 입니다.", { user_nick })
                                            : profileText}
                                        {is_owner && profileText === "" && this.props.t("자기소개를 입력해주세요.")}
                                    </span>
                                </p>
                                {is_owner && (
                                    <ModalWindow
                                        trigger={
                                            <button type="button" className="modify">
                                                <span>
                                                    <Trans>수정</Trans>
                                                </span>
                                            </button>
                                        }
                                    >
                                        {(close) => <ProfileMessage profile_text={profileText} onClose={close} />}
                                    </ModalWindow>
                                )}

                                {this.state.isMoreButton && (
                                    <button type="button" className="more" onClick={this.handleExpend}>
                                        <span>
                                            <Trans>스트리머 메세지 더보기</Trans>
                                        </span>
                                    </button>
                                )}
                            </div>

                        </div>
                        {is_owner ? (
                            <div className="items_wrap">
                                <div className="btns">
                                    <button type="button" className="favor">
                                        <em>
                                            <Trans>즐겨찾기</Trans>
                                        </em>
                                        <span>{displayNumber(upd.fan_cnt)}</span>
                                    </button>
                                    <button type="button" className="subscribe">
                                        <em>
                                            <Trans>구독</Trans>
                                        </em>
                                        <span>{displayNumber(subscription.total)}</span>
                                    </button>
                                    <button type="button" className="sticker" onClick={this.handleGift("sticker")}>
                                        <em>
                                            <Trans>스티커</Trans>
                                        </em>
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div className="items_wrap">
                                <div className="btns">
                                    {is_favorite && (
                                        <button
                                            type="button"
                                            className={classNames("alarm", { on: is_mobile_push })}
                                            onClick={this.handleGift("push")}
                                        >
                                            <em>
                                                <Trans>알림</Trans>
                                            </em>
                                        </button>
                                    )}
                                    <button
                                        type="button"
                                        className={classNames("favor", { on: is_favorite })}
                                        onClick={this.handleGift("favorite")}
                                    >
                                        <em>
                                            <Trans>즐겨찾기</Trans>
                                        </em>
                                        <span>{displayNumber(upd.fan_cnt)}</span>
                                    </button>

                                    {display.main_type !== SMR && (
                                        <button
                                            type="button"
                                            className={classNames("subscribe", { on: is_subscription })}
                                            onClick={this.handleGift("subscription")}
                                        >
                                            <em>
                                                <Trans>구독</Trans>
                                            </em>
                                            <span>{displayNumber(subscription.total)}</span>
                                        </button>
                                    )}
                                    {display.main_type !== SMR && (
                                        <>
                                         <button type="button" className="star_balloon" onClick={this.handleGift("starballon")}>
                                            <em>
                                                <Trans>별풍선</Trans>
                                            </em>
                                        </button>
                                        <Modal
                                            open={this.state.modalActionName === this.STARBALLOON}
                                            onClose={this.handleModalClose}
                                            showCloseIcon={false}
                                            styles={{
                                                modal: {
                                                    maxWidth: "unset",
                                                },
                                                overlay: {
                                                    background: "unset",
                                                }
                                            }}
                                        >
                                            {(
                                                <div className="balloonGift_layer">
                                                    <div className="inner">
                                                    <div className="box star">
                                                        {/*`${DOMAIN_URL}/new_player/items/adballoon/ceremony/mobile_${this.state.gift_count}.png`*/}
                                                        <span className="img"><img src={this.state.modalImageUrl} alt="" onError={(e) => handleCeremonyImgError(e, this.STARBALLOON, this.state.gift_count)}/></span>
                                                        <div className="text">
                                                            <strong><em>{user_nick}</em> 님에게<br />별풍선 {this.state.gift_count}개 선물!</strong>
                                                            { this.state.fanclub_order > 0 && (
                                                                <p className="fan">{this.state.fanclub_order}번째로 팬클럽이 되신걸<br /> 축하드립니다!</p>
                                                            )}
                                                        </div>
                                                        <button type="button" className="close" onClick={this.handleModalClose}>레이어 닫기</button>
                                                    </div>
                                                </div></div>
                                            )}
                                            
                                        </Modal>
                                        </>
                                    )}
                                    {lang === "ko" && display.main_type !== SMR && (
                                        <>
                                        <button type="button" className="ad_balloon" onClick={this.handleGift("adballoon")}>
                                            <em>
                                                <Trans>애드벌룬</Trans>
                                            </em>
                                        </button>
                                        <Modal
                                            open={this.state.modalActionName === this.ADBALLOON} 
                                            onClose={this.handleModalClose}
                                            showCloseIcon={false}
                                            styles={{
                                                modal: {
                                                    maxWidth: "unset",
                                                },
                                                overlay: {
                                                    background: "unset",
                                                }
                                            }}
                                        >
                                            {(
                                                <div className="balloonGift_layer">
                                                    <div className="inner">
                                                    <div className="box">
                                                        <span className="img"><img src={this.state.modalImageUrl} alt="" onError={(e) => handleCeremonyImgError(e, this.ADBALLOON, this.state.gift_count)} /></span>
                                                        <div className="text">
                                                            <strong><em>{user_nick}</em> 님에게<br />애드벌룬 {this.state.gift_count}개 선물!</strong>
                                                            { this.state.fanclub_order > 0 && (
                                                                <p className="fan">{this.state.fanclub_order}번째로 팬클럽이 되신걸<br /> 축하드립니다!</p>
                                                            )}
                                                        </div>
                                                        <button type="button" className="close" onClick={this.handleModalClose}>레이어 닫기</button>
                                                    </div>
                                                </div></div>
                                            )}
                                            
                                        </Modal>
                                        </>
                                        
                                    )}
                                    {display.main_type !== SMR && (
                                        <button type="button" className="sticker" onClick={this.handleGift("sticker")}>
                                            <em>
                                                <Trans>스티커</Trans>
                                            </em>
                                        </button>
                                    )}
                                </div>
                            </div>
                        )}
                        { lang === "ko" && isActiveEvent && (
                            <>
                            <GiftEvent onOpen={this.state.isEventModal} percent={adballoonEvent.percent} onOpenModal={this.handleGift("adballoon")} />
                            <Modal
                                open={this.state.isEventModal || this.state.modalActionName === this.ADBALLOON} 
                                onClose={this.handleModalClose}
                                showCloseIcon={false}
                            >
                                <AdballoonEventLayer onClose={this.handleModalClose} onOpenModal={this.handleOpenModal} gift={this.state.gift_count > 0 ? {gift_cnt: this.state.gift_count, fanclub_order: this.state.fanclub_order, gift_img: this.state.modalImageUrl} : null} />
                                                                            
                            </Modal>
                            </>
                        )}
                    </div>
                </section>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        authenticationReducer: state.authenticationReducer,
        bjInfo: state.stationReducer.bjInfo,
        homeReducer: state.homeReducer
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        storeFavorite: () => {
            dispatch(storeFavorite());
        },
        destroyFavorite: () => {
            dispatch(destroyFavorite());
        },
        storeFavoritePush: () => {
            dispatch(storeFavoritePush());
        },
        destroyFavoritePush: () => {
            dispatch(destroyFavoritePush());
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(Info));
