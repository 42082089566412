import cookie from "react-cookies";

import i18next from "i18next";
import qs from "qs";
import querystring from "querystring";
import store from "store";

import { goLogin } from "helpers";

import axios from "libs/axios";

import { DELETE, PUT } from "constant/method";
import { DOMAIN, STBBS_DOMAIN, VOD_DOMAIN } from "constant/config";

import { toastr } from "components/toastr";

export const FETCH_POST = "FETCH_POST";
export const FETCH_POST_SUCCESS = "FETCH_POST_SUCCESS";
export const FETCH_POST_FAILURE = "FETCH_POST_FAILURE";

export const CLEAR_POST = "CLEAR_POST";

export const STORE_POST_LIKE = "STORE_POST_LIKE";
export const STORE_POST_LIKE_SUCCESS = "STORE_POST_LIKE_SUCCESS";

export const DELETE_POST_LIKE = "DELETE_POST_LIKE";
export const DELETE_POST_LIKE_SUCCESS = "DELETE_POST_LIKE_SUCCESS";

export const HIDE_POST_SUCCESS = "HIDE_POST_SUCCESS";

export const DELETE_POST = "DELETE_POST";
export const DELETE_POST_SUCCESS = "DELETE_POST_SUCCESS";

export const CANCEL_NOTICE = "CANCEL_NOTICE";
export const CANCEL_NOTICE_SUCCESS = "CANCEL_NOTICE_SUCCESS";

export const CANCEL_NOTICE_BOTTOM_SUCCESS = "CANCEL_NOTICE_BOTTOM_SUCCESS";
export const CANCEL_POST_NOTICE_SUCCESS = "CANCEL_POST_NOTICE_SUCCESS";

/**
 * 게시글 불러 오기
 * @param titleNo
 * @returns {function(...[*]=)}
 */
export const fetchPost = (titleNo) => async (dispatch) => {
	dispatch({ type: FETCH_POST });

	let url = `/${global.bj_id}/title/${titleNo}`;

	if (window.location.search) {
		url += window.location.search; //szKey값전달
		cookie.save("post_referer", document.referrer, { domain: DOMAIN, path: "/" }); //쿠키로 referer 값전달(api는bj.afreecatv.com을 referer로 참고함)
	}

	try {
		const response = await axios.get(url);

		dispatch({
			type: FETCH_POST_SUCCESS,
			payload: response,
		});
	} catch (error) {
		dispatch({
			type: FETCH_POST_FAILURE,
			payload: error,
		});
	} finally {
		cookie.remove("post_referer", { expires: -1, domain: DOMAIN, path: "/" });
	}
};

/**
 * 동영상 나중에보기 등록
 * @param titleNo
 * @param type
 */
export const storeLaterViewVod = async (titleNo, type = "VOD") => {
	const { is_login } = store.getState().authenticationReducer;

	// 비로그인
	if (!is_login) {
		if (window.confirm(i18next.t("로그인이 필요합니다. 로그인 하시겠습니까?"))) {
			goLogin();
		}
		return;
	}

	const url = `${VOD_DOMAIN}/api/later_view_api.php?szWork=ADD&nTitleNo=${titleNo}&szVodType=${type}&szSysType=web&szLocation=list&_=${Math.random()}`;

	try {
		const response = await axios.get(url);

		// 나중에 보기 등록 실패
		if (response.RESULT !== 1) {
			toastr.warning(response.MSG, {
				timeOut: 2000,
			});
			return;
		}

		toastr.success(i18next.t("나중에 보기에 추가됐습니다."), {
			timeOut: 2000,
		});
	} catch (error) {
		toastr.error(error.message, {
			timeOut: 2000,
		});
	}
};

/**
 * 게시글 좋아요 등록
 * @param titleNo
 * @param boardType
 * @returns {function(...[*]=)}
 */
export const storePostLike = (titleNo, boardType) => async (dispatch) => {
	dispatch({ type: STORE_POST_LIKE });

	try {
		const response = await axios.post(`/${global.bj_id}/title/${titleNo}/like`, qs.stringify({ board_type: boardType }));

		dispatch({
			type: STORE_POST_LIKE_SUCCESS,
			payload: response,
		});

		toastr.success(response.message, {
			timeOut: 2000,
		});
	} catch (error) {
		toastr.error(error.message, {
			timeOut: 2000,
		});
	}
};

/**
 * 게시글 좋아요 해제
 * @param titleNo
 * @param boardType
 * @returns {function(...[*]=)}
 */
export const deletePostLike = (titleNo, boardType) => async (dispatch) => {
	dispatch({ type: DELETE_POST_LIKE });

	try {
		const response = await axios.post(
			`/${global.bj_id}/title/${titleNo}/like/1`,
			qs.stringify({ board_type: boardType, _method: DELETE }),
		);

		dispatch({
			type: DELETE_POST_LIKE_SUCCESS,
			payload: response,
		});

		toastr.success(response.message, {
			timeOut: 2000,
		});
	} catch (error) {
		toastr.error(error.message, {
			timeOut: 2000,
		});
	}
};

/**
 * 게시글 삭제
 * @param titleNo
 * @returns {function(...[*]=)}
 */
export const deletePost = (titleNo) => async (dispatch) => {
	dispatch({ type: DELETE_POST });

	try {
		const response = await axios.post(
			`/${global.bj_id}/title/${titleNo}`,
			qs.stringify({
				_method: DELETE,
			}),
		);

		dispatch({ type: DELETE_POST_SUCCESS, payload: response });

		toastr.success(i18next.t("삭제되었습니다."), {
			timeOut: 2000,
		});
	} catch (error) {
		toastr.error(error.message, {
			timeOut: 2000,
		});
	}
};

/**
 * 공지 해제
 * @param titleNo
 * @returns {function(...[*]=)}
 */
export const cancelNoticePost = (titleNo) => async (dispatch) => {
	if (!window.confirm(i18next.t("공지를 해제하시겠습니까?"))) {
		return;
	}

	dispatch({ type: CANCEL_NOTICE });

	try {
		const response = await axios.post(
			`/${global.bj_id}/title/${titleNo}/notice/${titleNo}`,
			qs.stringify({
				_method: PUT,
			}),
		);

		dispatch({
			type: CANCEL_NOTICE_SUCCESS,
			payload: response,
		});

		dispatch({
			type: CANCEL_NOTICE_BOTTOM_SUCCESS,
			payload: response,
		});

		dispatch({
			type: CANCEL_POST_NOTICE_SUCCESS,
			payload: response,
		});
		toastr.success(response.message, {
			timeOut: 2000,
		});
	} catch (error) {
		toastr.error(error.message, {
			timeOut: 2000,
		});
	}
};

export const moveSelectPostsApi = (titleNo, bbsNo, oldBbsNo) => async () => {
	// 여러 게시글 이동 시 array 로 받음
	!Array.isArray(titleNo) && (titleNo = [titleNo]);
	titleNo = JSON.stringify(titleNo);

	try {
		const response = await axios.post(
			`/${global.bj_id}/board/moveselect`,
			qs.stringify({
				title_lists: titleNo,
				bbs_no: bbsNo,
				old_bbs_no: oldBbsNo,
			}),
		);

		await alert(i18next.t(response.message));

	} catch (error) {
		alert(i18next.t(error.message));
		throw error;
	}
};


/**
 * 게시글 게시판 이동
 * @param titleNo
 * @param bbsNo
 * @param oldBbsNo
 * @returns {function(...[*]=)}
 */
export const movePostsApi = (titleNo, bbsNo, oldBbsNo) => async () => {
	// 여러 게시글 이동 시 array 로 받음
	!Array.isArray(titleNo) && (titleNo = [titleNo]);
	titleNo = JSON.stringify(titleNo);

	try {
		await axios.post(
			`/${global.bj_id}/board/move`,
			qs.stringify({
				_method: PUT,
				title_lists: titleNo,
				bbs_no: bbsNo,
				old_bbs_no: oldBbsNo,
			}),
		);
	} catch (error) {
		toastr.error(i18next.t(error.message), {
			timeOut: 2000,
		});

		throw error;
	}
};

/**
 * VOD 게시글 삭제 api
 * @param titleNo
 * @returns {function(...[*]=)}
 */
export const deleteVodApi = (titleNo) => async (dispatch) => {
	try {
		const response = await axios.post(`/${global.bj_id}/vod/${titleNo}`, querystring.stringify({ _method: DELETE }));

		// 삭제 성공
		dispatch({
			type: DELETE_POST_SUCCESS,
			payload: response,
		});

		// 삭제 성공 메시지
		toastr.success(response.message, {
			timeOut: 2000,
		});
	} catch (error) {
		// 삭제 실패
		toastr.error(error.message, {
			timeOut: 2000,
		});

		throw error;
	}
};

/**
 * VOD 게시글 삭제
 * @param titleNo
 * @returns {function(...[*]=)}
 */
export const deleteVod = (titleNo) => async (dispatch) => {
	try {
		await dispatch(deleteVodApi(titleNo));
	} catch (error) {}
};

/**
 * VOD 게시글 블라인드
 * @param titleNo
 * @returns {function(...[*]=)}
 */
export const blindVod = (titleNo) => async (dispatch) => {
	dispatch({
		type: DELETE_POST,
	});

	try {
		const response = await axios.post(`/${global.bj_id}/vod/blind/${titleNo}`, qs.stringify({ _method: DELETE }));

		dispatch({
			type: DELETE_POST_SUCCESS,
			payload: response,
		});

		toastr.success(response.message, {
			timeOut: 2000,
		});
	} catch (error) {
		toastr.error(error.message, {
			timeOut: 2000,
		});
	}
};

/**
 * 유저 클립 숨김 처리
 * @param titleNo 삭제할 게시글 번호
 * @param reasonDelete 삭제 사유 기입 여부
 * @param reason 삭제 사유
 * @returns {function(...[*]=)}
 */
export const hiddenVod = (titleNo, reasonDelete, reason) => async (dispatch) => {
	dispatch({
		type: DELETE_POST,
	});

	const data = {
		_method: DELETE,
		reason_delete: reasonDelete,
		reason: reason,
	};
	try {
		const response = await axios.post(`/${global.bj_id}/vod/hidden/${titleNo}`, qs.stringify(data));

		dispatch({
			type: DELETE_POST_SUCCESS,
			payload: response,
		});
		toastr.success(response.message, {
			timeOut: 2000,
		});
	} catch (error) {
		toastr.error(error.message, {
			timeOut: 2000,
		});
	}
};

/**
 * 다시 보기 게시글 사유 기입 삭제 API
 * @param data
 * @returns {Promise}
 */
export const deleteReviewVodApi = (data) =>
	axios.post(
		`${STBBS_DOMAIN}/app/vod/del_vod.php`,
		qs.stringify({
			nTitleNo: data.title_no,
			szTitleNoList: data.title_no,
			nStationNo: data.station_no,
			szDelType: data.del_type,
			szDelReason: data.del_reason,
		}),
	);
	