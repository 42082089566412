import qs from "qs";

import axios from "libs/axios";

import { OLD_LIVE_DOMAIN } from "constant/config";
import { getDefaultMessage } from "constant/error";
import { CLEAR } from "constant/status";

export const FETCH_OGQ_EMOTICON_LIST = "FETCH_OGQ_EMOTICON_LIST";
export const FETCH_OGQ_EMOTICON_LIST_FAILURE = "FETCH_OGQ_EMOTICON_LIST_FAILURE";
export const FETCH_OGQ_EMOTICON_LIST_SUCCESS = "FETCH_OGQ_EMOTICON_LIST_SUCCESS";

// ogq 이모티콘 리스트 조회
export const fetchOgqEmoticonList = () => async (dispatch, getState) => {
    // 이미 데이터가 있거나 로딩 중일 경우 실행 중지
    const { emoticonOgqReducer } = getState();
    if (emoticonOgqReducer.status !== CLEAR) return;

    dispatch({ type: FETCH_OGQ_EMOTICON_LIST });

    const params = {
        ogq_type: "STICKER",
        bj_id: global.bj_id,
        ogq_pay_type: "A",
        sort: "PURCHASE",
        work: "ogq_list",
    };

    try {
        const response = await axios.post(`${OLD_LIVE_DOMAIN}/api/ogq.php`, qs.stringify(params), {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
        });

        // 에러 확인
        if (response.code !== 1) {
            throw new Error();
        }

        let { data = [], img_domain: domain } = response;
        if (!domain) domain = "//ogq-sticker-global-cdn-z01.afreecatv.com/";

        dispatch({ type: FETCH_OGQ_EMOTICON_LIST_SUCCESS, payload: { data, domain } });
    } catch (error) {
        dispatch({ type: FETCH_OGQ_EMOTICON_LIST_FAILURE, payload: getDefaultMessage() });
    }
};

/**
 * OGQ 이모티콘 사용 가능 여부 확인
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const checkOgqEmoticonUsableApi = async (id) => {
    const data = {
        bj_id: global.bj_id,
        work: "ogq_use",
        ogq_id: id,
    };

    const response = await axios.post(`${OLD_LIVE_DOMAIN}/api/ogq.php`, qs.stringify(data), {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });

    // api 오류
    if (response.code < 0) {
        throw new Error();
    }

    return response;
};
