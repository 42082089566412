import { Map, List } from "immutable";
import {
	FETCH_SETTING_MENU,
	FETCH_SETTING_MENU_SUCCESS,
	FETCH_SETTING_MENU_FAILURE,
	PUT_SETTING_MENU_SUCCESS,
	CREATE_SETTING_MENU,
	DELETE_SETTING_MENU_SUCCESS,
	STORE_SETTING_MENU_SUCCESS,
	POST_SORT_MENU_SUCCESS,
} from "../actions/setting.menu.action";

const INITIAL_STATE = Map({
	error: null,
	loading: false,
	menuState: Map(),
	time_stamp: Math.floor(Date.now() / 1000),
});

export default function settingMenuReducer(state = INITIAL_STATE, action) {
	const time_stamp = Math.floor(Date.now() / 1000);
	switch (action.type) {
		//게시글 리스트
		case FETCH_SETTING_MENU:
			return state.set("loading", true);

		case FETCH_SETTING_MENU_SUCCESS:
		case POST_SORT_MENU_SUCCESS:{			
			return state.set("loading", false).set("menuState", Map(action.payload)).set("time_stamp", time_stamp);
		}
		case FETCH_SETTING_MENU_FAILURE:
			return state.set("loading", false).set("error", action.payload);

		case PUT_SETTING_MENU_SUCCESS: {
			const _data = List(state.getIn(["menuState", "data"]));
			return state.setIn(
				["menuState", "data"],
				_data.update(
					_data.findIndex((menu) => {
						return Map(menu).get("bbs_no") === parseInt(action.payload.bbs_no, 10);
					}),
					() => {
						return action.payload;
					},
				),
			).set("time_stamp", time_stamp);
		}

		case CREATE_SETTING_MENU: {
			const _data = List(state.getIn(["menuState", "data"]));
			return state.setIn(["menuState", "data"], _data.push(action.payload)).set("time_stamp", time_stamp);
		}
		case DELETE_SETTING_MENU_SUCCESS: {
			const _data = List(state.getIn(["menuState", "data"]));
			return state.setIn(
				["menuState", "data"],
				_data.delete(
					_data.findIndex((menu) => {
						return Map(menu).get("bbs_no") === parseInt(action.payload.bbs_no, 10);
					}),
				),
			).set("time_stamp", time_stamp);
		}

		case STORE_SETTING_MENU_SUCCESS:
			const _data = List(state.getIn(["menuState", "data"]));
			return state.setIn(["menuState", "data"], _data.push(action.payload)).set("time_stamp", time_stamp);

		default:
			return state;
	}
}
