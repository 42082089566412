import querystring from "querystring";
import i18next from "i18next";
import { FORM_CONTENT_TYPE } from "./config";
import { BJ_API_DOMAIN } from "constant/config";
import { DELETE, POST, PUT } from "../constant/method";
import { toastr } from "../components/toastr";
import { uniCodeToChrs, handleFetchErrors, handleFailedToFetch } from "../helpers";
import { isMobile } from "react-device-detect";
import cookie from "react-cookies";
import { OLD_STATION_DOMAIN } from "constant/config";

export const FETCH_STATION_INFO = "FETCH_STATION_INFO";
export const FETCH_STATION_INFO_SUCCESS = "FETCH_STATION_INFO_SUCCESS";
export const FETCH_STATION_INFO_FAILURE = "FETCH_STATION_INFO_FAILURE";

export const STORE_STATION_INFO_SUCCESS = "STORE_STATION_INFO_SUCCESS";

export const CHECK_STATION_NAME_SUCCESS = "CHECK_STATION_NAME_SUCCESS";
export const CHECK_STATION_NAME_FAILURE = "CHECK_STATION_NAME_FAILURE";
export const PUT_STATION_NAME_SUCCESS = "PUT_STATION_NAME_SUCCESS";

export const PUT_PROFILE_IMAGE_SUCCESS = "PUT_PROFILE_IMAGE_SUCCESS";
export const PUT_PROFILE_IMAGE_FAILURE = "PUT_PROFILE_IMAGE_FAILURE";

export const PUT_PROFILE_TEXT_SUCCESS = "PUT_PROFILE_TEXT_SUCCESS";

export const CHECK_USER_NICK_SUCCESS = "CHECK_USER_NICK_SUCCESS";
export const CHECK_USER_NICK_FAILURE = "CHECK_USER_NICK_FAILURE";
export const PUT_USER_NICK_SUCEESS = "PUT_USER_NICK_SUCEESS";
export const PUT_USER_NICK_FAILURE = "PUT_USER_NICK_FAILURE";

export const INIT_PROFILE_IMAGE_SUCCESS = "INIT_PROFILE_IMAGE_SUCCESS";

// 닉네임 정보 수정
export const PUT_USER_NICK = "PUT_USER_NICK";

export function fetchStation() {
	return (dispatch) => {
		const url = `${BJ_API_DOMAIN}/${global.bj_id}/setting/station`;
		dispatch({ type: FETCH_STATION_INFO });
		fetch(url, {
			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then((response) => {
				dispatch({
					type: FETCH_STATION_INFO_SUCCESS,
					payload: response,
				});
			})
			.catch((error) => {
				dispatch({
					type: FETCH_STATION_INFO_FAILURE,
					payload: error ? error : { code: 0, message: error },
				});
			});
	};
}

export function checkStationName(station_name) {
	return (dispatch) => {
		if (checkFiled(station_name, false)) {
			const url = `${BJ_API_DOMAIN}/${global.bj_id}/setting/profile/name`;
			fetch(url, {
				method: POST,
				headers: FORM_CONTENT_TYPE,
				body: querystring.stringify({ _method: PUT, _token: cookie.load("XSRF-TOKEN"), mode: "check", station_name: station_name }),
				credentials: "include",
			})
				.then(handleFetchErrors, handleFailedToFetch)
				.then((response) => response.json())
				.then((response) => {
					dispatch({
						type: CHECK_STATION_NAME_SUCCESS,
						payload: response,
					});
					if (isMobile) {
						toastr.success(i18next.t("사용 가능한 채널 이름입니다."), {
							timeOut: 2000,
						});
					}
				})
				.catch((error) => {
					toastr.error(error.message, {
						timeOut: 2000,
					});
					dispatch({
						type: CHECK_STATION_NAME_FAILURE,
						payload: error ? error : { code: 0, message: error },
					});
				});
		} else {
			dispatch({
				type: CHECK_STATION_NAME_FAILURE,
				payload: "",
			});
		}
	};
}

export function putStationName(props) {
	return fetch(`${BJ_API_DOMAIN}/${global.bj_id}/setting/profile/name`, {
		method: POST,
		headers: FORM_CONTENT_TYPE,
		body: querystring.stringify({ ...props, _token: cookie.load("XSRF-TOKEN"), _method: PUT }),
		credentials: "include",
	});
}

export function checkUserNick(user_nick) {
	return (dispatch) => {
		if (checkFiled(user_nick, true)) {
			fetch(`${OLD_STATION_DOMAIN}/api/nickname_api_utf8.php?szWork=check`, {
				method: POST,
				body: querystring.stringify({szNick: user_nick}),
				headers: FORM_CONTENT_TYPE,
				credentials: "include",
			})
				.then(handleFetchErrors, handleFailedToFetch)
				.then((response) => response.json())
				.then(function (response) {
					if (response.RESULT === 1) {
						dispatch({
							type: CHECK_USER_NICK_SUCCESS,
							payload: user_nick,
						});
						if (isMobile) {
							toastr.success(i18next.t("사용 가능한 닉네임입니다."), {
								timeOut: 2000,
							});
						}
					} else if(response.RESULT === -50014) {
						if (isMobile) {
							toastr.error(i18next.t("부적절한 닉네임 사용으로 인한 닉네임 변경이 제한되었습니다."), {
								timeOut: 2000,
							});
						} else{
							toastr.error(i18next.t("부적절한 닉네임 사용으로 인한 닉네임 변경이 제한되었습니다.\n(누적 {{round}}회 차) 변경 제한 해제일 {{dateTime}}", { round : response.ROUND, dateTime : response.RESTRICTION_RELEASE_DATETIME}), {
								timeOut: 2000,
							});
						}
						dispatch({
							type: CHECK_USER_NICK_FAILURE,
							payload: "",
						});
					} else {
						toastr.error(response.MESSAGE, {
							timeOut: 2000,
						});
						dispatch({
							type: CHECK_USER_NICK_FAILURE,
							payload: "",
						});
					}
				})
				.catch(function (error) {
					dispatch({
						type: CHECK_USER_NICK_FAILURE,
						payload: "",
					});
				});
		} else {
			dispatch({
				type: CHECK_USER_NICK_FAILURE,
				payload: "",
			});
		}
	};
}

export function putUserNick(props) {
	return fetch(`${OLD_STATION_DOMAIN}/api/nickname_api_utf8.php?szWork=update`, {
		method: POST,
		body: querystring.stringify({szNick: props.user_nick}),
		headers: FORM_CONTENT_TYPE,
		credentials: "include",
	});
}

export function putProfileImage(props) {
	const data = new FormData();
	data.append("file", props.logo_file[0]);
	data.append("_token", cookie.load("XSRF-TOKEN"));
	return fetch(`${BJ_API_DOMAIN}/${global.bj_id}/setting/profile/image`, {
		method: POST,
		body: data,
		credentials: "include",
	});
}

export function UploadLogo(file) {
	return (dispatch) => {
		const data = new FormData();
		data.append("file", file[0]);
		data.append("_token", cookie.load("XSRF-TOKEN"));
		fetch(`${BJ_API_DOMAIN}/${global.bj_id}/setting/profile/image`, {
			method: POST,
			body: data,
			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then((response) => {
				dispatch({
					type: PUT_PROFILE_IMAGE_SUCCESS,
					payload: response,
				});
				toastr.success(i18next.t("프로필 이미지가 변경됐습니다."), {
					timeOut: 2000,
				});
			})
			.catch((error) => {
				toastr.error(error.message, {
					timeOut: 2000,
				});
				dispatch({
					type: PUT_PROFILE_IMAGE_FAILURE,
					payload: error ? error : { code: 0, message: error },
				});
			});
	};
}

export function putProfileText(props) {
	return fetch(`${BJ_API_DOMAIN}/${global.bj_id}/setting/profile/text`, {
		method: POST,
		body: querystring.stringify({ ...props, _token: cookie.load("XSRF-TOKEN"), _method: PUT }),
		headers: FORM_CONTENT_TYPE,
		credentials: "include",
	});
}

export function toastNickNameMessage(code, banword) {
	let message = i18next.t("사용할수 없는 닉네임입니다.");
	switch (code) {
		case -1:
		case 35:
		default:
			message = i18next.t("사용할수 없는 닉네임입니다.");
			break;
		case 33:
			message = i18next.t("현재 사용 중인 닉네임입니다.");
			break;
		case 77:
			message = i18next.t("닉네임을 입력해주세요.");
			break;
		case 80:
			message = i18next.t("닉네임란에 허용 길이 이상의 글을 쓰셨습니다.");
			break;
		case 96:
			message = i18next.t("이미 사용중인 닉네임입니다.");
			break;
		case 97:
			message = i18next.t("이전 변경 시점부터 24시간 후에 재변경이 가능합니다.");
			break;
		case 200:
			message = i18next.t("{{banword}}는 사용하실 수 없습니다.", { banword: uniCodeToChrs(decodeURIComponent(banword)) });
			break;
	}
	toastr.error(message, {
		timeOut: 2000,
	});
}

export function checkFiled(word, is_nick) {
	// 공백 체크
	if (is_nick) {
		const reg_exp = /[\s]/g;
		if (reg_exp.test(word)) {
			toastr.error(i18next.t("공백문자는 사용하실 수 없습니다."), {
				timeOut: 2000,
			});
			return false;
		}
	}
	// 특수 공백 체크
	const space_char =
		"[\\x80\\xA0\\u3000\\uE025\\uF8F7]+|[\\u0009-\\u000D\\u0085\\u00A0\\u1680\\u2000\\u2001\\u2002\\u2003\\u2004\\u2005\\u2006\\u2007\\u2008\\u2009\\u200A\\u2028\\u2029\\u202F\\u205F\\u3000\\u3164]";
	const reg_exp_2 = new RegExp("(" + space_char + ")", "g");
	if (reg_exp_2.test(word)) {
		toastr.error(i18next.t("특수 공백문자는 사용하실 수 없습니다."), {
			timeOut: 2000,
		});
		return false;
	}
	// 특수문자
	const SPECIAL1 = "[\\x20\\x21\\x23-\\x25\\x28-\\x2E\\x30-\\x3F\\x40-\\x5B\\x5D-\\x7A]";
	//한글
	const HANGLE = "[\\u1100-\\u11FF\\u3130-\\u318F\\uAC00-\\uD7AF]";
	//한문
	const HANJA = "[\\u4E00-\\u9FBF\\uF900-\\uFAFF]";
	//특수문자
	const SPECIAL2 =
		"[\\u007b-\\u007e\\u00a1\\u00a4\\u00a7\\u00a8\\u00aa\\u00ac\\u00ae\\u00b1-\\u00b4\\u00b6\\u00b8-\\u00ba\\u00bc-\\u00bf\\u00c6\\u00d0\\u00d7\\u00d8\\u00de\\u00df\\u00e6\\u00f0\\u00f7\\u00f8\\u00fe\\u0111\\u0126\\u0127\\u0131-\\u0133\\u0138\\u013f-\\u0142\\u0149-\\u014b\\u0152\\u0153\\u0166\\u0167\\u02c7\\u02d0\\u02d8-\\u02db\\u02dd\\u0391-\\u03a1\\u03a3-\\u03a9\\u03b1-\\u03c1\\u03c3-\\u03c9\\u0401\\u0410-\\u044f\\u0451\\u2015\\u2018\\u2019\\u201c\\u201d\\u2020-\\u2022\\u2025\\u2026\\u2030\\u2032\\u2033\\u203b\\u2074\\u207f\\u2081-\\u2084\\u20ac\\u2103\\u2109\\u2113\\u2116\\u2121\\u2122\\u2126\\u212b\\u2153\\u2154\\u215b-\\u215e\\u2160-\\u2169\\u2170-\\u2179\\u2190-\\u2199\\u21d2\\u21d4\\u2200\\u2202\\u2203\\u2207\\u2208\\u220b\\u220f\\u2211\\u221a\\u221d\\u221e\\u2220\\u2225\\u2227-\\u222c\\u222e\\u2234\\u2235\\u223c\\u223d\\u2252\\u2260\\u2261\\u2264\\u2265\\u226a\\u226b\\u2282\\u2283\\u2286\\u2287\\u2299\\u22a5\\u2312\\u2460-\\u246e\\u2474-\\u2482\\u249c-\\u24b5\\u24d0-\\u24e9\\u2500-\\u2503\\u250c-\\u254b\\u2592\\u25a0\\u25a1\\u25a3-\\u25a9\\u25b2\\u25b3\\u25b6\\u25b7\\u25bc\\u25bd\\u25c0\\u25c1\\u25c6-\\u25c8\\u25cb\\u25ce-\\u25d1\\u2605-\\u2606\\u260e\\u260f\\u261c\\u261e\\u2640\\u2642\\u2660\\u2661\\u2663-\\u2665\\u2667-\\u266a\\u266c\\u266d\\u3001-\\u3003\\u3008-\\u3011\\u3013-\\u3015\\u3041-\\u3093\\u30a1-\\u30f6\\u3131-\\u3163\\u3165-\\u318e\\u3200-\\u321c\\u3260-\\u327b\\u327f\\u3380-\\u3384\\u3388-\\u33ca\\u33cf\\u33d0\\u33d3\\u33d6\\u33d8\\u33db\\u33dc\\u33dd\\uff01-\\uff5e\\uffe0-\\uffe3\\uffe5\\uffe6]";
	// 화이트 리스트 체크
	const reg_exp_3 = new RegExp("(" + SPECIAL1 + "+|" + HANGLE + "+|" + HANJA + "+|" + SPECIAL2 + ")", "g");
	const white_result = word.replace(reg_exp_3, "");
	if (white_result.length > 0) {
		toastr.error(i18next.t("{{white_result}} 는 사용하실 수 없습니다.", { white_result }), {
			timeOut: 2000,
		});
		return false;
	}
	// 태그 리무브
	const reg_exp_4 = /<(\w+)[^>]*?>(.*)<\/\1>/g;
	const reg_exp_5 = /<br[^>]*>/g;
	if (reg_exp_4.test(word) || reg_exp_5.test(word)) {
		toastr.error(i18next.t("태그는 입력하실 수 없습니다."), {
			timeOut: 2000,
		});
		return false;
	}
	return true;
}

//프로필 이미지 초기화
export function initProfileImage() {
	return (dispatch) => {
		fetch(`${BJ_API_DOMAIN}/${global.bj_id}/setting/station/1`, {
			method: POST,
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: querystring.stringify({ _token: cookie.load("XSRF-TOKEN"), _method: DELETE }),
			credentials: "include",
		})
			.then(handleFetchErrors)
			.then((response) => response.json())
			.then((response) => {
				dispatch({
					type: INIT_PROFILE_IMAGE_SUCCESS,
					payload: response,
				});
			})
			.catch((error) => {
				const errorMessage = error ? error.message : error.message;
				toastr.error(errorMessage, {
					timeOut: 2000,
				});
			});
	};
}
