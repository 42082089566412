import React, { Component } from "react";
import querystring from "querystring";
import { withRouter } from "react-router-dom";

const withParams = (WrappedComponent) => {
	return withRouter(
		class extends Component {
			constructor(props) {
				super(props);
				const { location = {} } = props;
				const { search } = location;
				this.state = {
					params: querystring.parse(search.substr(1)),
					page: 1,
				};
			}

			static getDerivedStateFromProps(nextProps, prevState) {
				const { location = {} } = nextProps;
				const { search = '' } = location;
				const nextParams = querystring.parse(search.substr(1));

				if (JSON.stringify(nextParams) !== JSON.stringify(prevState.params)) {
					return { params: nextParams };
				}

				return null;
			}

			getParams = () => {
				const { location = {} } = this.props;
				const { search } = location;
				return search ? querystring.parse(search.substr(1)) : {};
			};

			getParam = (key) => {
				return this.getParams()[key];
			};

			setParam = (key, value) => {
				this.historyParams({ key: value });
			};

			getStates = () => {
				const { location = {} } = this.props;
				const { state } = location;
				return state || {};
			};

			getState = (key) => {
				return this.getStates()[key];
			};

			historyParams = (params, state, pathname) => {
				const { history } = this.props;
				const { params: prevParams } = this.state;
				const newParams = Object.assign(prevParams, params);
				//값이 없는 필드는 제거
				const filterdParams = Object.keys(newParams)
					.filter((key) => newParams[key])
					.reduce((res, key) => Object.assign(res, { [key]: newParams[key] }), {});

				const newSearch = querystring.stringify(filterdParams);
				history.push({ search: `?${newSearch}`, state, pathname });
			};

			componentDidMount() { }

			render() {
				const newProps = {
					...this.props,
					setParam: this.setParam,
					historyParams: this.historyParams,
					getParams: this.getParams,
					getParam: this.getParam,
					getStates: this.getStates,
					getState: this.getState,
					test: (a, b, c) => {
						console.log("test", a, b, c);
					},
				};
				return React.createElement(WrappedComponent, newProps);
			}
		}
	);
};

export default withParams;
