import classNames from "classnames";
import { BEST_BJ_DOMAIN, DASHBOARD_DOMAIN, ISSUE_DOMAIN, ITEM_DOMAIN, JAVASCRIPT_VOID, PARTNER_BJ_DOMAIN, SOTONG_DOMAIN, SUPPORTERS_DOMAIN } from "constant/config";
import { SMR } from "constant/maintype";
import { getSessionItem, gtag, handleFailedToFetch, handleFetchErrors, setSessionItem } from "helpers";
import React, { Fragment, useCallback, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";
import { setClickLogapi } from "helpers";

const ADMIN = 1;
const BORAD = 2;
const VOD = 3;

const Menu = (props) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const location = useLocation();

    const [ adminActive, setAdminActive ] = useState(getSessionItem("adminActive", true));
    const [ vodActive, setVodActive ] = useState(getSessionItem("vodActive", true));
    const [ boardActive, setBoardActive ] = useState(getSessionItem("boardActive", true));
    
    const { station, is_owner, is_best_bj, is_partner_bj, is_af_supporters_bj, is_shopfreeca_bj } = useSelector(({ stationReducer }) => {
        const { stationInfo } = stationReducer;
        const { data } = stationInfo || {};
        const { station, is_owner, is_best_bj, is_partner_bj, is_af_supporters_bj, is_shopfreeca_bj } = data || {};
        
        return {
            station: station, is_owner: is_owner,
            is_best_bj: is_best_bj, is_partner_bj: is_partner_bj, is_af_supporters_bj: is_af_supporters_bj , is_shopfreeca_bj
        };
    },shallowEqual);
    
    const { menus, display} = station;
    const { main_type } = display;

    const handleActiveMenu = useCallback((category) => {
        switch (category) {
            case ADMIN:
                setAdminActive(setSessionItem("adminActive", !adminActive))
                break;
            case VOD:
                setVodActive(setSessionItem("vodActive", !vodActive))
                break;
            case BORAD:
                setBoardActive(setSessionItem("boardActive", !boardActive))
                break;
            default:
                break;
        }
    },[adminActive, boardActive, vodActive]);

    const handleMobileNotification = () => {
        const url = `${ITEM_DOMAIN}/choco_message.php?szWork=checkUseMessageItem`;
        fetch(url, {
            credentials: "include",
        })
            .then(handleFetchErrors, handleFailedToFetch)
            .then((response) => response.json())
            .then((response) => {
                if (response.RET === -1) {
                    alert(t("오류입니다. 다시 시도해주시기 바랍니다."));
                } else if (response.RET === -2) {
                    alert(t("로그인 후 이용해주시기 바랍니다."));
                } else if (response.RET === -3) {
                    if (window.confirm(t("아이템 사용하기 페이지로 이동하시겠습니까?"))) {
                        window.open(`${ITEM_DOMAIN}/myitem_sticker.php`);
                    }
                } else if (response.RET === -4) {
                    if (window.confirm(t("아이템을 보유하고 있지 않습니다. 교환 페이지로 이동하시겠습니까?"))) {
                        window.open(`${ITEM_DOMAIN}/sticker.php`);
                    }
                } else {
                    window.open(`${ITEM_DOMAIN}/choco_message.php?szWork=view`, "mobile_alim", "width=392 height=435");
                }
            })
            .catch(() => {
                window.open(`${ITEM_DOMAIN}/choco_message.php?szWork=view`, "mobile_alim", "width=392 height=435");
            });
    };

    const getActive = (url, exact = false) => {
        const { pathname, state = {} } = location;
        const { bbs_no } = state;
        const [, path1, path2, path3, path4] = pathname.split("/");
        if (path3 === "write" && path4) {
            //글쓰기
            return url === `/${path1}/posts/${path4}` ? "on" : null;
        } else if (path2 === "post" && !bbs_no) {
            //전체게시판
            return url === `/${path1}/posts` ? "on" : null;
        } else if (path2 === "post" && bbs_no) {
            return url === `/${path1}/posts/${bbs_no}` ? "on" : null;
        } else {
            if (exact) {
                return pathname === url ? "on" : null;
            } else {
                return pathname.indexOf(url) > -1 ? "on" : null;
            }
        }
    };

    return [
        is_owner && (
            <div key={1} className={classNames("menu-list", { active: adminActive })}>
                <h3>
                    <button
                        type="button"
                        onClick={() => {
                            handleActiveMenu(ADMIN);
                        }}
                    >
                        <span>
                            <Trans>방송설정</Trans>
                        </span>
                    </button>
                </h3>
                <ul>
                    <li>
                        {/* onClick 내 함수가 아닌 실행문을 전달할 경우 렌더링 될 때 onClick이벤트가 실행되어버린다. 따라서 함수형으로 선언*/}
                        <a href={`${DASHBOARD_DOMAIN}/index.php`} target="_blank" rel="noopener noreferrer" onClick={() => {setClickLogapi("station_click", {button_group: "lnb", button_type: "dashboard"})}}>
                            <Trans>외부장치 방송 설정</Trans>
                        </a>
                    </li>
                    <li>
                        <a href={`${ISSUE_DOMAIN}/apply`} target="_blank" rel="noopener noreferrer">
                            <Trans>이슈 방송 스케줄 등록</Trans>
                        </a>
                    </li>
                    <li>
                        <a href={JAVASCRIPT_VOID} onClick={handleMobileNotification}>
                            <Trans>모바일 알림 보내기</Trans>
                        </a>
                    </li>
                </ul>
            </div>
        ),
        <article key={2} className="fix-menu" ref={props.fixBar}>
            <div key={3} className={classNames("menu-list", { active: vodActive })}>
                <h3>
                    <button
                        type="button"
                        onClick={() => {
                            handleActiveMenu(VOD);
                        }}
                    >
                        <span>
                            <Trans>VOD</Trans>
                        </span>
                    </button>
                </h3>
                <ul>
                    <li className={classNames(getActive(`/${id}/vods`, true))}>
                        <Link to={`/${id}/vods`} refresh="true" onClick={gtag("pc_menu", "vod")}>
                            <strong>
                                <Trans>전체 VOD</Trans>
                            </strong>
                        </Link>
                    </li>
                    {main_type !== SMR && (
                        <Fragment>
                            <li className={classNames(getActive(`/${id}/vods/review`))} onClick={gtag("pc_menu", "review")}>
                                <Link to={`/${id}/vods/review`}>
                                    <Trans>다시보기</Trans>
                                </Link>
                            </li>
                            <li
                                className={classNames(getActive(`/${id}/vods/highlight`))}
                                onClick={gtag("pc_menu", "highlight")}
                            >
                                <Link to={`/${id}/vods/highlight`}>
                                    <Trans>하이라이트</Trans>
                                </Link>
                            </li>
                            <li className={classNames(getActive(`/${id}/vods/normal`))} onClick={gtag("pc_menu", "normal")}>
                                <Link to={`/${id}/vods/normal`}>
                                    <Trans>업로드 VOD</Trans>
                                </Link>
                            </li>
                            <li className={classNames(getActive(`/${id}/vods/myclip`))} onClick={gtag("pc_menu", "myclip")}>
                                <Link to={`/${id}/vods/myclip`}>
                                    <Trans>업로드 클립</Trans>
                                </Link>
                            </li>
                            <li className={classNames(getActive(`/${id}/vods/user`))} onClick={gtag("pc_menu", "user")}>
                                <Link to={`/${id}/vods/user`}>
                                    <Trans>유저 VOD</Trans>
                                </Link>
                            </li>
                            <li className={classNames(getActive(`/${id}/vods/clip`))} onClick={gtag("pc_menu", "clip")}>
                                <Link to={`/${id}/vods/clip`}>
                                    <Trans>유저 클립</Trans>
                                </Link>
                            </li>
                            <li
                                className={classNames(getActive(`/${id}/vods/balloonclip`))}
                                onClick={gtag("pc_menu", "balloonclip")}
                            >
                                <Link to={`/${id}/vods/balloonclip`}>
                                    <Trans>별풍선 클립</Trans>
                                </Link>
                            </li>
                        </Fragment>
                    )}
                    <li className={classNames(getActive(`/${id}/vods/list`))} onClick={gtag("pc_menu", "list")}>
                        <Link to={`/${id}/vods/list`}>
                            <Trans>재생목록</Trans>
                        </Link>
                    </li>

                    <li className={classNames(getActive(`/${id}/vods/catch`))} onClick={gtag("pc_menu", "catch")}>
                        <Link to={`/${id}/vods/catch`}>
                            <Trans>Catch</Trans>
                        </Link>
                    </li>

                </ul>
            </div>
            <div key={4} className={classNames("menu-list", { active: boardActive })}>
                <h3>
                    <button
                        type="button"
                        onClick={() => {
                            handleActiveMenu(BORAD);
                        }}
                    >
                        <span>
                            <Trans>게시판</Trans>
                        </span>
                    </button>
                </h3>
                <ul>
                    <li className={classNames(getActive(`/${id}/posts`, true))}>
                        <Link to={`/${id}/posts`} onClick={gtag("pc_menu", "posts")}>
                            <strong>
                                <Trans>전체 게시글</Trans>
                            </strong>
                        </Link>
                    </li>
                    {main_type !== SMR &&
                        menus.map((menu, idx) => {
                            switch (menu.display_type) {
                                case 107: //제목
                                    return (
                                        <li key={idx} className="stit">
                                            {menu.name}
                                        </li>
                                    );
                                case 106: //라인
                                    return <li key={idx} className="line" />;
                                case 101: //방명록
                                    return (
                                        <li key={idx} className={classNames(getActive(`/${id}/guest`))}>
                                            <Link to={`/${id}/guest`} onClick={gtag("pc_menu", "guest")}>
                                                {menu.name}
                                            </Link>
                                        </li>
                                    );
                                default:
                                    //게시판
                                    return (
                                        <li key={idx} className={classNames(getActive(`/${id}/posts/${menu.bbs_no}`))}>
                                            <Link
                                                to={`/${id}/posts/${menu.bbs_no}`}
                                                onClick={gtag("pc_menu", "posts", null, menu.bbs_no)}
                                            >
                                                {menu.name}
                                            </Link>
                                        </li>
                                    );
                            }
                        })}
                </ul>
            </div>

            {/*BJ가 설정한 게시판이 아닌 etc 게시판들은 css(menu-list etc_list) 그룹 따로 */}
            <div key={5} className={classNames("menu-list etc_list")}>
                <ul>
                    {/*숲토어 메뉴
                        라이브 커머스 O , 상품등록 X => 노출
                        라이브 커머스 X , 상품등록 O => 노출
                        라이브 커머스 X, 상품등록 X => 미노출
                    */}
                    { is_shopfreeca_bj && (
                        <Fragment>
                            <li className={classNames(getActive(`/${id}/posts/shopfreeca`))}>
                                <Link to={`/${id}/posts/shopfreeca`}><Trans>숲토어</Trans></Link>
                            </li>
                        </Fragment>
                    )}

                    {is_owner && [
                        (is_best_bj || is_partner_bj || is_af_supporters_bj) && (
                            <li key={1}>
                                {is_best_bj && (
                                    <a href={BEST_BJ_DOMAIN} target="_blank" rel="noopener noreferrer">
                                        <Trans>베스트 스트리머 게시판</Trans>
                                    </a>
                                )}
                                {is_partner_bj && (
                                    <a href={PARTNER_BJ_DOMAIN} target="_blank" rel="noopener noreferrer">
                                        <Trans>파트너 스트리머 게시판</Trans>
                                    </a>
                                )}
                                {is_af_supporters_bj && (
                                    <li key={5}>
                                        <a href={SUPPORTERS_DOMAIN} target="_blank" rel="noopener noreferrer">
                                            <Trans>SP서포터즈 게시판</Trans>
                                        </a>
                                    </li>
                                )}
                            </li>
                        )
                    ]}
                </ul>
            </div>
        </article>,
    ];
}

export default Menu;