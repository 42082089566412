// 다운로더 권한
export const DOWNLOADER = "DOWNLOADER";

// 업로더 권한
export const UPLOADER = "UPLOADER";

// 에디터 권한
export const EDITOR = "EDITOR";

// 다운로드 제한 권한
export const DOWN_REJECT = "DOWN_REJECT";
