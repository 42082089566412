import i18next from "i18next";

import {
	PAGE_TYPE_ALL,
	PAGE_TYPE_CLIP,
	PAGE_TYPE_MY_CLIP,
	PAGE_TYPE_NORMAL,
	PAGE_TYPE_STARBALLOON_CLIP,
	PAGE_TYPE_PLAYLIST,
	PAGE_TYPE_USER,
	PAGE_TYPE_REVIEW,
	PAGE_TYPE_HIGHLIGHT,
    PAGE_TYPE_CATCH,
	PAGE_TYPE_CATCH_STORY,
} from "constant/ucc.page.type";

export const SAVE_LATEST_COOKIE_KEY = "_slk";

export const ALL = "all";
export const TITLE_CONTENTS = "title,contents";
export const TITLE = "title";
export const CONTENTS = "contents";
export const USER_ID = "user_id";
export const NICKNAME = "user_nick";
export const NICKNAME_USER_ID = "user_nick,user_id";
export const COMMENT_CONTENTS = "comment_contents";
export const COMMENT_NICKNAME = "comment_user_nick";
export const COMMENT_USER_ID = "comment_user_id";
export const COMMENT_NICKNAME_USER_ID = "comment_user_nick,comment_user_id";
export const HASHTAGS = "hashtags";

export function getCommentSearchType() {
	return [COMMENT_CONTENTS, COMMENT_NICKNAME, COMMENT_USER_ID, COMMENT_NICKNAME_USER_ID];
}

export const TITLE_CONTENTS_NAME = () => i18next.t("제목+내용");
export const TITLE_NAME = () => i18next.t("제목");
export const CONTENTS_NAME = () => i18next.t("내용");
// TODO 번역 필요
export const NICKNAME_USER_ID_NAME = () => i18next.t("글 작성자");
export const COMMENT_CONTENTS_NAME = () => i18next.t("댓글 내용");
export const COMMENT_NICKNAME_USER_ID_NAME = () => i18next.t("댓글 작성자");

export const HASHTAGS_NAME = () => i18next.t("태그");

/**
 * 타입에 따른 검색 필드 옵션 조회
 * @param pageType
 * @returns {({name: string, value: string}|{name: string, value: string}|{name: string, value: string}|{name: string, value: string}|{name: string, value: string})[]|({name: string, value: string}|{name: string, value: string})[]}
 */
export function getSearchOptions(pageType = "post") {
	switch (pageType) {
		// VOD 게시판
		case PAGE_TYPE_ALL:
		case PAGE_TYPE_NORMAL:
		case PAGE_TYPE_USER:
		case PAGE_TYPE_CLIP:
		case PAGE_TYPE_MY_CLIP:
		case PAGE_TYPE_STARBALLOON_CLIP:
		case PAGE_TYPE_REVIEW:
		case PAGE_TYPE_HIGHLIGHT:
        case PAGE_TYPE_CATCH:    
			return [
				{ value: TITLE_CONTENTS, name: TITLE_CONTENTS_NAME() },
				{ value: TITLE, name: TITLE_NAME() },
				{ value: CONTENTS, name: CONTENTS_NAME() },
				{ value: NICKNAME_USER_ID, name: NICKNAME_USER_ID_NAME() },
				{ value: HASHTAGS, name: HASHTAGS_NAME() },
			];

		// 캐치스토리 게시판
        case PAGE_TYPE_CATCH_STORY:    
			return [
				{ value: TITLE, name: TITLE_NAME() },
			];

		// 재생목록 게시판
		case PAGE_TYPE_PLAYLIST:
			return [{ value: TITLE, name: TITLE_NAME() }, { value: NICKNAME_USER_ID, name: NICKNAME_USER_ID_NAME() }];


		// 일반 게시글 게시판
		default:
			return [
				{ value: TITLE_CONTENTS, name: TITLE_CONTENTS_NAME() },
				{ value: TITLE, name: TITLE_NAME() },
				{ value: CONTENTS, name: CONTENTS_NAME() },
				{ value: NICKNAME_USER_ID, name: NICKNAME_USER_ID_NAME() },
				{ value: COMMENT_CONTENTS, name: COMMENT_CONTENTS_NAME() },
				{ value: COMMENT_NICKNAME_USER_ID, name: COMMENT_NICKNAME_USER_ID_NAME() },
				{ value: HASHTAGS, name: HASHTAGS_NAME() },
			];
	}
}

/**
 * 유효한 검색 필드 조회
 * @param field
 * @param pageType
 * @returns {*}
 */
export function getSearchField(field, pageType = "post") {
	const fields = getSearchOptions(pageType).map((option) => option.value);
	return fields.find((optionField) => optionField === field) || fields[0];
}

export const SEARCH_ALL = "all";
export const SEARCH_VOD = "vod";
export const SEARCH_POST = "post";
export const REG_DATE = "reg_date";
export const LIKE_CNT = "like_cnt";
export const GIFT_CNT = "gift_cnt";
export const PRICE_ASC = "price_asc";
export const PRICE = "price";
export const ORDER_PRODUCT_NAME_ASC = "product_name_asc";
export const POINT_PERCENT = "charge";	// 적립 비율순
export const VOD_ADD_DATE = "vod_add_date"; // VOD 추가순


export function getSearchTypeOptions() {
	return [
		{ value: SEARCH_ALL, name: i18next.t("전체 보기") },
		{ value: SEARCH_VOD, name: i18next.t("VOD만 보기") },
		{ value: SEARCH_POST, name: i18next.t("글만 보기") },
	];
}

export function getSupportSearchTypeOption() {
	return [
		{ value: REG_DATE, name: i18next.t("최근순") },
		{ value: LIKE_CNT, name: i18next.t("인기순") },
		{ value: GIFT_CNT, name: i18next.t("선물개수순") },
	];
}

export function getShopfreecaTypeOption(isSetting = false) {
	let typepOptionArray = [
		{ value: REG_DATE, name: ("최신순") },
		{ value: PRICE_ASC, name: ("낮은 가격 순") },
		{ value: PRICE, name: ("높은 가격 순") },
		{ value: ORDER_PRODUCT_NAME_ASC, name: ("상품명 순") }
	];

	// 숲토어 영역 관리 > 상품 추가 영역에서만 "적립 비율순" 제공 (bj 본인에게만 보여야 함)
	if (isSetting) {
		typepOptionArray = [...typepOptionArray, { value: POINT_PERCENT, name: ("적립 비율순") }]
	}

	return typepOptionArray;
}

export function getPlaylistsTypeOption() {
	return [
		{ value: VOD_ADD_DATE, name: i18next.t("VOD 추가순") },
		{ value: REG_DATE, name: i18next.t("최신순") },
	]
}

/**
 * 유효한 검색 타입 조회
 * @param searchType
 * @returns {string}
 */
export const getSearchType = (searchType) => {
	const types = getSearchTypeOptions().map((option) => option.value);
	return types.find((optionType) => optionType === searchType) || types[0];
};
