import produce from "immer";

import {
	CHECK_PLAYLIST_LAYER,
	FETCH_PLAYLIST_LAYER,
	FETCH_PLAYLIST_LAYER_ERROR,
	FETCH_PLAYLIST_LAYER_MORE,
	FETCH_PLAYLIST_LAYER_MORE_SUCCESS,
	FETCH_PLAYLIST_LAYER_SUCCESS,
	CLEAR_PLAYLIST_LAYER,
	UPDATE_PLAYLIST_LAYER_ERROR,
	UPDATE_PLAYLIST_LAYER_SUCCESS,
} from "actions/playlist.layer.action";

import { CLEAR, ERROR, LOADING, LOADING_MORE, SUCCESS } from "constant/status";

const INITIAL_STATE = {
	status: CLEAR,
	more: true,
	data: [],
};

const playlistLayerReducer = produce((state, action) => {
	switch (action.type) {
		// 재생 목록 조회 시작
		case FETCH_PLAYLIST_LAYER:
			state.status = LOADING;
			return;

		//	재생 목록 더보기 조회
		case FETCH_PLAYLIST_LAYER_MORE:
			state.status = LOADING_MORE;
			return;

		// 재생 목록 조회 성공, 재생 목록 더보기 조회 성공
		case FETCH_PLAYLIST_LAYER_SUCCESS:
		case FETCH_PLAYLIST_LAYER_MORE_SUCCESS:
			state.status = SUCCESS;
			state.more = action.payload.hasMore;

			action.payload.playlists.map((playlist) => {
				playlist.checked = playlist.already;
				return playlist;
			});

			if (action.type === FETCH_PLAYLIST_LAYER_SUCCESS) {
				state.data = action.payload.playlists;
			} else {
				state.data.push(...action.payload.playlists);
			}
			return;

		// 재생 목록 조회 실패
		case FETCH_PLAYLIST_LAYER_ERROR:
			state.status = ERROR;
			return;

		// 재생 목록 레이어 리스트 초기화
		case CLEAR_PLAYLIST_LAYER:
			return INITIAL_STATE;

		// 재생 목록 레이어에 체크
		case CHECK_PLAYLIST_LAYER:
			const idx = action.payload;
			state.data.map((playlist) => {
				if (playlist.idx === idx) {
					playlist.checked = !playlist.checked;
				}
				return playlist;
			});
			return;

		// 재생목록에 선택한 영상 반영 성공
		case UPDATE_PLAYLIST_LAYER_SUCCESS:
			state.data.map((playlist) => {
				playlist.already = playlist.checked;
				return playlist;
			});

			return;

		// 재생목록에 선택한 영상 반영 실패
		case UPDATE_PLAYLIST_LAYER_ERROR:
			state.data.map((playlist) => {
				playlist.checked = playlist.already;
				return playlist;
			});

			return;

		default:
			return;
	}
}, INITIAL_STATE);

export default playlistLayerReducer;
