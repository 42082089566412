import React, { useState, useCallback, useMemo, Fragment, useEffect } from "react";
import Modal from "react-responsive-modal";

const ModalWindow = ({
    children = () => <span />,
    open = false,
    classNames = {
        overlay: "",
        modal: "modal_modal_style ui-pop-m bs-layer",
    },
    styles = {
        modal: { padding: 0 },
    },
    trigger = null,
    onOpen = null,
    onClose = null,
    blockScroll = false,
    center = false,
    focusTrapped=true,
}) => {
    // 모달 레이어 활성 여부
    const [modalOpen, setModalOpen] = useState(false);

    // 모달 레이어 활성화
    const handleOpenModal = useCallback(
        (event) => {
            event.stopPropagation();
            event.nativeEvent.stopImmediatePropagation();

            setModalOpen(true);

            // onOpen 실행
            onOpen && onOpen(event);
        },
        [onOpen],
    );

    // 모달 레이어 비활성화
    const handleCloseModal = useCallback(() => {
        setModalOpen(false);

        // onClose 실행
        onClose && onClose();
    }, [onClose]);

    // 모달 레이어 활성화를 위한 trigger component
    const triggerComponent = useMemo(() => {
        if (!trigger) {
            return null;
        }

        const triggerProps = { key: "T", onClick: handleOpenModal };

        if (typeof trigger === "function") {
            return React.cloneElement(trigger(modalOpen), triggerProps);
        }

        return React.cloneElement(trigger, triggerProps);
    }, [handleOpenModal, modalOpen, trigger]);

    // 모달 레이어
    const layerComponent = useMemo(() => (typeof children === "function" ? children(handleCloseModal) : children), [
        children,
        handleCloseModal,
    ]);

    // modal popup 오픈 여부 세팅
    useEffect(() => {
        setModalOpen(open);
    }, [open]);

    return (
        <Fragment>
            {triggerComponent}
            <Modal
                closeOnOverlayClick
                open={modalOpen}
                onClose={handleCloseModal}
                showCloseIcon={false}
                classNames={classNames}
                styles={styles}
                blockScroll={blockScroll}
                center={center}
                focusTrapped={focusTrapped}
            >
                {layerComponent}
            </Modal>
        </Fragment>
    );
};

export default ModalWindow;
