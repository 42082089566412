import axios from "libs/axios";
import { isWebview, handleFetchErrors, handleFailedToFetch, getAndroidCompareVersion, addBlockedStreamerId } from "helpers";
import { DOMAIN_URL, MOBILE_DOMAIN, APP_SCHEME } from "constant/config";
import { BJ_API_DOMAIN } from "constant/config";
import { toastr } from "components/toastr";
import i18next from "i18next";
import { HIDE_POST_SUCCESS } from "actions/post.action";


export const fetchTitleBlind = (titleNo = 0, blindId = "", close) => async (dispatch) => {
    
    fetch(`${BJ_API_DOMAIN}/${global.bj_id}/title/${titleNo}/blind${blindId ? ('?blind_id=' + blindId) : '' }`, {
        credentials: "include",
    })
        .then(handleFetchErrors, handleFailedToFetch)
        .then((response) => response.json())
        .then((response) => {
            close();
            dispatch({
                type: HIDE_POST_SUCCESS,
                payload: { title_no : titleNo },
            });
                    
            toastr.success(i18next.t(response.message), {
                timeOut: 2000,
            });
            
            // 채널이 아닌 일반 유저 게시글 차단일 경우 웹뷰 close 대상 X
            const isWebViewClose = blindId === global.bj_id; 

            // 게시물 숨기기 후 방송 숨기기 정책은 제거 (24.03)
            // setTimeout(async () => {
            //     fetchLiveBlind(blindId, isWebViewClose);
            // }, 300);
            if (isWebViewClose) {
                window.location.href = isWebview ? `${APP_SCHEME}browser/close` : DOMAIN_URL;
            }
        })
        .catch((error) => {
            close();
            //숨기기 개수 초과
            if(error.code === 1210){
                if(getAndroidCompareVersion("7.10.0")) {
                    if(window.confirm(error.message)) {
                        window.location.href = `${MOBILE_DOMAIN}/station/titleblind/a/list`;
                    }
                }
                else {
                    alert("게시글 숨기기 개수를 초과했습니다. 숨기기 해제 요청을 해주세요");
                }
            }
            else {
                toastr.error(error.message, {
                    timeOut: 2000,
                });
            }
        });
}

/*
 * LIVE 정보 확인 및 AOS func call
 */
export const fetchLiveBlind = async (targetId = '', isWebViewClose = true) => {
    const url = `${BJ_API_DOMAIN}/${global.bj_id}/broadInfo/${targetId}`;
    try {
        const response = await axios.get(url);
        let target_broad_no = '';
        let target_id = targetId;
        if(response.data) {
            target_broad_no = response.data.broad_no;
            target_id = response.data.user_id;
        } 

        // AOS 웹뷰 function call -> 안드 로컬에서 메인 방송 리스트의 targetId,broad_no hidden처리
        // window.ReportBridge.reportUgcPolicy(target_id, target_broad_no); // deprecated : 240926 android팀 지오님 요청

        // 240524 GT : 블록한 bjid를 로컬 스토리지에 저장한다. -> 포스트 화면 댓글 목록에서 차단 처리하기 위함
        addBlockedStreamerId(targetId);
    } catch (error) {
        
    } finally {
        if (isWebViewClose) {
            window.location.href = isWebview ? `${APP_SCHEME}browser/close` : DOMAIN_URL;
        }
    }
}