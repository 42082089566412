import { fromJS, List, Map } from "immutable";
import {
    COMMENT_FORM_RESET,
    DELETE_COMMENT_SUCCESS,
    DELETE_REPLY_SUCCESS,
    DESTROY_COMMENT_LIKE_SUCCESS,
    FETCH_COMMENTS,
    FETCH_COMMENTS_FAILURE,
    FETCH_COMMENTS_MORE_SUCCESS,
    FETCH_COMMENTS_PREVIOUS_MORE_SUCCESS,
    FETCH_COMMENTS_SUCCESS,
    FETCH_REPLY,
    FETCH_REPLY_FAILURE,
    FETCH_REPLY_SUCCESS,
    STORE_COMMENT_LIKE_SUCCESS,
    STORE_COMMENT_SUCCESS,
    STORE_REPLY_SUCCESS,
    UPDATE_COMMENT_SUCCESS,
    UPDATE_REPLY_CANCEL,
    UPDATE_REPLY_SUCCESS,
    COMMENT_HIDDEN,
    COMMENT_HIDDEN_CLEAR,
    REPLY_HIDDEN,
    REPLY_HIDDEN_CLEAR,
} from "../actions/comments.action";

const INITIAL_STATE = {
    error: null,
    loading: false,
    comments: {
        data: [],
        hidden_list: [],
    },
    reset: 1,
    fetched: 0,
};

const findCommentAndUpdateReply = (state, p_comment_no, callback) => {
    const data = List(state.getIn(["comments", "data"]));
    return state.setIn(
        ["comments", "data"],
        data.update(
            data.findIndex((comment) => {
                return Map(comment).get("p_comment_no") === parseInt(p_comment_no, 10);
            }),
            (comment) => callback.call(this, comment),
        ),
    );
};

export default function commentsReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        //게시글 리스트
        case FETCH_COMMENTS:
            return { ...state, loading: true, fetched: 0 };

        case FETCH_COMMENTS_FAILURE:
            return { ...state, loading: false, error: action.payload };

        case FETCH_COMMENTS_SUCCESS:
            return { ...state, loading: false, comments: action.payload, fetched: state.fetched + 1 };

        case FETCH_COMMENTS_MORE_SUCCESS: {
            const oldState = fromJS(state);
            const newComments = Map(fromJS(action.payload));
            const oldData = oldState.getIn(["comments", "data"]);
            const concatData = oldData.concat(newComments.get("data"));
            return oldState.update("comments", () => newComments.update("data", () => concatData)).toJS();
        }
        case FETCH_COMMENTS_PREVIOUS_MORE_SUCCESS: {
            const oldState = fromJS(state);
            const newComments = Map(fromJS(action.payload));
            const oldData = oldState.getIn(["comments", "data"]);
            const concatData = newComments.get("data").concat(oldData);
            return oldState.update("comments", () => newComments.update("data", () => concatData)).toJS();
        }

        case STORE_COMMENT_SUCCESS: {
            //베스트 다음글 index 가져오기
            const oldState = fromJS(state);
            const index = oldState
                .getIn(["comments", "data"])
                .findIndex((comment) => comment.get("is_best_top") === false && comment.get("is_pin") === false);
            //베스트 다음에 끼워넣기
            const data = oldState.getIn(["comments", "data"]).splice(index, 0, Map(action.payload));
            return oldState
                .setIn(["comments", "data"], data)
                .updateIn(["comments", "total"], (total) => total + 1)
                .toJS();
        }
        case DELETE_COMMENT_SUCCESS: {
            const oldState = fromJS(state);
            //삭제된 댓글을 댓글리스트에서 제외
            const { p_comment_no } = action.payload;
            const data = List(oldState.getIn(["comments", "data"])).filterNot(
                (comment) => Map(comment).get("p_comment_no") === parseInt(p_comment_no, 10),
            );
            return oldState
                .setIn(["comments", "data"], data)
                .updateIn(["comments", "total"], (total) => total - 1)
                .toJS();
        }
        case UPDATE_COMMENT_SUCCESS: {
            const oldState = fromJS(state);
            const { p_comment_no } = action;
            const data = List(oldState.getIn(["comments", "data"]));
            return oldState
                .setIn(
                    ["comments", "data"],
                    data.update(
                        data.findIndex((comment) => {
                            return Map(comment).get("p_comment_no") === p_comment_no;
                        }),
                        (comment) => {
                            return Map(
                                fromJS({
                                    ...action.payload,
                                    is_best_top: comment.get("is_best_top"),
                                    is_pin: comment.get("is_pin"),
                                    pin_nick: comment.get("pin_nick"),
                                    replies: comment.get("replies") || {},
                                }),
                            );
                        },
                    ),
                )
                .toJS();
        }

        //답글 리스트
        case FETCH_REPLY: {
            const oldState = fromJS(state);
            const { p_comment_no } = action;
            return findCommentAndUpdateReply(oldState, p_comment_no, (comment) => {
                return Map(comment).setIn(["replies", "loading"], true);
            }).toJS();
        }

        case FETCH_REPLY_SUCCESS: {
            const oldState = fromJS(state);
            //댓글을 찾아서 밑에다 붙임
            const { p_comment_no, payload } = action;
            //console.log(payload);
            return findCommentAndUpdateReply(oldState, p_comment_no, (comment) => {
                return Map(comment).set(
                    "replies",
                    Map({
                        loading: false,
                        data: List(payload.data),
                        reply_hidden_list: List(payload.reply_hidden_list),
                    }),
                );
            }).toJS();
        }
        case FETCH_REPLY_FAILURE: {
            const oldState = fromJS(state);
            const { p_comment_no, payload } = action;
            return findCommentAndUpdateReply(oldState, p_comment_no, (comment) => {
                return Map(comment).set(
                    "replies",
                    Map({
                        loading: false,
                        error: payload,
                    }),
                );
            }).toJS();
        }

        case STORE_REPLY_SUCCESS: {
            const oldState = fromJS(state);
            //새로 작업한 댓글을 맨위에 추가함
            const { p_comment_no, payload } = action;
            return findCommentAndUpdateReply(oldState, p_comment_no, (comment) => {
                return Map(comment).set(
                    "replies",
                    Map({
                        loading: false,
                        data: comment.getIn(["replies", "data"]).push(payload),
                    }),
                );
            }).toJS();
        }
        case DELETE_REPLY_SUCCESS: {
            const oldState = fromJS(state);
            //삭제된 댓글을 댓글리스트에서 제외
            const { p_comment_no, c_comment_no } = action;
            return findCommentAndUpdateReply(oldState, p_comment_no, (comment) => {
                return Map(comment).set(
                    "replies",
                    Map({
                        loading: false,
                        data: comment.getIn(["replies", "data"]).filterNot((reply) => {
                            return Map(reply).get("c_comment_no") === parseInt(c_comment_no, 10);
                        }),
                    }),
                );
            }).toJS();
        }

        case UPDATE_REPLY_CANCEL: {
            const oldState = fromJS(state);
            const { p_comment_no, c_comment_no } = action;
            return findCommentAndUpdateReply(oldState, p_comment_no, (comment) => {
                const data = comment.getIn(["replies", "data"]);
                return Map(comment)
                    .set(
                        "replies",
                        Map({
                            loading: false,
                            data: data.update(
                                data.findIndex((reply) => {
                                    return Map(reply).get("c_comment_no") === c_comment_no;
                                }),
                                (reply) => {
                                    return Map(reply)
                                        .set("is_update", false)
                                        .toJS();
                                },
                            ),
                        }),
                    )
                    .toJS();
            });
        }
        case UPDATE_REPLY_SUCCESS: {
            const oldState = fromJS(state);
            const { p_comment_no, c_comment_no, payload } = action;
            return findCommentAndUpdateReply(oldState, p_comment_no, (comment) => {
                const data = comment.getIn(["replies", "data"]);
                return Map(comment).set(
                    "replies",
                    Map({
                        loading: false,
                        data: data.update(
                            data.findIndex((reply) => {
                                return Map(reply).get("c_comment_no") === c_comment_no;
                            }),
                            () => {
                                return payload;
                            },
                        ),
                    }),
                );
            }).toJS();
        }

        case STORE_COMMENT_LIKE_SUCCESS: {
            const oldState = fromJS(state);
            const { p_comment_no, user_id } = action.payload;
            const data = List(oldState.getIn(["comments", "data"]));
            return oldState
                .setIn(
                    ["comments", "data"],
                    data.update(
                        data.findIndex((comment) => {
                            return Map(comment).get("p_comment_no") === p_comment_no;
                        }),
                        (comment) =>
                            Map(comment)
                                .update("is_like", () => 1)
                                .update("bjlike", (bjlike) => (global.bj_id === user_id ? true : bjlike)),
                    ),
                )
                .toJS();
        }
        case DESTROY_COMMENT_LIKE_SUCCESS: {
            const oldState = fromJS(state);
            const { p_comment_no, user_id } = action.payload;
            const data = List(oldState.getIn(["comments", "data"]));
            return oldState
                .setIn(
                    ["comments", "data"],
                    data.update(
                        data.findIndex((comment) => {
                            return Map(comment).get("p_comment_no") === p_comment_no;
                        }),
                        (comment) =>
                            Map(comment)
                                .update("is_like", () => 0)
                                .update("bjlike", (bjlike) => {
                                    return global.bj_id === user_id ? false : bjlike;
                                }),
                    ),
                )
                .toJS();
        }

        case COMMENT_FORM_RESET:
            const oldState = fromJS(state);
            return oldState.update("reset", (reset) => reset + 1).toJS();


        case COMMENT_HIDDEN: 
            state.comments.hidden_list.push(action.payload);
            
            return state;
        
        case COMMENT_HIDDEN_CLEAR: 
            const index = state.comments.hidden_list.indexOf(action.payload);
            state.comments.hidden_list.splice(index, 1);
            
            return state;

        case REPLY_HIDDEN: 

            const replyHiddenIdx = state.comments.data.findIndex((comment) => {
                return comment.p_comment_no === action.p_comment_no;
            });
            state.comments.data[replyHiddenIdx].replies.reply_hidden_list.push(action.c_comment_no);
        
        return state;
        
        case REPLY_HIDDEN_CLEAR: 
            const commentHiddenIdx = state.comments.data.findIndex((comment) => {
                return comment.p_comment_no === action.p_comment_no;
            });

            var tmpData = state.comments.data[commentHiddenIdx].replies.reply_hidden_list;
            tmpData = tmpData.filter((item) => {

                return item !== action.c_comment_no;
            });

            // state에 데이터 갱신
            state.comments.data[commentHiddenIdx].replies.reply_hidden_list = tmpData;
            
            return state;
        
        default:
            return state;
    }
}
