import Moment from "moment";
import React, { Component } from "react";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { fetchDetail } from "../../actions/station.action";
import { numberCommaString } from "../../helpers";
import ScrollArea from "../scrollbar/ScrollArea";
import { JAVASCRIPT_VOID } from "constant/config";

class InfoMore extends Component {
	componentDidMount() {
		this.props.fetchDetail();
	}
	render() {
		const { t, detailInfo, stationInfo } = this.props;
		const { station, subscription } = stationInfo.data;
		const { upd, jointime, broad_start, total_broad_time, active_no } = station;
		const { payload = {} } = detailInfo;
		const { rank = [], count = {} } = payload;
		const up_cnt = upd[`today${active_no}_ok_cnt`];
		const visit_cnt = upd[`today${active_no}_visit_cnt`];
		const scrollbarStyles = { borderRadius: 5 };
		const startDate = Moment(jointime).diff(Moment("2006-03-09")) > 0 ? Moment(jointime).format("YYYY.MM.DD") : "2006.03.09";
		return (
			<div
				className="ui-pop layer_bs-info active"
				style={{ position: "absolute", top: 40, left: 4 }}
				onClick={(e) => {
					e.stopPropagation();
					e.nativeEvent.stopImmediatePropagation();
				}}
			>
				<div className="pop-title">
					<h3>
						<Trans>채널 정보</Trans>
					</h3>
				</div>
				<div className="pop-body info_list">
					<ScrollArea
						speed={2}
						verticalScrollbarStyle={scrollbarStyles}
						verticalContainerStyle={scrollbarStyles}
						horizontalScrollbarStyle={scrollbarStyles}
						horizontalContainerStyle={scrollbarStyles}
						vertical
						horizontal={false}
						smoothScrolling
						stopScrollPropagation={true}
					>
						{rank.length > 0 && (
							<ul>
								{rank.map((rank, index) => (
									<li key={index}>
										<span>{rank.rank_name}</span>
										<strong>{t("{{num}}위", { num: rank.total_rank })}</strong>
									</li>
								))}
							</ul>
						)}
						<ul>
							<li>
								<span>
									<Trans>애청자 수</Trans>
								</span>
								<strong>{t("{{num}}명", { num: numberCommaString(upd.fan_cnt) })}</strong>
							</li>
							<li>
								<span>
									<Trans>구독팬 수</Trans>
								</span>
								<strong>
									{t("{{num}}명", { num: numberCommaString(subscription.total || 0) })}
								</strong>
							</li>
							<li>
								<span>
									<Trans>티어1</Trans>
								</span>
								<strong>
									<em>{t("{{num}}명", { num: numberCommaString(subscription.tier1 || 0) })}</em>
								</strong>
							</li>
							<li>
								<span>
									<Trans>티어2</Trans>
								</span>
								<strong>
									<em>{t("{{num}}명", { num: numberCommaString(subscription.tier2 || 0) })}</em>
								</strong>
							</li>
							<li>
								<span>
									<Trans>팬클럽 수</Trans>
								</span>
								<strong>{t("{{num}}명", { num: numberCommaString(count.fanclub) })}</strong>
							</li>
							<li>
								<span>
									<Trans>서포터 수</Trans>
								</span>
								<strong>{t("{{num}}명", { num: numberCommaString(count.supporter) })}</strong>
							</li>
						</ul>
						<ul>
							<li>
								<span>
									<Trans>채널 개설일</Trans>
								</span>
								<strong>{startDate}</strong>
							</li>
							<li>
								<span>
									<Trans>총 방송 시간</Trans>
								</span>
								<strong>{t("{{num}}시간", { num: numberCommaString(Math.round(total_broad_time / 60 / 60)) })}</strong>
							</li>
							<li>
								<span>
									<Trans>누적 유저</Trans>
								</span>
								<strong>{t("{{num}}명", { num: numberCommaString(upd.total_view_cnt) })}</strong>
							</li>
							<li>
								<span>
									<Trans>누적 UP 수</Trans>
								</span>
								<strong>{numberCommaString(upd.total_ok_cnt)}</strong>
							</li>
							<li>
								<span>
									<Trans>오늘의 UP</Trans>
								</span>
								<strong>{numberCommaString(up_cnt)}</strong>
							</li>
							<li>
								<span>
									<Trans>누적 방문 수</Trans>
								</span>
								<strong>{numberCommaString(upd.total_visit_cnt)}</strong>
							</li>
							<li>
								<span>
									<Trans>오늘 방문 수</Trans>
								</span>
								<strong>{numberCommaString(visit_cnt)}</strong>
							</li>
							<li>
								<span>
									<Trans>최근 방송일</Trans>
								</span>
								<strong>
									{broad_start === "0000-00-00 00:00:00"
										? "-"
										: [Moment(broad_start).format("YYYY.MM.DD"), <br />, Moment(broad_start).format("HH:mm")]}
								</strong>
							</li>
						</ul>
					</ScrollArea>
				</div>
				<a href={JAVASCRIPT_VOID} className="pop-close" onClick={this.props.close}>
					<span>
						<Trans>닫기</Trans>
					</span>
				</a>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		detailInfo: state.stationReducer.detailInfo,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchDetail: () => {
			dispatch(fetchDetail());
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withTranslation()(InfoMore));
