import { produce } from "immer";

import {
    CLEAR_VOD_DOWN_REJECT_LIST,
    FETCH_VOD_DOWN_REJECT_LIST,
    FETCH_VOD_DOWN_REJECT_LIST_FAILURE,
    FETCH_VOD_DOWN_REJECT_LIST_SUCCESS,
} from "actions/setting.vodreject.list.action";

import { CLEAR, ERROR, LOADING, SUCCESS } from "constant/status";

const INITIAL_STATE = {
    status: CLEAR,
    error: false,
    pagination: [],
};

const settingVodRejectListReducer = produce((state, { type, payload }) => {
    switch (type) {
        // vod 유저 권한 리스트 조회 시작
        case FETCH_VOD_DOWN_REJECT_LIST:
            state.status = LOADING;
            break;

        // vod 유저 권한 리스트 조회 성공
        case FETCH_VOD_DOWN_REJECT_LIST_SUCCESS:
            state.status = SUCCESS;
            state.pagination = payload;
            return;

        // vod 유저 권한 리스트 조회 실패
        case FETCH_VOD_DOWN_REJECT_LIST_FAILURE:
            state.status = ERROR;
            state.error = payload;
            return;

        // vod 유저 권한 리스트 초기화
        case CLEAR_VOD_DOWN_REJECT_LIST:
            state = Object.assign(state, INITIAL_STATE);
            return;

        default:
    }
}, INITIAL_STATE);

export default settingVodRejectListReducer;
