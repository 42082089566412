import { FETCH_AUTO_COMPLETE_FAILURE, FETCH_AUTO_COMPLETE_SUCCESS, MODE_HOT_KEYWORD } from "../actions/afreeca.search.action";

const INITIAL_STATE = {
	error: null,
	loading: false,
	keywords: {
		data: null,
		mode: MODE_HOT_KEYWORD,
	},
};

export default function afreecaSearchReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case FETCH_AUTO_COMPLETE_SUCCESS:
			return {
				...state,
				keywords: action.payload,
				loading: true,
			};
		case FETCH_AUTO_COMPLETE_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			};

		default:
			return state;
	}
}
