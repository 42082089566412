import { BJ_API_DOMAIN } from "constant/config";
import querystring from "querystring";
import cookie from "react-cookies";
import { handleFetchErrors, handleFailedToFetch } from "../helpers";
import { toastr } from "../components/toastr";

export const FETCH_ID_SEARCH = "FETCH_ID_SEARCH";
export const FETCH_ID_SEARCH_SUCCESS = "FETCH_ID_SEARCH_SUCCESS";
export const FETCH_ID_SEARCH_FAILURE = "FETCH_ID_SEARCH_FAILURE";

export const CHANGE_KEYWORD = "CHANGE_KEYWORD";
export const CHANGE_SEARCH_STATUS = "CHANGE_SEARCH_STATUS";

export function fetchSearchId(user_id, action = "user") {
	return (dispatch) => {
		const fetch_url = `${BJ_API_DOMAIN}/${global.bj_id}/setting/user/${action}?id=${user_id}`;
		dispatch({ type: FETCH_ID_SEARCH });
		//로딩
		fetch(fetch_url, {
			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then((response) => {
				dispatch({
					type: FETCH_ID_SEARCH_SUCCESS,
					payload: response,
				});
			})
			.catch((error) => {
				const error_message = error.message;
				toastr.error(error_message, {
					timeOut: 2000,
				});
				dispatch({
					type: FETCH_ID_SEARCH_FAILURE,
					payload: error ? error : { code: 0, message: error },
				});
			});
	};
}

export function changeKeyword(keyword) {
	return (dispatch) => {
		dispatch({
			type: CHANGE_KEYWORD,
			payload: keyword,
		});
	};
}

export function changeSerachStatus(search_status) {
	return (dispatch) => {
		dispatch({
			type: CHANGE_SEARCH_STATUS,
			payload: search_status,
		});
	};
}
