import { Map } from "immutable";
import { FETCH_ID_SEARCH, FETCH_ID_SEARCH_SUCCESS, FETCH_ID_SEARCH_FAILURE, CHANGE_KEYWORD, CHANGE_SEARCH_STATUS } from "../actions/search.bj.action";
import { UPDATE_AUTO_MANAGER_MOBILE_SUCCESS } from "../actions/fanclub.action";

const INITIAL_STATE = Map({
	error: null,
	loading: false,
	bj: Map({
		data: {},
	}),
	searching: false,
	keyword: "",
});

export default function searchBjReducer(state = INITIAL_STATE, action) {
	////console.log(action.type);
	switch (action.type) {
		//게시글 리스트
		case FETCH_ID_SEARCH:
			return state.set("loading", true);

		case FETCH_ID_SEARCH_SUCCESS:
			return state.set("loading", false).set("searching",true).set("bj", Map(action.payload));

		case FETCH_ID_SEARCH_FAILURE:
			return state.set("loading", false).set("searching",false).set("error", action.payload);

		case CHANGE_KEYWORD:
			return state.set("keyword",action.payload).set("searching",false);

		case CHANGE_SEARCH_STATUS:
			return state.set("searching",action.payload);

		case UPDATE_AUTO_MANAGER_MOBILE_SUCCESS:
			return state.set("searching",false).set("keyword","");

		default:
			return state;
	}
}
