import { BJ_API_DOMAIN } from "constant/config";
import axios from "libs/axios";
export const FETCH_SAYBUZZ_AD = "FETCH_SAYBUZZ_AD";
export const FETCH_SAYBUZZ_AD_SUCCESS = "FETCH_SAYBUZZ_AD_SUCCESS";
export const FETCH_SAYBUZZ_AD_FAILURE = "FETCH_SAYBUZZ_AD_FAILURE";

export const fetchSaybuzz = (params) => async (dispatch) => {

    try {
        const brandId = "aftv";
        // 로딩 시작
        dispatch({type: FETCH_SAYBUZZ_AD});

        //const params = {};
        const response = await axios.get(`${BJ_API_DOMAIN}/ad/saybuzz`, { params });
        console.log(response);

        //const response = await axios.get(`https://ad.SAYBUZZ.io/ad/brand/${brandId}/campaign/${campaignId}/space/${spaceId}`,
        //    { params, headers: { "SAYBUZZ-Api-Version": "1.0", "accept": "*/*"}, withCredentials: false  });
        //{ headers: { "Content-Type": "application/x-www-form-urlencoded"},  withCredentials: false}

        //return response;

        dispatch({
            type: FETCH_SAYBUZZ_AD_SUCCESS,
            payload: response,
        });
    } catch (error) {
        dispatch({
            type: FETCH_SAYBUZZ_AD_FAILURE,
            payload: error,
        });
    }
};