import { isMobile } from "react-device-detect";


// ANDROID VOD리스트
export const STATION_PLAYLIST_BANNER_ANDROID_WEB = "STATION_PLAYLIST_BANNER_ANDROID_WEB";

// IOS VOD리스트
export const STATION_PLAYLIST_BANNER_IOS_WEB = "STATION_PLAYLIST_BANNER_IOS_WEB";

// PC 게시물 댓글하단
export const STATION_POST_BANNER_PC_WEB = "STATION_POST_BANNER_PC_WEB";

// PC 게시판 & 게시물하단
export const STATION_BOARD_BANNER_PC_WEB = "STATION_BOARD_BANNER_PC_WEB";

// PC VOD리스트 하단
export const STATION_PLAYLIST_BANNER_PC_WEB = "STATION_PLAYLIST_BANNER_PC_WEB";

// ANDROID 게시물 댓글상단
export const STATION_POST_BANNER_ANDROID_WEB = "STATION_POST_BANNER_ANDROID_WEB";

// IOS 게시물 댓글상단
export const STATION_POST_BANNER_IOS_WEB = "STATION_POST_BANNER_IOS_WEB";

// PC 메인 하단(홀수)
export const STATION_MAIN_BOTTOM1_BANNER_PC_WEB = "STATION_MAIN_BOTTOM1_BANNER_PC_WEB";

// PC 메인 하단(짝수)
export const STATION_MAIN_BOTTOM2_BANNER_PC_WEB = "STATION_MAIN_BOTTOM2_BANNER_PC_WEB";

// PC 메인배너
export const TYPE_PC_MAIN_BANNER = "PC_MAIN_STATION_BANNER";

// PC 서브배너
export const TYPE_PC_SUB_BANNER = "PC_728_90_BANNER";

// 모바일 배너
export const TYPE_MOBILE_BANNER = "MOBILE_640_100_BANNER";

//메인 PC 홈 사이드 배너
export const STATION_MAIN_RIGHT_BANNER_PC_WEB = "STATION_MAIN_RIGHT_BANNER_PC_WEB";

//모바일 메인 홈 하단 배너 ANDROID
export const STATION_MAIN_BOTTOM_BANNER_ANDROID_WEB = "STATION_MAIN_BOTTOM_BANNER_ANDROID_WEB";

//모바일 메인 홈 하단 배너 IOS
export const STATION_MAIN_BOTTOM_BANNER_IOS_WEB = "STATION_MAIN_BOTTOM_BANNER_IOS_WEB";

export const PC_IMAGE_BANNER = "PC_IMAGE_BANNER";

export const MOBILE_AD_TEXT_BANNER = "MOBILE_AD_TEXT_BANNER";

export const SDK = "SDK";
export const GOOGLE = "google";

// 영역별 광고호출 코드
export const CODE_VODLIST = "x68";
export const CODE_POST_BOARD = "board@x45";
export const CODE_POST_FOOTER = "board@x46";
export const CODE_VODS_FOOTER = "vod@x45";
export const CODE_MAIN_BOTTOM1 = "x48";
export const CODE_MAIN_BOTTOM2 = "x49";
export const CODE_MAIN_HOME_RIGHT_AND_BOTTOM = "x50";

// 아카시아 광고 지면 조회
export const CODE_SAYBUZZ_HOME = "saybuzz@x1";

export const PC_MAIN_COUPLE_BRAND = "pc_main_couple_brand"; // PC 메인 커플 브랜딩 배너 광고
export const PERSONAL_SPONSORSHIP = "personal_sponsorship"; // 개인형 스폰서십 후원 광고
export const SAYBUZZ = "saybuzz"; // 세이버즈 플랫폼 광고

// 아카시아 space 파람
export const A_PC_STATION = "A_PC_station"; // 채널 PC
export const A_M_STATION = "A_M_station"; // 채널 Mobile


// PC 호출배너 정보조회
export function getPcAdInfo() {
    return [
        { code: CODE_MAIN_BOTTOM1, type: STATION_MAIN_BOTTOM1_BANNER_PC_WEB },
        { code: CODE_MAIN_BOTTOM2, type: STATION_MAIN_BOTTOM2_BANNER_PC_WEB },
        { code: CODE_POST_BOARD, type: STATION_POST_BANNER_PC_WEB },
        { code: CODE_POST_FOOTER, type: STATION_BOARD_BANNER_PC_WEB },
        { code: CODE_VODS_FOOTER, type: STATION_PLAYLIST_BANNER_PC_WEB },
        { code: CODE_MAIN_HOME_RIGHT_AND_BOTTOM,   type: STATION_MAIN_RIGHT_BANNER_PC_WEB }
    ];
}

// IOS 호출배너 정보조회
export function getMobileIosAdInfo() {
    return [
        { code: CODE_VODLIST, type: STATION_PLAYLIST_BANNER_IOS_WEB },
        { code: CODE_POST_BOARD, type: STATION_POST_BANNER_IOS_WEB },
        { code: CODE_POST_FOOTER, type: STATION_POST_BANNER_IOS_WEB },
        { code: CODE_VODS_FOOTER, type: STATION_POST_BANNER_IOS_WEB },
        { code: CODE_MAIN_HOME_RIGHT_AND_BOTTOM, type: STATION_MAIN_BOTTOM_BANNER_IOS_WEB}
    ];
}

// ANDROID 호출배너 정보조회
export function getMobileAndroidAdInfo() {
    return [
        { code: CODE_VODLIST, type: STATION_PLAYLIST_BANNER_ANDROID_WEB },
        { code: CODE_POST_BOARD, type: STATION_POST_BANNER_ANDROID_WEB },
        { code: CODE_POST_FOOTER, type: STATION_POST_BANNER_ANDROID_WEB },
        { code: CODE_VODS_FOOTER, type: STATION_POST_BANNER_ANDROID_WEB },
        { code: CODE_MAIN_HOME_RIGHT_AND_BOTTOM, type: STATION_MAIN_BOTTOM_BANNER_ANDROID_WEB},
    ];
}


// 구광고서버 제거로 pa.afreecav.com response data
// 채널 홈 하단 배너1, 배너2
export function getStationHomeAdInfo(code) {
    switch (code) {
        case CODE_MAIN_BOTTOM1 :
            return {
                type: GOOGLE,
                google_ad_client: 'div-gpt-ad-1554880069244',
                google_ad_slot: '/84015925/Native_Station_Bottom',
                google_ad_width: 439,
                is_empty: false
            }
        case CODE_MAIN_BOTTOM2 :
            return {   
                type: GOOGLE,
                google_ad_client: 'div-gpt-ad-1554880056137',
                google_ad_slot: '/84015925/Native_Station_Bottom2',
                google_ad_width: 439,
                is_empty: false
            }
        default:
            break;
    }
}

/* 채널 
 * PC VOD 하단, 게시글 중 하단
 * Mobile 게시글 하단
*/
export function getStationPostsPaAd(code) {
    let config = {
        type: GOOGLE,
        google_ad_client: '',
        google_ad_slot: '',
        google_ad_width: isMobile ? 320 : 728,
        google_ad_height: isMobile ? 50 : 90,
        is_empty: false
    }

    if (isMobile) {
        return {
            ...config,
            google_ad_client: 'div-gpt-ad-1604295454584-0',
            google_ad_slot: '/84015925/Native_Station_list_new',
        }
    } 

    switch (code) {
        case CODE_POST_BOARD:
            return {
                ...config,
                google_ad_client: 'div-gpt-ad-1575874529375-0',
                google_ad_slot: '/84015925/Station_bottom_noticeboard2',
            }
        case CODE_POST_FOOTER:
            return {
                ...config,
                google_ad_client: 'div-gpt-ad-1575521646676-0',
                google_ad_slot: '/84015925/Station_bottom_noticeboard3',
            }
        case CODE_VODS_FOOTER:
            return {
                ...config,
                google_ad_client: 'div-gpt-ad-1575856969699-0',
                google_ad_slot: '/84015925/Station_bottom_vod2',
            }
        default:
            break;
    }
}


// 모바일 채널 VOD List 10개마다 광고 한 개씩
export const getStationVodPaAd = {
    type: GOOGLE,
    google_ad_client: 'div-gpt-ad-1556780548632-0',
    google_ad_slot: '/84015925/Native_Station_list',
    google_ad_width: 388,
    passbackURL:'',
    is_empty: false
}


/**
 * 호출 광고지면 조회
 * @param code
 * @param is_mobile
 * @param is_android
 * @return string
 */
export function getPlacement(code, is_mobile, is_android) {
    let adInfo = is_mobile ? (is_android ? getMobileAndroidAdInfo() : getMobileIosAdInfo()) : getPcAdInfo();
    adInfo = adInfo.filter((val) => val.code === code);
    return adInfo.length > 0 ? adInfo[0].type : "";
}

/**
 * 아카시아 광고 지면 채널 홈 변경 (x50 > 아카시아 광고 호출)
 */
export function getPcSaybuzzAdInfo() {
    return [
        { code: CODE_SAYBUZZ_HOME, campaign: PERSONAL_SPONSORSHIP, space: A_PC_STATION }
    ];
}
export function getMobileSaybuzzAdInfo() {
    return [
        { code: CODE_SAYBUZZ_HOME, campaign: PERSONAL_SPONSORSHIP, space: A_M_STATION }
    ];
}

export function getSaybuzzParams(code, is_mobile) {
    let adInfo = is_mobile ? getMobileSaybuzzAdInfo() : getPcSaybuzzAdInfo();
    adInfo = adInfo.filter((val) => val.code === code);
    return adInfo.length > 0 ? adInfo[0] : null;
}