import {
	ALL,
	COMMENT_CONTENTS,
	COMMENT_NICKNAME_USER_ID,
	CONTENTS,
	HASHTAGS,
	NICKNAME_USER_ID,
	TITLE,
	TITLE_CONTENTS,
	USER_ID,
} from "constant/search";
import {
	ORDER_COMMENT_CNT,
	ORDER_LIKE_CNT,
	ORDER_LIVE_PV,
	ORDER_READ_CNT,
	ORDER_REG_DATE,
	ORDER_REG_DATE_ASC,
	ORDER_VOD_ADD_DATE,
} from "constant/order";

// export const SCKETC_DOMAIN = "//scketc.afreecatv.com";

// m: 로그 액션
export const M_SEARCH_LOG = "stopWord"; // 기본 검색 로그
export const M_SEARCH_ACTION_LOG = "actionLog"; // 검색 액션 로그

// stype: 검색 종류
export const STYPE_DIRECT = "di"; // 직접 입력
export const STYPE_LATEST = "lc"; // 최근 검색어
export const STYPE_HASHTAG = "ht"; // 해쉬태그 검색
export const STYPE_MY_BOARD = "mb"; // 내가 작성한 글
export const STYPE_USER_ID = "ui"; // 유저 검색

// location: 검색 위치
export const LOCATION_STATION = "station"; // 채널 검색

// acttype: 로그 액션 타입
export const ACTTYPE_POST = "post"; // 채널 게시글
export const ACTTYPE_PLAYLIST = "vod_playlist"; // 채널 재생목록
export const ACTTYPE_TOTAL = "total"; // 통합 검색

// actcode: 로그 액션 세부 코드
export const ACTCODE_TITLE = "tt"; // 타이틀 액션
export const ACTCODE_CONTENTS = "ct"; // 내용 액션
export const ACTCODE_THUMBNAIL = "sn"; // 이미지 썸네일 액션
export const ACTCODE_COMMENT_CONTENTS = "cct"; // 댓글 내용 액션

// st: 정렬 정보
export const ST_LATEST = "latest"; // 최신순
export const ST_REG_DATE = "reg_date"; // 등록순
export const ST_VIEW_CNT = "view_cnt"; // 재생순
export const ST_RECOMM_CNT = "recomm_cnt"; // UP 순
export const ST_MEMO_CNT = "memo_cnt"; // 댓글 순
export const ST_LIVE_PV = "live_pv"; // 라이브 시청 순
export const ST_VOD_ADD_DATE = "vod_add_date"; // vod 추가 순

// ft: 필터링 정보
export const FT_ALL = "all"; // 필터링 없음 (전체 검색)

/**
 * search_filter: 검색 필드 정보
 * @param filter
 * @returns {string}
 */
export function getSearchFilter(filter) {
	switch (filter) {
		case ALL:
			return "all";
		case TITLE_CONTENTS:
			return "tt|ct";
		case TITLE:
			return "tt";
		case CONTENTS:
			return "ct";
		case USER_ID:
			return "id";
		case NICKNAME_USER_ID:
			return "nick|id";
		case COMMENT_CONTENTS:
			return "cct";
		case COMMENT_NICKNAME_USER_ID:
			return "cnick|cid";
		case HASHTAGS:
			return "hash";
		default:
			return "";
	}
}

export function getSortFromOrder(order = ORDER_REG_DATE) {
	switch (order) {
		case ORDER_REG_DATE:
			return ST_LATEST;
		case ORDER_REG_DATE_ASC:
			return ST_REG_DATE;
		case ORDER_READ_CNT:
			return ST_VIEW_CNT;
		case ORDER_COMMENT_CNT:
			return ST_MEMO_CNT;
		case ORDER_LIKE_CNT:
			return ST_RECOMM_CNT;
		case ORDER_LIVE_PV:
			return ST_LIVE_PV;
		case ORDER_VOD_ADD_DATE:
			return ST_VOD_ADD_DATE;

		default:
			return ST_LATEST;
	}
}
