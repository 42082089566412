import { fetchNotice } from "actions/station.action";
import { JAVASCRIPT_VOID } from "constant/config";
import { localStorageFactory } from "helpers";
import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import { Trans } from "react-i18next";
import { connect } from "react-redux";

class NoticePopup extends Component {
    static defaultProps = {
        offsetX: 0,
        offsetY: 0,
    };
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            notice: {},
        };
    }
    componentDidMount() {
        if (this.isNoticeExpire()) {
            this.props.fetchNotice();
        }
    }
    componentWillReceiveProps(nextProps) {
        //페이지 이동 -> 공지사항 팝업 노출/비노출 체크
        if (nextProps.isLocationChange) {
            if (this.isNoticeExpire()) {
                this.handleClose();
            }
        }
        if (this.props.noticeInfo !== nextProps.noticeInfo) {
            if (this.isNoticeExpire()) {
                const { fix = [], nonfix = [] } = nextProps.noticeInfo;
                if (fix.length > 0) {
                    //날짜가 지정된것 중에 우선 (랜덤)
                    const notice = fix[Math.floor(Math.random() * fix.length)];
                    this.setState({
                        isOpen: true,
                        notice,
                    });
                } else if (nonfix.length > 0) {
                    //날짜가 없는것
                    const notice = nonfix[Math.floor(Math.random() * nonfix.length)];
                    this.setState({
                        isOpen: true,
                        notice,
                    });
                }
            }
        }
    }

    /**
     * 오늘하루 안보기가 만료가 된는지~
     */
    isNoticeExpire() {
        var now = new Date().getTime();
        if (now < parseInt(localStorageFactory.getItem(`notice_expire`), 10)) {
            return false;
        } else {
            return true;
        }
    }

    /**
     * 닫기버튼 누르면 30분간 안보임
     */
    handleClose = () => {
        let expire = new Date().getTime();
        expire += 30 * 60 * 1000;
        localStorageFactory.setItem(`notice_expire`, expire);
        this.setState({
            isOpen: false,
        });
    };

    /**
     * 일주일뒤를 만료일자로함
     */
    handleOneWeekExpire = () => {
        let expire = new Date().getTime();
        expire += 24 * 7 * 60 * 60 * 1000;
        localStorageFactory.setItem(`notice_expire`, expire);
        this.setState({
            isOpen: false,
        });
    };

    render() {
        const { isOpen, notice } = this.state;
        if (!notice) {
            return null;
        }
        return (
            <div className="bs-notice_layer" style={{ display: isOpen ? "block" : "none" }}>
                <div className="conts">
                    <h2>{notice.title}</h2>
                    <div className="text_box">
                        {notice.image ? (
                            <a href={notice.link_url} className="notice-img" target={notice.target} onClick={this.handleClose}>
                                <img src={notice.image} alt="" />
                            </a>
                        ) : (
                            ReactHtmlParser(notice.detail)
                        )}
                    </div>
                </div>
                <div className="todays">
                    <div className="chk-box">
                        <span>
                            <input type="checkbox" name="" id="chk1" onChange={this.handleOneWeekExpire} />
                            <i />
                        </span>
                        <label htmlFor="chk1">
                            <Trans>일주일 간 보지 않기</Trans>
                        </label>
                    </div>
                    <button type="button" className="btn-basic submit" onClick={this.handleClose}>
                        <span>
                            <Trans>닫기</Trans>
                        </span>
                    </button>
                </div>
                <a href={JAVASCRIPT_VOID} className="notice-close" onClick={this.handleClose}>
                    <span>
                        <Trans>닫기</Trans>
                    </span>
                </a>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        noticeInfo: state.stationReducer.noticeInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchNotice: () => {
            dispatch(fetchNotice());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(NoticePopup);
