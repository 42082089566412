import React from "react";
import { isMobile } from "react-device-detect";
import { Trans } from "react-i18next";
import i18next from "i18next";

export const BadgeMore = (props) => {
	const { groups, user_nick } = props;
	if (isMobile) {
		return (
			<div className="ui-pop-m badge_layer_mo">
				<div className="pop-title">
					<Trans><span className="nick">{{user_nick}}</span>의 배지</Trans>
				</div>
				<div className="pop-body">
                    <div className="bj_badge">
                        {groups.map((groups, index) => (
                            <i key={index} style = {{backgroundColor : groups.info.group_background_color}}>
                                <Trans>{groups.info.group_name}</Trans>
                            </i>
                        ))}
                    </div>
				</div>
				<div className="pop-btn" onClick={props.onClose}>
					<button type="button" className="btn_done"> {i18next.t("확인")} </button>
				</div>
			</div>
		);
	} else {
		return (
			<div className="badge_layer" style={{display :"block"}}>
				<h3> 
						<span className="nick">{user_nick}</span><Trans>의 배지</Trans>
                </h3>
                <div className="badge_wrap">
                    {groups.map((groups, index) => (
                        <i key={index} style = {{backgroundColor : groups.info.group_background_color}}>
                            <Trans>{groups.info.group_name}</Trans>
                        </i>
                    ))}
                </div>
				<button type="button" className="btn_close"> 닫기 </button>
			</div>
		);
	}
};
