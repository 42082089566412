import { SEARCH_ALL, TITLE_CONTENTS } from "constant/search";
import { ALL_MONTHS } from "constant/month";
import axios from "libs/axios";

export const FETCH_POST_LIST = "FETCH_POST_LIST";
export const FETCH_POST_LIST_SUCCESS = "FETCH_POST_LIST_SUCCESS";
export const FETCH_POST_LIST_FAILURE = "FETCH_POST_LIST_FAILURE";

export const CLEAR_POST_LIST = "CLEAR_POST_LIST";

/**
 * 게시글 리스트 로딩
 * @param key
 * @param page
 * @param perPage
 * @param field
 * @param keyword
 * @param type
 * @param bbsNo
 * @param months
 * @returns {function(...[*]=)}
 */
export const fetchPostList = ({
	page = 1,
	perPage = 20,
	field = TITLE_CONTENTS,
	keyword = "",
	type = SEARCH_ALL,
	months = ALL_MONTHS,
	bbsNo = null,
}) => async (dispatch) => {
	// 로딩 시작
	dispatch({ type: FETCH_POST_LIST });

	// 게시글 리스트 조회 url
	let url = `/${global.bj_id}/board`;
	bbsNo && (url += `/${bbsNo}`);

	// 게시글 리스트 조회 parameter
	const params = {
		page,
		per_page: perPage,
		field,
		keyword,
		type,
		months,
	};

	try {
		// api 통신 시작
		const response = await axios.get(url, { params });

		dispatch({
			type: FETCH_POST_LIST_SUCCESS,
			payload: response,
		});
	} catch (error) {
		dispatch({
			type: FETCH_POST_LIST_FAILURE,
			payload: error,
		});
	}
};

export const fetchBoardViewAllList = ({
	page = 1,
	perPage = 20,
	type = SEARCH_ALL,
	bbsNo = null,
	titleNo = 0
}) => async (dispatch) => {
	// 로딩 시작
	dispatch({ type: FETCH_POST_LIST });

	// 게시글 리스트 조회 url
	let url = `/${global.bj_id}/boardlist`;
	bbsNo && (url += `/${bbsNo}`);

	// 게시글 리스트 조회 parameter
	const params = {
		page,
		per_page: perPage,
		type,
		title_no: titleNo
	};

	try {
		// api 통신 시작
		const response = await axios.get(url, { params });

		dispatch({
			type: FETCH_POST_LIST_SUCCESS,
			payload: response,
		});
	} catch (error) {
		dispatch({
			type: FETCH_POST_LIST_FAILURE,
			payload: error,
		});
	}
};
