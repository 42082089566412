import { BJ_API_DOMAIN } from "constant/config";
import querystring from "querystring";

import axios from "libs/axios";

/**
 * 탈퇴 신청 여부 조회
 * @param page
 * @returns {Function}
 */

export const fetchDrawalUserInfo = async () => {
    try { 
        // const params = querystring.stringify({ user_id: global.bj_id });
        // let url = `${BJ_API_DOMAIN}/api/bj_member_info_api.php?${params}`;
        let url = `${BJ_API_DOMAIN}/${global.bj_id}/withDraw`;

        const response = await axios.get(url);

        const { withDraw = 0 } = response;
        
        return withDraw;

    } catch (error) {
        return 0;
    }

}
