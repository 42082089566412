import { EDITTOR_INSERT_HTML, EDITTOR_REMOVE_IMAGE_TAG, EDITTOR_SET_DATA, EDITTOR_COMMAND } from "../actions/ckeditor.action";
const INITIAL_STATE = {
	payload: "",
};

export default function ckeditorReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case EDITTOR_SET_DATA:
			return {
				...state,
				type: action.type,
				payload: action.payload,
			};
			break;
		//에디터 태그추가
		case EDITTOR_INSERT_HTML:
			return {
				...state,
				type: action.type,
				payload: action.payload,
			};
		//에디터 태그제거
		case EDITTOR_REMOVE_IMAGE_TAG:
			return {
				...state,
				type: action.type,
				payload: action.payload,
			};
		case EDITTOR_COMMAND:
			console.log("action.payload", action.payload);
			return {
				...state,
				type: action.type,
				payload: action.payload,
			};
		default:
			return state;
	}
}
