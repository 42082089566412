import React from "react";
import PropTypes from "prop-types";
import lineHeight from "line-height";
import { Motion, spring } from "react-motion";
import { warnAboutFunctionChild, warnAboutElementChild, positiveOrZero, modifyObjValues } from "./utils";
import ScrollBar from "./Scrollbar";

const eventTypes = {
	wheel: "wheel",
	api: "api",
	touch: "touch",
	touchEnd: "touchEnd",
	mousemove: "mousemove",
	keyPress: "keypress",
};

class ScrollArea extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			topPosition: 0,
			leftPosition: 0,
			realHeight: 0,
			containerHeight: 0,
			realWidth: 0,
			containerWidth: 0,
		};
		this.contentRef = React.createRef()

		this.scrollArea = {
			refresh: () => {
				this.setSizesToState();
			},
			scrollTop: () => {
				this.scrollTop();
			},
			scrollBottom: () => {
				this.scrollBottom();
			},
			scrollYTo: (position) => {
				this.scrollYTo(position);
			},
			scrollLeft: () => {
				this.scrollLeft();
			},
			scrollRight: () => {
				this.scrollRight();
			},
			scrollXTo: (position) => {
				this.scrollXTo(position);
			},
		};

		this.evntsPreviousValues = {
			clientX: 0,
			clientY: 0,
			deltaX: 0,
			deltaY: 0,
		};

		this.bindedHandleWindowResize = this.handleWindowResize.bind(this);
		this.handleScrollbarMove = this.handleScrollbarMove.bind(this);
		this.handleScrollbarYPositionChange = this.handleScrollbarYPositionChange.bind(this);
		this.handleScrollbarXPositionChange = this.handleScrollbarXPositionChange.bind(this);
		this.focusContent = this.focusContent.bind(this);
		this.handleTouchStart = this.handleTouchStart.bind(this);
		this.handleTouchMove = this.handleTouchMove.bind(this);
		this.handleTouchEnd = this.handleTouchEnd.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.handleWheel = this.handleWheel.bind(this);
	}

	getChildContext() {
		return {
			scrollArea: this.scrollArea,
		};
	}

	getLineHeightPx() {
		return this.contentRef ? lineHeight(this.contentRef.current) : 14
	}

	componentDidMount() {
		if (this.props.contentWindow) {
			this.props.contentWindow.addEventListener("resize", this.bindedHandleWindowResize);
		}

		this.setSizesToState();

		/**
		 * 크롬,파이어폭스에서 <div onWheel=""> stopPropagation 가 안되서  addEventListener로 추가
		 */
		this.wrapper.addEventListener("onwheel" in document ? "wheel" : "mousewheel", this.handleWheel);
	}

	componentDidUpdate() {
		this.setSizesToState();
	}

	componentWillUnmount() {
		if (this.props.contentWindow) {
			this.props.contentWindow.removeEventListener("resize", this.bindedHandleWindowResize);
		}
	}

	setStateFromEvent(newState, eventType) {
		if (this.props.onScroll) {
			this.props.onScroll(newState);
		}
		this.setState({ ...newState, eventType });
	}

	getModifiedPositionsIfNeeded(state) {
		const newState = { ...state };
		const bottomPosition = state.realHeight - state.containerHeight;
		if (this.state.topPosition >= bottomPosition) {
			newState.topPosition = this.canScrollY(state) ? positiveOrZero(bottomPosition) : 0;
		}

		const rightPosition = newState.realWidth - newState.containerWidth;
		if (this.state.leftPosition >= rightPosition) {
			newState.leftPosition = this.canScrollX(newState) ? positiveOrZero(rightPosition) : 0;
		}

		return newState;
	}

	setSizesToState() {
		const sizes = this.computeSizes();
		if (sizes.realHeight !== this.state.realHeight || sizes.realWidth !== this.state.realWidth) {
			this.setStateFromEvent(this.getModifiedPositionsIfNeeded(sizes));
		}
	}

	computeSizes() {
		const realHeight = this.contentRef.current.offsetHeight;
		const containerHeight = this.wrapper.offsetHeight;
		const realWidth = this.contentRef.current.offsetWidth;
		const containerWidth = this.wrapper.offsetWidth;

		return {
			realHeight,
			containerHeight,
			realWidth,
			containerWidth,
		};
	}

	normalizeLeftPosition(leftPosition, sizes) {
		let newLeftPosition;
		if (leftPosition > sizes.realWidth - sizes.containerWidth) {
			newLeftPosition = sizes.realWidth - sizes.containerWidth;
		} else if (leftPosition < 0) {
			newLeftPosition = 0;
		}
		return newLeftPosition;
	}

	normalizeTopPosition(topPosition, sizes) {
		let newTopPosition = topPosition;
		if (topPosition > sizes.realHeight - sizes.containerHeight) {
			newTopPosition = sizes.realHeight - sizes.containerHeight;
		}
		if (topPosition < 0) {
			newTopPosition = 0;
		}
		return newTopPosition;
	}

	computeLeftPosition(deltaX, sizes) {
		const newLeftPosition = this.state.leftPosition - deltaX;
		return this.normalizeLeftPosition(newLeftPosition, sizes);
	}

	scrollTop() {
		this.scrollYTo(0);
	}

	scrollBottom() {
		this.scrollYTo(this.state.realHeight - this.state.containerHeight);
	}

	scrollLeft() {
		this.scrollXTo(0);
	}

	scrollRight() {
		this.scrollXTo(this.state.realWidth - this.state.containerWidth);
	}

	scrollYTo(topPosition) {
		if (this.canScrollY()) {
			const position = this.normalizeTopPosition(topPosition, this.computeSizes());
			this.setStateFromEvent({ topPosition: position }, eventTypes.api);
		}
	}

	scrollXTo(leftPosition) {
		if (this.canScrollX()) {
			const position = this.normalizeLeftPosition(leftPosition, this.computeSizes());
			this.setStateFromEvent({ leftPosition: position }, eventTypes.api);
		}
	}

	canScrollY(state = this.state) {
		const scrollableY = state.realHeight > state.containerHeight;
		return scrollableY && this.props.vertical;
	}

	canScrollX(state = this.state) {
		const scrollableX = state.realWidth > state.containerWidth;
		return scrollableX && this.props.horizontal;
	}

	canScroll(state = this.state) {
		return this.canScrollY(state) || this.canScrollX(state);
	}

	computeTopPosition(deltaY, sizes) {
		const newTopPosition = this.state.topPosition - deltaY;
		return this.normalizeTopPosition(newTopPosition, sizes);
	}

	composeNewState(deltaX, deltaY) {
		const newState = this.computeSizes();

		if (this.canScrollY(newState)) {
			newState.topPosition = this.computeTopPosition(deltaY, newState);
		} else {
			newState.topPosition = 0;
		}
		if (this.canScrollX(newState)) {
			newState.leftPosition = this.computeLeftPosition(deltaX, newState);
		}
		return newState;
	}

	focusContent() {
		if (this.contentRef.current) {
			this.contentRef.current.focus();
		}
	}

	handleTouchStart(e) {
		const { touches } = e;
		if (touches.length === 1) {
			const { clientX, clientY } = touches[0];
			this.eventPreviousValues = {
				...this.eventPreviousValues,
				clientY,
				clientX,
				timestamp: Date.now(),
			};
		}
	}

	handleTouchMove(e) {
		if (this.canScroll()) {
			e.preventDefault();
			e.stopPropagation();
		}

		const { touches } = e;
		if (touches.length === 1) {
			const { clientX, clientY } = touches[0];

			const deltaY = this.eventPreviousValues.clientY - clientY;
			const deltaX = this.eventPreviousValues.clientX - clientX;

			this.eventPreviousValues = {
				...this.eventPreviousValues,
				deltaY,
				deltaX,
				clientY,
				clientX,
				timestamp: Date.now(),
			};

			this.setStateFromEvent(this.composeNewState(-deltaX, -deltaY));
		}
	}

	handleTouchEnd() {
		const { deltaX, deltaY, timestamp } = this.eventPreviousValues;
		const newDeltaX = typeof deltaX === "undefined" ? 0 : deltaX;
		const newDeltaY = typeof deltaY === "undefined" ? 0 : deltaY;
		if (Date.now() - timestamp < 200) {
			this.setStateFromEvent(this.composeNewState(-newDeltaX * 10, -newDeltaY * 10), eventTypes.touchEnd);
		}

		this.eventPreviousValues = {
			...this.eventPreviousValues,
			deltaY: 0,
			deltaX: 0,
		};
	}

	handleScrollbarMove(deltaY, deltaX) {
		this.setStateFromEvent(this.composeNewState(deltaX, deltaY));
	}

	handleScrollbarXPositionChange(position) {
		this.scrollXTo(position);
	}

	handleScrollbarYPositionChange(position) {
		this.scrollYTo(position);
	}

	handleWheel(e) {
		let deltaY = e.deltaY;
		let deltaX = e.deltaX;

		if (this.props.swapWheelAxes) {
			[deltaY, deltaX] = [deltaX, deltaY];
		}

		/*
		 * WheelEvent.deltaMode can differ between browsers and must be normalized
		 * e.deltaMode === 0: The delta values are specified in pixels
		 * e.deltaMode === 1: The delta values are specified in lines
		 * https://developer.mozilla.org/en-US/docs/Web/API/WheelEvent/deltaMode
		 */
		if (e.deltaMode === 1) {
			deltaY *= this.getLineHeightPx();
			deltaX *= this.getLineHeightPx();
		}

		deltaY *= this.props.speed;
		deltaX *= this.props.speed;

		const newState = this.composeNewState(-deltaX, -deltaY);

		if (
			(newState.topPosition && this.state.topPosition !== newState.topPosition) ||
			(newState.leftPosition && this.state.leftPosition !== newState.leftPosition) ||
			this.props.stopScrollPropagation
		) {
			e.preventDefault();
			e.stopPropagation();
		}

		this.setStateFromEvent(newState, eventTypes.wheel);
		this.focusContent();
	}

	handleKeyDown(e) {
		// only handle if scroll area is in focus
		if (e.target.tagName.toLowerCase() !== "input" && e.target.tagName.toLowerCase() !== "textarea" && !e.target.isContentEditable) {
			let deltaY = 0;
			let deltaX = 0;
			const lineHeightSize = this.getLineHeightPx();

			switch (e.keyCode) {
				case 33: // page up
					deltaY = this.state.containerHeight - lineHeightSize;
					break;
				case 34: // page down
					deltaY = -this.state.containerHeight + lineHeightSize;
					break;
				case 37: // left
					deltaX = lineHeightSize;
					break;
				case 38: // up
					deltaY = lineHeightSize;
					break;
				case 39: // right
					deltaX = -lineHeightSize;
					break;
				case 40: // down
					deltaY = -lineHeightSize;
					break;
				default:
					break;
			}

			// only compose new state if key code matches those above
			if (deltaY !== 0 || deltaX !== 0) {
				const newState = this.composeNewState(deltaX, deltaY);

				e.preventDefault();
				e.stopPropagation();

				this.setStateFromEvent(newState, eventTypes.keyPress);
			}
		}
	}

	handleWindowResize() {
		let newState = this.computeSizes();
		newState = this.getModifiedPositionsIfNeeded(newState);
		this.setStateFromEvent(newState);
	}

	render() {
		const { children, className, contentClassName, ownerDocument } = this.props;
		const withMotion =
			this.props.smoothScrolling &&
			(this.state.eventType === eventTypes.wheel ||
				this.state.eventType === eventTypes.api ||
				this.state.eventType === eventTypes.touchEnd ||
				this.state.eventType === eventTypes.keyPress);

		const scrollbarY = this.canScrollY() ? (
			<ScrollBar
				ownerDocument={ownerDocument}
				realSize={this.state.realHeight}
				containerSize={this.state.containerHeight}
				position={this.state.topPosition}
				onMove={this.handleScrollbarMove}
				onPositionChange={this.handleScrollbarYPositionChange}
				containerStyle={this.props.verticalContainerStyle}
				scrollbarStyle={this.props.verticalScrollbarStyle}
				smoothScrolling={withMotion}
				minScrollSize={this.props.minScrollSize}
				onFocus={this.focusContent}
				type="vertical"
			/>
		) : null;

		const scrollbarX = this.canScrollX() ? (
			<ScrollBar
				ownerDocument={ownerDocument}
				realSize={this.state.realWidth}
				containerSize={this.state.containerWidth}
				position={this.state.leftPosition}
				onMove={this.handleScrollbarMove}
				onPositionChange={this.handleScrollbarXPositionChange}
				containerStyle={this.props.horizontalContainerStyle}
				scrollbarStyle={this.props.horizontalScrollbarStyle}
				smoothScrolling={withMotion}
				minScrollSize={this.props.minScrollSize}
				onFocus={this.focusContent}
				type="horizontal"
			/>
		) : null;

		let newChildren;
		if (typeof children === "function") {
			warnAboutFunctionChild();
			newChildren = children();
		} else {
			warnAboutElementChild();
			newChildren = children;
		}

		const classes = `scrollarea ${className || ""}`;
		const contentClasses = `scrollarea-content ${contentClassName || ""}`;

		const contentStyle = {
			marginTop: -this.state.topPosition,
			marginLeft: -this.state.leftPosition,
		};
		const springifiedContentStyle = withMotion ? modifyObjValues(contentStyle, (x) => spring(x)) : contentStyle;

		return (
			<Motion style={springifiedContentStyle}>
				{(style) => (
					<div
						ref={(x) => {
							this.wrapper = x;
						}}
						className={classes}
						style={this.props.style}
						onWheel={this.handleWheel}
					>
						<div
							ref={this.contentRef}
							style={{ ...this.props.contentStyle, ...style }}
							className={contentClasses}
							onTouchStart={this.handleTouchStart}
							onTouchMove={this.handleTouchMove}
							onTouchEnd={this.handleTouchEnd}
							onKeyDown={this.handleKeyDown}
							role="button"
							tabIndex={this.props.focusableTabIndex}
						>
							{newChildren}
						</div>
						{scrollbarY}
						{scrollbarX}
					</div>
				)}
			</Motion>
		);
	}
}

ScrollArea.childContextTypes = {
	scrollArea: PropTypes.object,
};

ScrollArea.propTypes = {
	className: PropTypes.string,
	style: PropTypes.object,
	speed: PropTypes.number,
	contentClassName: PropTypes.string,
	contentStyle: PropTypes.object,
	vertical: PropTypes.bool,
	verticalContainerStyle: PropTypes.object,
	verticalScrollbarStyle: PropTypes.object,
	horizontal: PropTypes.bool,
	horizontalContainerStyle: PropTypes.object,
	horizontalScrollbarStyle: PropTypes.object,
	onScroll: PropTypes.func,
	contentWindow: PropTypes.any,
	ownerDocument: PropTypes.any,
	smoothScrolling: PropTypes.bool,
	minScrollSize: PropTypes.number,
	swapWheelAxes: PropTypes.bool,
	stopScrollPropagation: PropTypes.bool,
	focusableTabIndex: PropTypes.number,
};

ScrollArea.defaultProps = {
	className: null,
	style: null,
	speed: 1,
	contentClassName: null,
	contentStyle: null,
	vertical: true,
	verticalContainerStyle: null,
	verticalScrollbarStyle: null,
	horizontal: true,
	horizontalContainerStyle: null,
	horizontalScrollbarStyle: null,
	onScroll: null,
	smoothScrolling: false,
	minScrollSize: 0,
	swapWheelAxes: false,
	contentWindow: typeof window === "object" ? window : undefined,
	ownerDocument: typeof document === "object" ? document : undefined,
	stopScrollPropagation: false,
	focusableTabIndex: 1,
};

export default ScrollArea;
