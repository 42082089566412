import * as SentryBrowser from "@sentry/browser";
import baseAxios from "axios";
import * as querystring from "querystring";

import reportSentry from "libs/sentry";

import { BJ_API_DOMAIN } from "constant/config";
import { getDefaultMessage } from "constant/error";

const axios = baseAxios.create({
    baseURL: BJ_API_DOMAIN,
    timeout: 15000,
    paramsSerializer: (params) => querystring.stringify(params),
    withCredentials: true,
});

axios.interceptors.response.use(
    async (response) => {
        console.log(response);
        return response.data;
    },
    async (error) => {
        if (error.code === "ECONNABORTED") {
            reportSentry(error, { request: error.config || null }, SentryBrowser.Severity.Warning);
        }

        /*if(error.response.data.code == "-302") {
            window.location.href = DOMAIN_URL;
        }*/

        if (!error.response || !error.response.data) {
            error.response = {
                ...error.response,
                data: { code: 0, message: getDefaultMessage() },
            };
        }

        throw error.response.data;
    },
);

export default axios;
