import produce from "immer";
import {
    FETCH_SETTING_PLAYLIST_LIST,
    FETCH_SETTING_PLAYLIST_LIST_SUCCESS,
    FETCH_SETTING_PLAYLIST_LIST_FAIL,
    FETCH_SETTING_PLAYLIST_LIST_ADD_SUCCESS,
    FETCH_SETTING_PLAYLIST_LIST_ADD_FAIL,
    FETCH_SETTING_PLAYLIST_RELOAD,
    UPDATE_SETTING_PLAYLISTS,
    DELETE_SETTING_PLAYLISTS,
    DELETE_ALL_SETTING_PLAYLISTS, FETCH_SETTING_PLAYLIST_LIST_ADD_SUCCESS_MORE,
} from "actions/setting/setting.playlist.action"


const INITIAL_STATE = {
    init: true,
    playlist_error: null,
    all_playlist_error: null,
    loading: true,
    reload: false,
    name: "",
    page: 1,
    hasMore: false,
    bj_playlists : [],  //BJ가 선택한 플레이 리스트 번호
    playlists: [], // BJ가 선택한 플레이 리스트 정보
    all_playlists : [], // 전체 플레이 리스트
};


const settingPlaylistReducer = produce((state, action) => {
    switch (action.type) {
        case FETCH_SETTING_PLAYLIST_LIST:
            state.playlist_error = null;
            state.loading = true;
            state.reload = false;
            state.init = false;
            break;
        case FETCH_SETTING_PLAYLIST_LIST_SUCCESS:
            state.playlist_error = null;
            state.loading = false;
            state.reload = false;
            state.init = false;
            state.bj_playlists = action.payload.bjConfigArr;
            state.playlists = action.payload.playlists;
            break;
        case FETCH_SETTING_PLAYLIST_LIST_FAIL:
            state.playlist_error = action.payload;
            state.loading = false;
            state.init = false;
            break;
        case FETCH_SETTING_PLAYLIST_LIST_ADD_FAIL:
            state.all_playlist_error = action.payload;
            state.loading = false;
            state.init = false;
            break;

        // 재생목록 설정 페이지, 재생목록 정렬 후 저장 업데이트
        case UPDATE_SETTING_PLAYLISTS:
            state.reload = false;
            state.init = false;
            state.playlists = action.payload.playlists;
            state.bj_playlists = action.payload.sortItems;
            return;

       // 채널 설정 > 재생목록 추가 리스트
        case FETCH_SETTING_PLAYLIST_LIST_ADD_SUCCESS:
            state.loading = false;
            state.reload = false;
            state.init = false;
            state.all_playlists = action.payload.allPlaylist;
            state.bj_playlists = action.payload.bjConfigArr;

            state.hasMore = action.payload.allPlaylist.length >= 20;
            state.page = state.page + 1;

            break;

        case FETCH_SETTING_PLAYLIST_LIST_ADD_SUCCESS_MORE:
            state.loading = false;
            state.reload = false;
            state.init = false;


            if (state.page <= action.page && action.payload.allPlaylist.length > 0) {
                state.all_playlists = [ ...state.all_playlists, ...action.payload.allPlaylist];
                state.page = state.page + 1;
            }
            state.hasMore = action.payload.allPlaylist.length >= 20;

            break;

        // 채널 설정 > 재생목록 추가 업데이트
        case FETCH_SETTING_PLAYLIST_RELOAD:
            const diffArr =  action.payload.filter((store) => !state.bj_playlists.includes(store));
            const intersectArr =  action.payload.filter((store) => state.bj_playlists.includes(store));

            state.bj_playlists = [...diffArr, ...intersectArr];
            state.reload = true;

            return;

        // 재생목록 설정 페이지 삭제
        case DELETE_SETTING_PLAYLISTS:
            state.loading = false;
            state.reload = false;
            state.init = false;
            state.playlists = state.playlists.filter((playlist, idx) => {
                return  playlist.idx !== action.payload
            });
            state.bj_playlists = state.bj_playlists.filter((data) => data !== action.payload)
            return;

        // 재생목록 설정 > 재생목록 전체 선택 취소 (전체 삭제)
        case DELETE_ALL_SETTING_PLAYLISTS:
            state.loading = false;
            state.reload = false;
            state.init = false;
            state.bj_playlists = [];
            state.playlists = [];
            return ;

        default:
            return;
    }
}, INITIAL_STATE);

export default settingPlaylistReducer;
