import { isMobile } from "react-device-detect";

import moment from "moment";
import i18next from "i18next";

export const ALL_MONTHS = "";
export const THREE_MONTHS = "3M";
export const SIX_MONTHS = "6M";
export const ONE_YEAR = "1Y";

export const getMonthOptions = () => [
	{ name: i18next.t(isMobile ? "전체" : "전체 기간"), value: ALL_MONTHS },
	{ name: i18next.t("3개월"), value: THREE_MONTHS },
	{ name: i18next.t("6개월"), value: SIX_MONTHS },
	{ name: i18next.t("1년"), value: ONE_YEAR },
];

export const getMonths = (months) => {
	if (typeof months !== "string") {
		return ALL_MONTHS;
	}

	const startMonth = moment(months.substr(0, 6), "YYYYMM");
	const endMonth = moment(months.substr(6, 6), "YYYYMM");

	if ([ALL_MONTHS, THREE_MONTHS, SIX_MONTHS, ONE_YEAR].includes(months) || (startMonth.isValid() && endMonth.isValid())) {
		return months;
	}

	return ALL_MONTHS;
};
