import { useState, useEffect, useCallback } from "react";
// Hook
export default function useWindowScrollY() {
	const isClient = typeof window === "object";

	const getScroll = useCallback(() => {
		if (isClient) {
			// IE 때문에 예외 처리
			return window.scrollY ? window.scrollY : window.document.documentElement.scrollTop;
		}

		return 0;
	}, [isClient]);

	const [scrollTop, setScrollTop] = useState(getScroll);

	useEffect(() => {
		if (!isClient) {
			return false;
		}

		function handleScroll() {
			setScrollTop(getScroll());
		}

		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, [getScroll, isClient]); // Empty array ensures that effect is only run on mount and unmount

	return [scrollTop];
}
