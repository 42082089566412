import qs from "qs";

import axios from "libs/axios";

import { STBBS_DOMAIN } from "constant/config";

import { toastr } from "components/toastr";

export const FETCH_PLAYLIST_LAYER = "FETCH_PLAYLIST_LAYER";
export const FETCH_PLAYLIST_LAYER_MORE = "FETCH_PLAYLIST_LAYER_MORE";
export const FETCH_PLAYLIST_LAYER_SUCCESS = "FETCH_PLAYLIST_LAYER_SUCCESS";
export const FETCH_PLAYLIST_LAYER_MORE_SUCCESS = "FETCH_PLAYLIST_MORE_SUCCESS";
export const FETCH_PLAYLIST_LAYER_ERROR = "FETCH_PLAYLIST_ERROR";

export const CLEAR_PLAYLIST_LAYER = "CLEAR_PLAYLIST_LAYER";

export const CHECK_PLAYLIST_LAYER = "CHECK_PLAYLIST_LAYER";

export const UPDATE_PLAYLIST_LAYER_SUCCESS = "UPDATE_PLAYLIST_LAYER_SUCCESS";
export const UPDATE_PLAYLIST_LAYER_ERROR = "UPDATE_PLAYLIST_LAYER_ERROR";

// 재생목록 레이어 리스트 조회
export const fetchPlaylistLayer = (page, titleNo) => async (dispatch) => {
	// 재생목록 조회 시작
	dispatch({ type: page === 1 ? FETCH_PLAYLIST_LAYER : FETCH_PLAYLIST_LAYER_MORE });

	const data = {
		szWork: "get_making_playlist",
		nPage: page,
		nLimit: 20,
		title_no: titleNo,
		bj_id: global.bj_id,
		sorttype: "recent_date",
	};

	try {
		const { result = -1, playlists = [], has_more: hasMore = false, msg = "" } = await axios.post(
			`${STBBS_DOMAIN}/vodplaylist/api/api.php`,
			qs.stringify(data),
		);

		// api 오류 발생
		if (result !== 1) {
			toastr.warning(msg, {
				timeOut: 2000,
			});

			dispatch({ type: FETCH_PLAYLIST_LAYER_ERROR });
			return;
		}

		// 재생목록 조회 성공
		dispatch({
			type: page === 1 ? FETCH_PLAYLIST_LAYER_SUCCESS : FETCH_PLAYLIST_LAYER_MORE_SUCCESS,
			payload: { playlists, hasMore },
		});
	} catch (error) {
		// 알 수 없는 오류 발생
		toastr.error(error.message, {
			timeOut: 2000,
		});
		dispatch({ type: FETCH_PLAYLIST_LAYER_ERROR });
	}
};

// 재생목록 레이어의 리스트 초기화
export const clearPlaylistLayer = () => (dispatch) => {
	dispatch({ type: CLEAR_PLAYLIST_LAYER });
};

// 재생목록 레이어에 체크
export const checkPlaylistLayer = (idx) => (dispatch) => {
	dispatch({ type: CHECK_PLAYLIST_LAYER, payload: idx });
};

// 재생목록 레이어에 담은 영상 반영
export const updatePlaylistLayer = (titleNo, idxList) => async (dispatch) => {
	const data = {
		szWork: "ins_playlist_info",
		title_no: titleNo,
		nPlaylistIdx: idxList,
		bj_id: global.bj_id,
	};

	try {
		const { result = -1, msg = "" } = await axios.post(`${STBBS_DOMAIN}/vodplaylist/api/api.php`, qs.stringify(data));

		// api 오류 발생
		if (result !== 1) {
			toastr.warning(msg, {
				timeOut: 2000,
			});

			dispatch({ type: UPDATE_PLAYLIST_LAYER_ERROR });
			return;
		}

		// 재생목록에 영상 반영 완료
		toastr.success(msg, {
			timeOut: 2000,
		});

		dispatch({ type: UPDATE_PLAYLIST_LAYER_SUCCESS });
	} catch (error) {
		// 알 수 없는 오류 발생
		toastr.error(error.message, {
			timeOut: 2000,
		});
		dispatch({ type: UPDATE_PLAYLIST_LAYER_ERROR });
	}
};

// 재생목록 추가
export const storePlaylistLayer = async (title, display, share) => {
	const data = {
		szWork: "ins_playlist_list",
		title,
		share_yn: share,
		display: display,
		bj_id: global.bj_id,
	};

	try {
		const { result = -1, msg = "" } = await axios.post(`${STBBS_DOMAIN}/vodplaylist/api/api.php`, qs.stringify(data));

		// api 오류 발생
		if (result !== 1) {
			throw new Error(msg);
		}

		// 재생목록 추가 완료
		toastr.success(msg, {
			timeOut: 2000,
		});
	} catch (error) {
		// 알 수 없는 오류 발생
		toastr.error(error.message, {
			timeOut: 2000,
		});

		throw error;
	}
};
