import { isWebview } from "helpers";
import { DOMAIN_URL, APP_SCHEME } from "constant/config";

/*
 * BJ 숨기기 추가 및 AOS func call
 */
export const fetchHidden = async (targetId = '', targetNick = '', isWebViewClose = true) => {
    try {
        // AOS 웹뷰 function call -> 안드 로컬에서 스낵바 노출을 위함
        if(window.HiddenBridge){    // window 안 HiddenBridge 객체 있는지 check
            window.HiddenBridge.hiddenBj(targetId, targetNick);
        }
    } catch (error) {
        
    } finally {
        if (isWebViewClose) {
            window.location.href = isWebview ? `${APP_SCHEME}browser/close` : DOMAIN_URL;
        }
    }
}
