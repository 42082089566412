import axios from "libs/axios";
import {TITLE_CONTENTS} from "../constant/search";
export const FETCH_SHOPFREECA = 'FETCH_SHOPFREECA';
export const FETCH_SHOPFREECA_LIST_SUCCESS = 'FETCH_SHOPFREECA_LIST_SUCCESS';
export const FETCH_SHOPFREECA_LIST_FAILURE = 'FETCH_SHOPFREECA_LIST_FAILURE';

export const fetchShopfreecaList = (locationKey =  null, {
                                  page = 1,
                                  perPage = 20,
                                  orderBy = 'reg_date',

                              }, append = false) => async (dispatch) => {

    // 로딩 시작
    dispatch({
        type: FETCH_SHOPFREECA,
        payload: { key: locationKey, append },
    });

    // 숲토어 리스트 조회 url
    let url = `/${global.bj_id}/shopfreeca/curation`;

    // 숲토어 리스트 조회 parameter
    const params = {
        page,
        per_page: perPage,
        orderBy: orderBy,
    };

    try {
        // api 통신 시작
        const response = await axios.get(url, { params });

        dispatch({
            type: FETCH_SHOPFREECA_LIST_SUCCESS,
            payload: response,
        });
    } catch (error) {
        dispatch({
            type: FETCH_SHOPFREECA_LIST_FAILURE,
            payload: error,
        });
    }
};

// order by 할 경우 curtation data만 append 쳐야할지
// 큐레이션 api 가 따로 분리가 안되어있어서 이건 나중에..