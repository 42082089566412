import i18next from "i18next";
import qs from "qs";

import axios from "libs/axios";

import { FORM_CONTENT_TYPE } from "actions/config";

import { EDIT, POST } from "constant/method";

import { toastr } from "components/toastr";
import { getCommentAuthNo } from "constant/auth";

export const FETCH_CREATE_FORM = "FETCH_CREATE_FORM";
export const FETCH_CREATE_FORM_SUCCESS = "FETCH_CREATE_FORM_SUCCESS";
export const FETCH_CREATE_POST_ERROR = "FETCH_CREATE_POST_ERROR";

export const CLEAR_CREATE_FORM = "CLEAR_CREATE_FORM";

export const FETCH_TEMPORARY = "FETCH_TEMPORARY";
export const FETCH_TEMPORARY_SUCCESS = "FETCH_TEMPORARY_SUCCESS";

export const SET_DISABLE_SAVE_BTN = "SET_DISABLE_SAVE_BTN";
export const RESET_DISABLE_SAVE_BTN = "RESET_DISABLE_SAVE_BTN";

/**
 * 게시글 글쓰기 폼
 * @param method
 * @param bbsNo
 * @param titleNo
 */
export const fetchCreateForm = (method, bbsNo = "", titleNo = 0) => async (dispatch) => {
    dispatch({ type: FETCH_CREATE_FORM });

    const url =
        method === EDIT
            ? `/${global.bj_id}/title/${titleNo}/edit?${Math.random()}`
            : `/${global.bj_id}/title/create?bbs_no=${bbsNo}&${Math.random()}`;

    try {
        const response = await axios.get(url);

        dispatch({
            type: FETCH_CREATE_FORM_SUCCESS,
            payload: response,
        });
    } catch (error) {
        dispatch({
            type: FETCH_CREATE_POST_ERROR,
            payload: error ? error : { code: 0, message: error.message },
        });
    }
};

/**
 * 임시 저장 글 불러 오기
 * @returns {function(...[*]=)}
 */
export const fetchTemporary = () => async (dispatch) => {
    dispatch({ type: FETCH_TEMPORARY });

    try {
        const response = await axios.get(`/${global.bj_id}/temporary`);

        dispatch({ type: FETCH_TEMPORARY_SUCCESS, payload: response });

        toastr.success(i18next.t("{{reg_date}}에 임시 저장된 글을 불러 왔습니다.", { reg_date: response.reg_date }), {
            timeOut: 2000,
        });
    } catch (error) {
        toastr.error(error.message, {
            timeOut: 2000,
        });
    }
};

/**
 * 작성 중인 글 임시 저장
 * @param values
 * @param photos
 * @returns {function(...[*]=)}
 */
export const storeTemporary = (values, photos) => async (dispatch) => {
    if (!values.title_name || values.title_name.trim() === "") {
        toastr.error(i18next.t("게시글의 제목을 입력해주세요."));
        return;
    }

    if (!values.content || values.content.trim() === "") {
        toastr.error(i18next.t("게시글의 내용을 입력해주세요.."));
        return;
    }

    // 댓글 권한 데이터
    const commentAuthNo = getCommentAuthNo(values.comment_yn, values.comment_auth);

    const data = {
        ...values,
        _method: POST,
        photos: JSON.stringify(photos),
        votes: JSON.stringify(values.votes),
        comment_auth: commentAuthNo,
    };

    try {
        const response = await axios.post(`/${global.bj_id}/temporary`, qs.stringify(data), {
            headers: FORM_CONTENT_TYPE,
        });

        toastr.success(response.message, {
            timeOut: 2000,
        });
    } catch (error) {
        toastr.error(error.message, {
            timeOut: 2000,
        });
    }
};

/**
 * 작성한 게시글 저장 API
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const storePostApi = (data) =>
    axios.post(`/${global.bj_id}/title`, qs.stringify({ ...data }), {
        headers: FORM_CONTENT_TYPE,
    });

/**
 * 게시글 수정 API
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updatePostApi = (data) => {
    const { title_no: titleNo } = data;

    return axios.post(`/${global.bj_id}/title/${titleNo}`, qs.stringify({ ...data }), {
        headers: FORM_CONTENT_TYPE,
    });
};
