import React from "react"; //  eslint-disable-line no-unused-vars
import PropTypes from "prop-types";
import classnames from "classnames";

export default class ToastrBox extends React.Component {
	static displayName = "ToastrBox";

	static propTypes = {
		item: PropTypes.shape({
			options: PropTypes.shape({}),
		}),
	};

	constructor(props) {
		super(props);

		this.isHiding = false;
		this.shouldClose = false;
		this.intervalId = null;
		this.ignoreIsHiding = false;

		// an identifier to facilitate aria labelling for a11y for multiple instances of the component family in the DOM
		this.id = Math.floor(Math.random() * 9999);
	}

	componentDidMount() {
		const { item } = this.props;
		if (this.props.inMemory[item.id]) return;

		const timeOut = this._getItemTimeOut();

		if (timeOut) {
			this._setIntervalId(setTimeout(this._removeToastr, timeOut));
		}

		this.props.addToMemory(item.id);

		// 다른 영역에서 포커스 아웃 발생하여 기능 제거
		// if (this.closeButton !== undefined) {
		// 	this.closeButton.focus();
		// }
	}

	componentWillUnmount() {
		if (this.intervalId) {
			clearTimeout(this.intervalId);
		}
	}

	handlePressEnterOrSpaceKeyToastr = (e) => {
		if (e.key === " " || e.key === "enter") {
			this.handleClickToastr(e);
		}
	};

	handlePressEnterOrSpaceKeyCloseButton(e) {
		if (e.key === " " || e.key === "enter") {
			this.handleClickCloseButton(e);
		}
	}

	handleClickToastr = () => {
		let { onToastrClick, closeOnToastrClick } = this.props.item.options;
		this.ignoreIsHiding = true;

		if (onToastrClick) {
			onToastrClick();
		}

		if (closeOnToastrClick) {
			this._setShouldClose(true);
			this._removeToastr();
		}
	};

	handleClickCloseButton = (e) => {
		let { onCloseButtonClick } = this.props.item.options;
		e.stopPropagation();

		this.ignoreIsHiding = true;

		if (onCloseButtonClick) {
			onCloseButtonClick();
		}

		this._setShouldClose(true);
		this._removeToastr();
	};

	mouseEnter = () => {
		clearTimeout(this.intervalId);
		this._setIntervalId(null);
		this._setIsHiding(false);
	};

	mouseLeave = () => {
		const { removeOnHover, removeOnHoverTimeOut } = this.props.item.options;

		if (!this.isHiding && (removeOnHover || this.shouldClose)) {
			const interval = removeOnHover === true ? removeOnHoverTimeOut || 1000 : removeOnHover;
			this._setIntervalId(setTimeout(this._removeToastr, interval));
		}
	};

	renderCloseButton = () => {
		return (
			<button type="button" className="close" onClick={this.handleClickCloseButton} ref={(ref) => (this.closeButton = ref)}>
				<span>닫기</span>
			</button>
		);
	};

	_getItemTimeOut() {
		const { item } = this.props;
		let { timeOut } = item.options;
		if (typeof timeOut === "undefined") {
			timeOut = this.props.timeOut;
		}

		return timeOut;
	}

	_onComplete = () => {
		const { remove, item } = this.props;
		const { options, id } = item;

		if (this.isHiding || this.ignoreIsHiding) {
			this._setIsHiding(false);
			this.ignoreIsHiding = false;
			remove(id);
			if (options.onHideComplete) {
				options.onHideComplete();
			}
		} else if (!this.isHiding && options.onShowComplete) {
			options.onShowComplete();
		}
	};

	_removeToastr = () => {
		if (!this.isHiding) {
			this._setIsHiding(true);
		}
		this._onComplete();
	};

	_setIntervalId = (intervalId) => {
		this.intervalId = intervalId;
	};

	_setIsHiding = (val) => {
		this.isHiding = val;
	};

	_setShouldClose = (val) => {
		this.shouldClose = val;
	};

	render() {
		const { options, type, message } = this.props.item;

		const { onToastrClick, closeOnToastrClick } = options;
		const hasOnToastrClick = !!onToastrClick;
		const doesCloseOnToastrClick = closeOnToastrClick;

		let toastrClickAttributes = {};
		if (hasOnToastrClick || doesCloseOnToastrClick) {
			toastrClickAttributes.role = "button";
			toastrClickAttributes.tabIndex = 0;
			toastrClickAttributes.onClick = this.handleClickToastr;
			toastrClickAttributes.onKeyPress = this.handlePressEnterOrSpaceKeyToastr;
		}
		return (
			<div
				ref={(ref) => (this.toastrBoxElement = ref)}
				className={classnames(type, options.className)}
				onMouseEnter={this.mouseEnter}
				onMouseLeave={this.mouseLeave}
				{...toastrClickAttributes}
			>

				{options.textCenter ? (
					<p key={0} style={ options.textCenter ? {textAlign : "center"} : ""} >{message}</p>
				) : (
					<p key={0}>{message}</p>
				)}

				{options.showCloseButton && this.renderCloseButton()}
			</div>
		);
	}
}
