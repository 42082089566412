import { Map, List, fromJS } from "immutable";
import {
	FETCH_GUESTS,
	FETCH_GUESTS_SUCCESS,
	FETCH_GUESTS_FAILURE,
	STORE_GUEST_SUCCESS,
	DELETE_GUEST_SUCCESS,
	UPDATE_GUEST_SUCCESS,
	FETCH_GUESTBOOK_MEMO,
	FETCH_GUESTBOOK_MEMO_SUCCESS,
	FETCH_GUESTBOOK_MEMO_FAILURE,
	STORE_GUESTBOOK_MEMO_SUCCESS,
	DELETE_GUESTBOOK_MEMO_SUCCESS,
	UPDATE_GUESTBOOK_MEMO_CANCEL,
	UPDATE_GUESTBOOK_MEMO_SUCCESS,
	GUESTBOOK_FORM_RESET,
	FETCH_GUESTS_MORE_SUCCESS,
} from "../actions/guest.action";

const INITIAL_STATE = Map({
	error: null,
	loading: true,
	guests: Map({
		data: List([]),
	}),
	reset: 1,
});

const findGuestAndUpdateMemo = (state, title_no, callback) => {
	const data = List(state.getIn(["guests", "data"]));
	return state.setIn(
		["guests", "data"],
		data.update(
			data.findIndex((guest) => {
				return Map(guest).get("title_no") === parseInt(title_no, 10);
			}),
			(guest) => callback.call(this, guest),
		),
	);
};

export default function guestReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		//게시글 리스트
		case FETCH_GUESTS:
			return state.set("loading", true);

		case FETCH_GUESTS_SUCCESS:
			return state.set("loading", false).set("guests", Map(fromJS(action.payload)));

		case FETCH_GUESTS_MORE_SUCCESS:
			const newGuests = Map(fromJS(action.payload));
			const oldData = state.getIn(["guests", "data"]);
			const concatData = oldData.concat(newGuests.get("data"));
			return state.update("guests", () => newGuests.update("data", () => concatData));

		case FETCH_GUESTS_FAILURE:
			return state.set("loading", false).set("error", action.payload);

		case STORE_GUEST_SUCCESS: {
			//새로 작업한 댓글을 맨위에 추가함
			return state.updateIn(["guests", "data"], (data) => {
				return data.unshift(Map(action.payload));
			});
		}
		case DELETE_GUEST_SUCCESS: {
			//삭제된 댓글을 댓글리스트에서 제외
			const { title_no } = action.payload;
			const data = state.getIn(["guests", "data"]).filterNot((guest) => Map(guest).get("title_no") === parseInt(title_no, 10));
			return state.setIn(["guests", "data"], data);
		}
		case UPDATE_GUEST_SUCCESS: {
			const { title_no } = action;
			return state.updateIn(["guests", "data"], (data) => {
				return data.update(
					data.findIndex((guest) => {
						return guest.get("title_no") === title_no;
					}),
					(guest) => {
						const memos = guest.get("memos") ? guest.get("memos") : {};
						return Map(fromJS({ ...action.payload, memos }));
					},
				);
			});
		}

		//답글 리스트
		case FETCH_GUESTBOOK_MEMO: {
			const { title_no } = action;
			return findGuestAndUpdateMemo(state, title_no, (guest) => {
				return guest.setIn(["memos", "loading"], true);
			});
		}

		case FETCH_GUESTBOOK_MEMO_SUCCESS: {
			//댓글을 찾아서 밑에다 붙임
			const { title_no, payload } = action;
			return findGuestAndUpdateMemo(state, title_no, (guest) => {
				return guest.setIn(["memos", "loading"], false).setIn(["memos", "data"], List(fromJS(payload)));
			});
		}
		case FETCH_GUESTBOOK_MEMO_FAILURE: {
			const { title_no, payload } = action;
			return findGuestAndUpdateMemo(state, title_no, (guest) => {
				return guest.setIn(["memos", "loading"], false).setIn(["memos", "error"], List(fromJS(payload)));
			});
		}

		case STORE_GUESTBOOK_MEMO_SUCCESS: {
			//새로 작업한 댓글을 맨위에 추가함
			const { title_no, payload } = action;
			return findGuestAndUpdateMemo(state, title_no, (guest) => {
				return guest.updateIn(["memos", "data"], (data) => {
					return data.push(Map(payload));
				});
			});
		}
		case DELETE_GUESTBOOK_MEMO_SUCCESS: {
			//삭제된 댓글을 댓글리스트에서 제외
			const { title_no, memo_no } = action;
			return findGuestAndUpdateMemo(state, title_no, (guest) => {
				return guest.updateIn(["memos", "data"], (data) => {
					return data.filterNot((reply) => {
						return reply.get("memo_no") === parseInt(memo_no, 10);
					});
				});
			});
		}

		case UPDATE_GUESTBOOK_MEMO_CANCEL: {
			const { title_no, memo_no } = action;
			return findGuestAndUpdateMemo(state, title_no, (guest) => {
				return guest.updateIn(["memos", "data"], (data) => {
					return data.update(
						data.findIndex((reply) => {
							return Map(reply).get("memo_no") === memo_no;
						}),
						(reply) => reply.set("is_update", false),
					);
				});
			});
		}
		case UPDATE_GUESTBOOK_MEMO_SUCCESS: {
			const { title_no, memo_no, payload } = action;
			return findGuestAndUpdateMemo(state, title_no, (guest) => {
				return guest.updateIn(["memos", "data"], (data) => {
					return data.update(
						data.findIndex((reply) => {
							return Map(reply).get("memo_no") === memo_no;
						}),
						(reply) => Map(payload),
					);
				});
			});
		}
		case GUESTBOOK_FORM_RESET:
			return state.update("reset", (reset) => reset + 1);
		default:
			return state;
	}
}
