import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import { updateProfileText } from "actions/station.action";
import { Trans, useTranslation } from "react-i18next";
import { JAVASCRIPT_VOID } from "constant/config";

const ProfileMessage = (props) => {	

	const { t } = useTranslation();
	const dispatch = useDispatch();
	
	const _lineRemove = (text) => {
		const newLocal = "\r?\n";
		return text.replace(new RegExp(newLocal, "g"), "");
	}
	const [ text, setText ] = useState( _lineRemove(props.profile_text) );
	const [ size, setSize ] = useState(`${text.length} / 150`);

	const handleTextChange = (e) => {
		var text = e.target.value.substring(0, 150);
		const size = text.length;
		setText(_lineRemove(text));
		setSize(`${size} / 150`);
	};

	const handleSumit = () => {
		if (text.trim() === "") {
			alert(t("프로필 메시지를 입력해주세요."));
			return false;
		}
		dispatch(updateProfileText(text));
		props.onClose();
	};

	const handleEnterLess = (e) => {
		const { keyCode } = e;
		if (keyCode === 13) {
			e.stopPropagation();
			e.preventDefault();
			return false;
		}
	};

	if (isMobile) {
		return (
			<div className="ui-pop-m bs-layer">
				<div className="pop-title">
					<Trans>프로필 수정</Trans>
				</div>
				<div className="pop-body pop-msg">
					<div>
						<textarea
							cols="30"
							rows="10"
							placeholder={t("방문 유저에게 전하고 싶은 이야기를 작성해 보세요.")}
							onKeyUp={handleEnterLess}
							onKeyDown={handleEnterLess}
							defaultValue={text}
							value={text}
							onChange={handleTextChange}
						/>
						<div className="bytes">
							<input type="text" name="" id="" readOnly={true} value={size} />
						</div>
					</div>
				</div>
				<div className="pop-btn">
					<a href={JAVASCRIPT_VOID} className="btn" onClick={props.onClose}>
						<Trans>취소</Trans>
					</a>
					<a href={JAVASCRIPT_VOID} className="btn" onClick={handleSumit}>
						<strong>
							<Trans>수정</Trans>
						</strong>
					</a>
				</div>
			</div>
		);
	} else {
		return (
			<div className="ui-pop profile-modyify_layer">
				<div className="pop-title">
					<h3>
						<Trans>프로필 메시지 수정</Trans>
					</h3>
				</div>
				<div className="pop-body">
					<div className="textarea">
						<textarea
							placeholder={t("방문 유저에게 전하고 싶은 이야기를 작성해 보세요.")}
							onKeyUp={handleEnterLess}
							onKeyDown={handleEnterLess}
							defaultValue={text}
							value={text}
							onChange={handleTextChange}
						/>
						<input type="text" name="" id="" className="byte" readOnly={true} value={size} />
					</div>
					<div className="btn-grid">
						<div className="grid-end">
							<button type="button" className="btn-basic blue" onClick={handleSumit}>
								<span>
									<Trans>수정</Trans>
								</span>
							</button>
							<button type="button" className="btn-basic " onClick={props.onClose}>
								<span>
									<Trans>취소</Trans>
								</span>
							</button>
						</div>
					</div>
				</div>
				<a href={JAVASCRIPT_VOID} className="pop-close" onClick={props.onClose}>
					<span>
						<Trans>닫기</Trans>
					</span>
				</a>
			</div>
		);
	}
}

export default ProfileMessage;