import React from "react";
import { withTranslation, Trans } from "react-i18next";
import ReactPopup from "components/popup/ReactPopup";
import { MedalMore } from "components/contents/MedalMore";
import ModalWindow from "components/ModalWindow";
import { isMobile } from "react-device-detect";
import { getSimpleMedalImg, getBasicMedalImg } from "constant/medal.js";
export const Medal = withTranslation()((props) => {
	const { medals } = props;
	const topMedal = medals.slice(0, 4);
	const topMedalMobile = medals.slice(0, 3);
    const lang = document.documentElement.lang;
	if (isMobile) {
		return (
			<div className="medal_area_v2">
				{medals.length > 0 && (
					<ModalWindow
						classNames={{modal: "bs-wrap "}}
						trigger={
							<button type="button" className="btn_more">
								<span>
									<Trans>더보기</Trans>
								</span>
							</button>
						}
					>
						{(close) => <MedalMore medals={medals} lang = {lang} onClose={close} />}
					</ModalWindow>
				)}
				<ul className="medal_list simple">
					{topMedalMobile.map((medal, index) => (
						<li key={index} >
							<img src= {getSimpleMedalImg(lang,medal.id)} 
							 alt={medal.name}></img>
						</li>
					))}
				</ul>
			</div>
		);
	} else {
		return (
			<div className="medal_area_v2">
				<ul className="medal_list basic">
					{topMedal.map((medal, index) => (
						<li key={index}>
							<img src={getBasicMedalImg(lang,medal.id)} 
							 alt={medal.name}></img>
					   </li>
					))}
				</ul>

				{medals.length > 4 && (
					<ReactPopup
						offsetY={10}
						trigger={<button type="button" className="btn_more"> <Trans>더보기</Trans> </button>}
					>
						{(close) => <MedalMore medals={medals} lang={lang} key={medals} close={close} />}
					</ReactPopup>
				)}
			</div>
		);
	}
});
