import axios from "libs/axios";
import qs from "qs";

import { OLD_LIVE_DOMAIN } from "constant/config";
import { getDefaultMessage } from "constant/error";

export const FETCH_RECENT_EMOTICON_LIST = "FETCH_RECENT_EMOTICON_LIST";
export const FETCH_RECENT_EMOTICON_LIST_FAILURE = "FETCH_RECENT_EMOTICON_LIST_FAILURE";
export const FETCH_RECENT_EMOTICON_LIST_SUCCESS = "FETCH_RECENT_EMOTICON_LIST_SUCCESS";
export const FETCH_RECENT_EMOTICON_TAB_CHANGE = "FETCH_RECENT_EMOTICON_TAB_CHANGE";

// 최근 사용한 이모티콘 리스트 조회
export const fetchRecentEmoticonList = () => async (dispatch) => {

    dispatch({ type: FETCH_RECENT_EMOTICON_LIST });

    //수정 필요 로그인 체크
    try {
        const response = await axios.get(`${OLD_LIVE_DOMAIN}/api/recent_used_emoticon.php`);

        if (response.result === -1) {
            throw new Error();
        }

        let { DATA = [], domain } = response;

        if (!domain) domain = "//ogq-sticker-global-cdn-z01.afreecatv.com/";
        

        dispatch({ type: FETCH_RECENT_EMOTICON_LIST_SUCCESS, payload: { DATA, domain } });

    } catch (error) {
        dispatch({ type: FETCH_RECENT_EMOTICON_LIST_FAILURE, payload: getDefaultMessage() });
    }
}

//최근 사용한 이모티콘 업데이트
export const updateRecentEmoticon = (param) => async (dispatch) => {

    try {
        const response = await axios.post(`${OLD_LIVE_DOMAIN}/api/recent_used_emoticon.php`,
            qs.stringify( param ));

        if (response.result === -1) {
            throw new Error();
        }

        let { DATA = [], domain } = response;

        if (!domain) domain = "//ogq-sticker-global-cdn-z01.afreecatv.com/";
        

        dispatch({ type: FETCH_RECENT_EMOTICON_LIST_SUCCESS, payload: { DATA, domain } });

    } catch (error) {
        dispatch({ type: FETCH_RECENT_EMOTICON_LIST_FAILURE, payload: getDefaultMessage() });
    }

}