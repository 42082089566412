export const EmoticonTheme = {
    DEFAULT: "default",
    EDIT: "edit",
};

export const EmoticonType = {
    SUBSCRIBE: "subscribe",
    OGQ: "ogq",
    DEFAULT: "default",
    CLOCK:"clock",
    OLD : "old"
};

export const RecentEmoticonType = {
    RECENT_OGQ: "recent_ogq",
    RECENT_DEFAULT: "recent_default",
}
