import { DESTORY_PIN_SUCCESS, STORE_PIN_SUCCESS } from "actions/home.action";
import { CANCEL_NOTICE_SUCCESS, DELETE_POST_LIKE_SUCCESS, HIDE_POST_SUCCESS,
    DELETE_POST_SUCCESS, STORE_POST_LIKE_SUCCESS } from "actions/post.action";
import { DELETE_POSTS_SUCCESS, FETCH_POSTS, FETCH_POSTS_ERROR, FETCH_POSTS_SUCCESS } from "actions/posts.action";
import { NORMAL, PHOTO, UCC } from "constant/board";
import { CLEAR, ERROR, LOADING, LOADING_MORE, SUCCESS } from "constant/status";
import produce from "immer";
import moment from "moment";
import { isMobile } from "react-device-detect";

const INITIAL_STATE = {
    status: CLEAR,
    error: null,
    key: null,
    append: false,
    pagination: {
        data: [],
        noticeData: [],
        links: {},
        meta: {},
    },
};

const postsReducer = produce((state, { type, payload }) => {
    switch (type) {
        // 게시글 리스트 로딩 시작
        case FETCH_POSTS:
            state.status = !payload.append ? LOADING : LOADING_MORE;
            state.key = payload.key;
            state.append = payload.append;
            return;

        // 게시글 리스트 조회 성공
        case FETCH_POSTS_SUCCESS:
            state.status = SUCCESS;

            // 더보기 시 뒤에 붙여지는 경우(infinity scroll)
            const pagination = payload;
            if (state.append) {
                pagination.data = [...state.pagination.data, ...payload.data];
            }
            state.pagination = {
                data: pagination.data,
                noticeData: pagination.notice_data,
                links: pagination.links,
                meta: pagination.meta,
            };
            return;

        // 게시글 리스트 조회 실패
        case FETCH_POSTS_ERROR:
            state.status = ERROR;
            state.error = payload;
            return;
        
        // 게시글 숨기기 성공
        case HIDE_POST_SUCCESS:
            //해당 게시글 제거
            state.pagination.data = state.pagination.data.filter((post) => post.title_no !== payload.title_no);

            //공지 에서도 제거
            state.pagination.noticeData = state.pagination.noticeData.filter((post) => post.title_no !== payload.title_no);
            return;

        // 게시글 삭제 성공
        case DELETE_POST_SUCCESS:
            if (state.status !== SUCCESS) {
                return;
            }

            // PC 에서는 게시글 리로드
            if (!isMobile) {
                state.status = CLEAR;
                return;
            }

            // 모바일에서는 해당 게시글 제거
            state.pagination.data = state.pagination.data.filter((post) => post.title_no !== payload.title_no);
            return;

        // 게시글 다중 삭제 성공
        case DELETE_POSTS_SUCCESS:
            if (state.status !== SUCCESS) {
                return;
            }

            // PC 에서는 게시글 리로드
            if (!isMobile) {
                state.status = CLEAR;
                return;
            }

            // 모바일에서는 해당 게시글 제거
            const { title_no: titleNoList = [] } = payload;
            state.pagination.data = state.pagination.data.filter((post) => !titleNoList.includes(post.title_no));
            return;

        // 게시글 공지 해제
        case CANCEL_NOTICE_SUCCESS:
            if (state.status !== SUCCESS) {
                return;
            }

            state.pagination.noticeData = state.pagination.noticeData.filter((post) => post.title_no !== payload.title_no);
            state.pagination.data = state.pagination.data.map((post) => {
                if (post.title_no === payload.title_no) {
                    post.authority.is_notice_cancelable = false;
                }
                return post;
            });
            return;

        // 게시글 좋아요 등록 성공
        case STORE_POST_LIKE_SUCCESS: {
            if (state.status !== SUCCESS) {
                return;
            }

            const { title_no: titleNo, user_id, copyright = {} } = payload;
            const { user_id: copyright_id, user_nick: copyright_nick } = copyright || {};

            const setLike = (post) => {
                if (post.title_no === parseInt(titleNo)) {
                    post.like = {
                        reg_date: moment().format("YYYY-MM-DD HH:mm:ss"),
                    };
                    if (global.bj_id === user_id) {
                        post.bjlike = {
                            reg_date: moment().format("YYYY-MM-DD HH:mm:ss"),
                        };
                    }
                    if (user_id === copyright_id) {
						post.copyright.like = {
                            reg_date: moment().format("YYYY-MM-DD HH:mm:ss")
                        };
                        
                        post.copyright.like_id = copyright_id;
						post.copyright.like_nick = copyright_nick;
					}
                }

                return post;
            };

            state.pagination.data = state.pagination.data.map(setLike);
            state.pagination.noticeData = state.pagination.noticeData.map(setLike);
            return;
        }

        // 게시글 좋아요 해제 성공
        case DELETE_POST_LIKE_SUCCESS: {
            if (state.status !== SUCCESS) {
                return;
            }

            const { title_no: titleNo, user_id, copyright} = payload;
            const { user_id: copyright_id } = copyright || {};

            const removeLike = (post) => {
                if (post.title_no === parseInt(titleNo)) {
                    post.like = null;

                    if (user_id === copyright_id) {
                        post.copyright.like = null;
                    }

                    if (global.bj_id === user_id) {
                        post.bjlike = null;
                    }
                }
                return post;
            };

            state.pagination.data = state.pagination.data.map(removeLike);
            state.pagination.noticeData = state.pagination.noticeData.map(removeLike);
            return;
        }

        // 핀 고정
        case STORE_PIN_SUCCESS:
            if (state.status !== SUCCESS) {
                return;
            }

            const storePin = (post) => {
                // 일반 게시글, VOD 게시글 각각 한개씩만 핀 고정 가능
                const boardType = [NORMAL, PHOTO].includes(post.board_type) ? NORMAL : UCC;
                if (payload.board_type !== boardType) {
                    return post;
                }

                const { authority = {} } = post;
                const pinable = authority.is_pinable, // 핀 고정 권한을 갖고 있는지 여부
                    pinFixed = post.title_no === payload.title_no; // 현재 고정 시킨 게시글

                // 고정 시킨 게시글은 핀 추가, 동일한 게시글 타입의 다른 게시글은 핀 제거
                post.pin = pinFixed ? { reg_date: moment().format("YYYY-MM-DD HH:mm:ss"), from_reducer: true } : null;
                post.authority.is_pin_fixable = pinable && !pinFixed;
                post.authority.is_pin_cancelable = pinable && pinFixed;
                return post;
            };

            state.pagination.data = state.pagination.data.map(storePin);
            state.pagination.noticeData = state.pagination.noticeData.map(storePin);
            return;

        // 핀 고정 해제
        case DESTORY_PIN_SUCCESS:
            if (state.status !== SUCCESS) {
                return;
            }

            const deletePin = (post) => {
                if (post.title_no !== payload.title_no) {
                    return post;
                }

                let { authority = {} } = post;
                if (authority.is_pinable) {
                    post.authority.is_pin_fixable = true;
                    post.authority.is_pin_cancelable = false;
                    post.pin = null;
                }

                return post;
            };

            state.pagination.data = state.pagination.data.map(deletePin);
            state.pagination.noticeData = state.pagination.noticeData.map(deletePin);
            return;

        default:
    }
}, INITIAL_STATE);

export default postsReducer;
