import querystring from "querystring";
import cookie from "react-cookies";
import { toastr } from "../components/toastr";
import { DELETE, POST, PUT } from "../constant/method";
import { handleFetchErrors, handleFailedToFetch } from "../helpers";
import { BJ_API_DOMAIN } from "constant/config";

//댓글리스트
export const FETCH_GUESTS = "FETCH_GUESTS";
export const FETCH_GUESTS_SUCCESS = "FETCH_GUESTS_SUCCESS";
export const FETCH_GUESTS_MORE_SUCCESS = "FETCH_GUESTS_MORE_SUCCESS";
export const FETCH_GUESTS_FAILURE = "FETCH_GUESTS_FAILURE";

export const DELETE_GUEST = "DELETE_GUEST";
export const DELETE_GUEST_SUCCESS = "DELETE_GUEST_SUCCESS";
export const DELETE_GUEST_FAILURE = "DELETE_GUEST_FAILURE";

//댓글쓰기 완료
export const STORE_GUEST_SUCCESS = "STORE_GUEST_SUCCESS";
//댓글수정 완료
export const UPDATE_GUEST_SUCCESS = "UPDATE_GUEST_SUCCESS";

export function fetchGuests(page = 1, m_highlight_no = '', more = false) {
	return (dispatch) => {
		if (more === false) {
			dispatch({ type: FETCH_GUESTS });
		}

		const szParameter = querystring.stringify({
			page: page,
            m_highlight_no: m_highlight_no || ''
        });

		//로딩
		fetch(`${BJ_API_DOMAIN}/${global.bj_id}/guest?${szParameter}`, {
			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then((response) => {
				dispatch({
					type: more ? FETCH_GUESTS_MORE_SUCCESS : FETCH_GUESTS_SUCCESS,
					payload: response,
				});
			})
			.catch((error) => {
				dispatch({
					type: FETCH_GUESTS_FAILURE,
					payload: error ? error : { code: 0, message: error },
				});
			});
	};
}

export function storeGuest(props) {
	return fetch(`${BJ_API_DOMAIN}/${global.bj_id}/guest`, {
		method: POST,
		headers: { "Content-Type": "application/x-www-form-urlencoded" },
		body: querystring.stringify({ ...props, _token: cookie.load("XSRF-TOKEN") }),
		credentials: "include",
	});
}

export function updateGuest(props, title_no) {
	return fetch(`${BJ_API_DOMAIN}/${global.bj_id}/guest/${title_no}`, {
		method: POST,
		headers: { "Content-Type": "application/x-www-form-urlencoded" },
		body: querystring.stringify({ ...props, _token: cookie.load("XSRF-TOKEN"), _method: PUT }),

		credentials: "include",
	});
}

export function deleteGuest(title_no) {
	return (dispatch) => {
		dispatch({ type: DELETE_GUEST });

		fetch(`${BJ_API_DOMAIN}/${global.bj_id}/guest/${title_no}`, {
			method: POST,
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: querystring.stringify({ _token: cookie.load("XSRF-TOKEN"), _method: DELETE }),

			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then((response) => {
				dispatch({
					type: DELETE_GUEST_SUCCESS,
					title_no,
					payload: response,
				});
			})
			.catch((error) => {
				toastr.error(error.message);
			});
	};
}

//댓글리스트
export const FETCH_GUESTBOOK_MEMO = "FETCH_GUESTBOOK_MEMO";
export const FETCH_GUESTBOOK_MEMO_SUCCESS = "FETCH_GUESTBOOK_MEMO_SUCCESS";
export const FETCH_GUESTBOOK_MEMO_FAILURE = "FETCH_GUESTBOOK_MEMO_FAILURE";

export const DELETE_GUESTBOOK_MEMO = "DELETE_GUESTBOOK_MEMO";
export const DELETE_GUESTBOOK_MEMO_SUCCESS = "DELETE_GUESTBOOK_MEMO_SUCCESS";
export const DELETE_GUESTBOOK_MEMO_FAILURE = "DELETE_GUESTBOOK_MEMO_FAILURE";

//댓글쓰기 완료
export const STORE_GUESTBOOK_MEMO_SUCCESS = "STORE_GUESTBOOK_MEMO_SUCCESS";
//댓글수정 완료
export const UPDATE_GUESTBOOK_MEMO = "UPDATE_GUESTBOOK_MEMO";
export const UPDATE_GUESTBOOK_MEMO_CANCEL = "UPDATE_GUESTBOOK_MEMO_CANCEL";
export const UPDATE_GUESTBOOK_MEMO_SUCCESS = "UPDATE_GUESTBOOK_MEMO_SUCCESS";

//방명록쓰기폼 리셋
export const GUESTBOOK_FORM_RESET = "GUESTBOOK_FORM_RESET";
/**
 * 답글 리스트
 * @param {*} title_no
 * @param {*} title_no
 */
export function fetchGuestMemos(title_no, m_highlight_no) {
	return (dispatch) => {
		dispatch({
			type: FETCH_GUESTBOOK_MEMO,
			title_no,
		});

		const szParameter = querystring.stringify({
            m_highlight_no: m_highlight_no || ''
        });

		//로딩
		fetch(`${BJ_API_DOMAIN}/${global.bj_id}/guest/${title_no}/memo?${szParameter}`, {
			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then((response) => {
				dispatch({
					type: FETCH_GUESTBOOK_MEMO_SUCCESS,
					title_no,
					payload: response,
				});
			})
			.catch((error) => {
				dispatch({
					type: FETCH_GUESTBOOK_MEMO_FAILURE,
					title_no,
					payload: error ? error : { code: 0, message: error },
				});
			});
	};
}

/**
 * 답글 저장
 * @param {*} props
 * @param {*} title_no
 */
export function storeGuestMemo(props, title_no) {
	return fetch(`${BJ_API_DOMAIN}/${global.bj_id}/guest/${title_no}/memo`, {
		method: POST,
		headers: { "Content-Type": "application/x-www-form-urlencoded" },
		body: querystring.stringify({ ...props, _token: cookie.load("XSRF-TOKEN") }),
		credentials: "include",
	});
}
/**
 * 답글 수정
 * @param {*} props
 * @param {*} title_no
 */
export function updateGuestMemo(props, title_no, memo_no) {
	return fetch(`${BJ_API_DOMAIN}/${global.bj_id}/guest/${title_no}/memo/${memo_no}`, {
		method: POST,
		headers: { "Content-Type": "application/x-www-form-urlencoded" },
		body: querystring.stringify({ ...props, _token: cookie.load("XSRF-TOKEN"), _method: PUT }),
		credentials: "include",
	});
}

/**
 * 답글 삭제
 * @param {*} title_no
 * @param {*} title_no
 * @param {*} memo_no
 */
export function deleteGuestMemo(title_no, memo_no) {
	return (dispatch) => {
		dispatch({ type: DELETE_GUESTBOOK_MEMO });

		//로딩
		fetch(`${BJ_API_DOMAIN}/${global.bj_id}/guest/${title_no}/memo/${memo_no}`, {
			method: POST,
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: querystring.stringify({ _token: cookie.load("XSRF-TOKEN"), _method: DELETE }),
			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then(() => {
				dispatch({
					type: DELETE_GUESTBOOK_MEMO_SUCCESS,
					title_no,
					memo_no,
				});
			})
			.catch((error) => {
				toastr.error(error.message);
			});
	};
}
