import { fromJS, List, Map } from "immutable";
import {
    PHOTO_ADD,
    PHOTO_ADD_LIST,
    PHOTO_DELETE,
    PHOTO_FORM_RESET,
    PHOTO_OPEN_DIALOG,
    PHOTO_REMOVE_ALL,
    PHOTO_SET_DEFAULT_TITLE,
    PHOTO_SET_TITLE,
    PHOTO_UPLOAD_COMPLETE,
    PHOTO_UPLOAD_END,
    PHOTO_UPLOAD_FAILURE,
    PHOTO_UPLOAD_INIT,
    PHOTO_UPLOAD_PROGRESS,
    PHOTO_UPLOAD_START,
} from "actions/post.photo.action";

export function postsPhotoDialogReducer(state = { dialog: 0, reset: 0 }, action) {
    switch (action.type) {
        case PHOTO_OPEN_DIALOG: {
            return {
                ...state,
                dialog: state.dialog + 1,
            };
        }

        case PHOTO_FORM_RESET: {
            return {
                ...state,
                reset: state.reset + 1,
            };
        }

        default:
            return state;
    }
}

const INITIAL_STATE = List([]);

/**
 * 업로드 중일때 updateKey값으로 index찾기
 * @param {*} list
 * @param {*} key
 */
const findPhotoByUploadkey = (list, uploadKey) => {
    return list.findIndex((photo) => {
        return photo.get("uploadKey") === uploadKey;
    });
};

/**
 * Key값으로 index찾기
 * @param {*} list
 * @param {*} key
 */
const findPhotoBykey = (list, key) => {
    return list.findIndex((photo) => photo.get("key") === key);
};

/**
 * 대표이미지가 없는경우 대표이미지 설정
 */
const setDefaultTitleIndex = (list) => {
    var titleIndex = list.findIndex((photo) => photo.get("rnum") === 1);

    if (list.count() > 0 && titleIndex === -1) {
        //대표이미지가 없을 경우 첫번째
        return list.updateIn([0, "rnum"], () => 1);
    } else {
        return list;
    }
};

export function postsPhotoReducer(state = INITIAL_STATE, action) {
    let index = -1;
    switch (action.type) {
        case PHOTO_UPLOAD_INIT: //이미지 올리기 시작
            return state.push(Map(action.payload));

        case PHOTO_UPLOAD_PROGRESS: //이미지 진행룰
            index = findPhotoByUploadkey(state, action.payload.uploadKey);
            return state.set(index, Map({ type: action.type, ...action.payload }));

        case PHOTO_UPLOAD_COMPLETE: //이미지 등록
            index = findPhotoByUploadkey(state, action.payload.uploadKey);
            return state.set(index, Map({ type: action.type, ...action.payload }));

        case PHOTO_UPLOAD_FAILURE: //이미지 등록실패
            index = findPhotoByUploadkey(state, action.payload.uploadKey);
            return state.delete(index);

        case PHOTO_UPLOAD_END: //이미지 등록 종료
            return setDefaultTitleIndex(state);

        case PHOTO_ADD: //이미지 추가
            return state.push(Map(action.payload));

        case PHOTO_ADD_LIST: //여러개 이미지 추가
            return state.concat(List(fromJS(action.payload)));

        case PHOTO_REMOVE_ALL: //이미지 전체 삭제
            return INITIAL_STATE;

        case PHOTO_SET_TITLE: //대표이미지 선택
            return state.map((photo) => {
                return photo.set("rnum", photo.get("key") === action.key ? 1 : 2);
            });

        case PHOTO_SET_DEFAULT_TITLE: //대표가 없을때 첫번째를 대표로 지정함
            return setDefaultTitleIndex(state);

        case PHOTO_DELETE: //이미지 일부 삭제
            index = findPhotoBykey(state, action.key);
            return setDefaultTitleIndex(state.delete(index));

        case PHOTO_UPLOAD_START:
        default:
            return state;
    }
}
