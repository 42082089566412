import qs from "qs";

import axios from "libs/axios";

import { SEARCH_ALL, TITLE_CONTENTS } from "constant/search";
import { DELETE } from "constant/method";
import { ALL_MONTHS } from "constant/month";

import { toastr } from "components/toastr";

export const FETCH_POSTS = "FETCH_POSTS";
export const FETCH_POSTS_SUCCESS = "FETCH_POSTS_SUCCESS";
export const FETCH_POSTS_ERROR = "FETCH_POSTS_ERROR";

export const DELETE_POSTS = "DELETE_POSTS";
export const DELETE_POSTS_SUCCESS = "DELETE_POSTS_SUCCESS";

/**
 * 게시글 리스트 로딩
 * @param key
 * @param page
 * @param perPage
 * @param field
 * @param keyword
 * @param type
 * @param bbsNo
 * @param months
 * @param append
 * @returns {function(...[*]=)}
 */
export const fetchPosts = (
	key = null,
	{ page = 1, perPage = 20, field = TITLE_CONTENTS, keyword = "", type = SEARCH_ALL, bbsNo = null, months = ALL_MONTHS },
	append = false,
) => async (dispatch) => {
	// 로딩 시작
	dispatch({ type: FETCH_POSTS, payload: { key, append } });

	// 게시글 리스트 조회 url
	let url = `/${global.bj_id}/board`;
	bbsNo && (url += `/${bbsNo}`);

	// 게시글 리스트 조회 parameter
	const params = {
		page,
		per_page: perPage,
		field,
		keyword,
		type,
		months,
	};

	try {
		// api 통신 시작
		const response = await axios.get(url, { params });

		dispatch({
			type: FETCH_POSTS_SUCCESS,
			payload: response,
		});
	} catch (error) {
		dispatch({
			type: FETCH_POSTS_ERROR,
			payload: error,
		});
	}
};

/**
 * 선택한 게시글 리스트 다중 삭제
 * @param titleNoList
 * @returns {function(...[*]=)}
 */
export const deletePosts = (titleNoList) => async (dispatch) => {
	dispatch({ type: DELETE_POSTS });

	try {
		const response = await axios.post(
			`/${global.bj_id}/board/1`,
			qs.stringify({
				_method: DELETE,
				title_lists: JSON.stringify(titleNoList),
			}),
		);

		dispatch({
			type: DELETE_POSTS_SUCCESS,
			payload: response,
		});

		toastr.success(response.message, {
			timeOut: 2000,
		});
	} catch (error) {
		toastr.error(error.message, {
			timeOut: 2000,
		});
	}
};
