import produce from "immer";
import {
	FETCH_BANNER,
	FETCH_BANNER_FAILURE,
	FETCH_BANNER_SUCCESS,
	UPDATE_BANNER_SUCCESS,
    UPLOAD_BANNER_SUCCESS,
    STORE_BANNER_SUCCESS,
	DELETE_BANNER_SUCCESS,
} from "actions/setting.banner.action";

const INITIAL_STATE = {
	error: null,
	loading: true,
	name: "",
	bannerInfo: []
};


const settingBannerReducer = produce((state, action) => {
    switch (action.type) {
		case FETCH_BANNER:
            state.error = null;
            state.loading = true;
            state.name = "banner";
            break;
		case FETCH_BANNER_SUCCESS:
            state.error = null;
            state.loading = false;
            state.name = "banner";
            state.bannerInfo = action.payload;
            break;
		case FETCH_BANNER_FAILURE:
            state.loading = false;
            state.name = "banner";
            state.error = action.payload;
            break;
		case UPLOAD_BANNER_SUCCESS:
            state.bannerInfo.data.push(action.payload.data);
            break;
        case STORE_BANNER_SUCCESS:
            const storeIdx = state.bannerInfo.data.findIndex((banner) => {
                return banner.banner_idx === parseInt(action.banner_idx, 10);
            });
            state.bannerInfo.data[storeIdx] = action.payload.data;
            break;
        case UPDATE_BANNER_SUCCESS:
            const updIdx = state.bannerInfo.data.findIndex((banner) => {
                return banner.banner_idx === parseInt(action.banner_idx, 10);
            });
            state.bannerInfo.data[updIdx] = action.payload.data;
            break;
		case DELETE_BANNER_SUCCESS:
            const delIdx = state.bannerInfo.data.findIndex((banner) => {
                return parseInt(banner.banner_idx, 10) === parseInt(action.payload.data.banner_idx, 10);
            });
            state.bannerInfo.data.splice(delIdx, 1);
            break;
        default:
			return;
    }
}, INITIAL_STATE);

export default settingBannerReducer;