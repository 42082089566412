import { PUT_USER_NICK } from "../actions/setting.station.action";
import { LOGIN_SUCCESS, LOGING_FAILURE } from "constant/auth";

const initialState = { is_login: false };

export default function authenticationReducer(state = initialState, action) {
	switch (action.type) {
		case LOGIN_SUCCESS:
			return {
				...state,
				...action.plyload,
				is_login: true,
			};
		case LOGING_FAILURE:
			return {
				...state,
				is_login: false,
			};
		case PUT_USER_NICK:
			return {
				...state,
				nickname: action.payload,
			};
		default:
			return state;
	}
}
