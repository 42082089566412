import * as SentryBrowser from "@sentry/browser";
import cookie from "react-cookies";
import store from "store";

if (process.env.NODE_ENV === 'production') {
    SentryBrowser.init({
        dsn: 'https://abc9ccfdf65a4e0c9c82196a4bad8c69@sentry.afreecatv.com/13',
        defaultIntegrations: false,
    });
} else {
    SentryBrowser.init({
        dsn: 'https://4c89897384f9494693d5e16abe30e59f@sentry.afreecatv.com/19',
        defaultIntegrations: false,
    });
}

const reportSentry = (error, errorInfo = {}, level = SentryBrowser.Severity.Error) => {
    const { user_id = '' } = store.getState().authenticationReducer;
    SentryBrowser.withScope((scope) => {
        if (typeof errorInfo === "object") {
            Object.keys(errorInfo).forEach((key) => {
                scope.setExtra(key, errorInfo[key]);
            });
        }
        scope.setLevel(level);
        scope.setExtra('cookies', cookie.loadAll())
            .setExtra('user-agent', navigator.userAgent)
            .setTag('hostname', process.env.BUILD_HOSTNAME || '')
            .setTag('url', window.location.href)
            .setTag('user_id', user_id);
        SentryBrowser.captureException(error);
    });
};

export default reportSentry;