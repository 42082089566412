import axios from "libs/axios";

export const FETCH_NEARBY_POST = "FETCH_NEARBY_POST";
export const FETCH_NEARBY_POST_SUCCESS = "FETCH_NEARBY_POST_SUCCESS";
export const FETCH_NEARBY_POST_FAILURE = "FETCH_NEARBY_POST_FAILURE";

export const CLEAR_NEARBY_POST = "CLEAR_NEARBY_POST";

/**
 * 근처 글 로딩 (이전/다음 글)
 * @param titleNo
 * @param bbsNo
 * @returns {function(...[*]=)}
 */
export const fetchNearbyPost = (titleNo, bbsNo) => async (dispatch) => {
	dispatch({ type: FETCH_NEARBY_POST });

	const url = `/${global.bj_id}/prevnext/${titleNo}${bbsNo && `?${bbsNo}`}`;

	try {
		const response = await axios.get(url);

		dispatch({
			type: FETCH_NEARBY_POST_SUCCESS,
			payload: response,
		});
	} catch (error) {
		dispatch({
			type: FETCH_NEARBY_POST_FAILURE,
			payload: error,
		});
	}
};
