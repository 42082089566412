import produce from "immer";

import { FETCH_RECENT_EMOTICON_LIST, 
    FETCH_RECENT_EMOTICON_LIST_FAILURE, 
    FETCH_RECENT_EMOTICON_LIST_SUCCESS,
    FETCH_RECENT_EMOTICON_TAB_CHANGE,
} from "actions/emoticon.recent.action";

import {RecentEmoticonType} from "constant/emoticon";
import { CLEAR, ERROR, LOADING, SUCCESS } from "constant/status";


const INITIAL_STATE = {
    status: CLEAR,
    domain: "",
    data: null,
    error: null,
    click_type: RecentEmoticonType.RECENT_DEFAULT,
};

const emoticonRecentReducer = produce((state, { type, payload }) => {
    switch (type) {
        // 최근 사용한 이모티콘 리스트 로딩 시작
        case FETCH_RECENT_EMOTICON_LIST:
            state.status = LOADING;
            break;

        // 최근 사용한 이모티콘 리스트 로딩 완료
        case FETCH_RECENT_EMOTICON_LIST_SUCCESS:
            //const { DATA } = payload;
            const { default:default_emoticon, ogq:ogq_emoticon } = payload.DATA;

            state.status = SUCCESS;
            state.recent_defaults = default_emoticon;
            state.recent_ogqs = ogq_emoticon;
            state.domain = payload.domain;
            
            break;

        // 최근 사용한 이모티콘 로딩 실패
        case FETCH_RECENT_EMOTICON_LIST_FAILURE:
            state.status = ERROR;
            state.error = payload;
            break;

        //최근 사용한 이모티콘 탭 변경
        case FETCH_RECENT_EMOTICON_TAB_CHANGE:
            return {...state, click_type: payload }
            
        default:

        
    }
}, INITIAL_STATE);

export default emoticonRecentReducer;
