import { h, render } from "preact";
import { Notification } from "preact-notification";
import React, { useEffect, useRef } from "react";

export default function NotificationBarWrapper() {
    // Get the raw DOM node to render into
    const ref = useRef(null);

    useEffect(() => {
        if (ref.current) {
            // Can't use two different JSX constructors in
            // the same file, so we're writing the JSX output
            // manually. (h is the same as createElement)
            render(h(Notification, null), ref.current);
        }

        return () => {
            // Clear Preact rendered tree when the parent React
            // component unmounts
            if (ref.current) {
                render(null, ref.current);
            }
        };
    }, []);

    return <div ref={ref} style={{ display: "unset" }} />;
}
