import { isMobile } from "react-device-detect";

import produce from "immer";
import moment from "moment";

import { CLEAR_VODS, FETCH_VODS, FETCH_VODS_FAILURE, FETCH_VODS_SUCCESS } from "actions/vods.action";
import { DELETE_PLAYLIST_SUCCESS } from "actions/playlist.action";
import { DESTORY_PIN_SUCCESS, STORE_PIN_SUCCESS } from "actions/home.action";
import { DELETE_POST_SUCCESS } from "actions/post.action";

import { UCC } from "constant/board";
import { CLEAR, ERROR, LOADING, LOADING_MORE, SUCCESS } from "constant/status";

const INITIAL_STATE = {
	status: CLEAR,
	error: false,
	append: false,
	pagination: {
		data: [],
		links: {},
		meta: {},
		page_type: null,
		catch_story: [],
		catch_story_total:0
	},
};

const vodsReducer = produce((state, { type, payload }) => {
	switch (type) {
		// VOD 리스트 조회 시작
		case FETCH_VODS:
			state.status = !payload.append ? LOADING : LOADING_MORE;
			state.append = payload.append;
			/*
			* 이슈 : 
			* Catch, 스토리 탭 빠르게 번갈아 누를 때 Catch 응답이 더 느릴 시
			* FETCH_VODS_SUCCESS state.pagination = pagination; state.pagination.page_type = payload.page_type; 코드 때문에 탭은 스토리지만 리스트 데이터는 Catch가 되는 이슈 발생
			* 해결 : 
			* FETCH_VODS 에서 요청 들어온 순서대로 page_type을 저장하고 FETCH_VODS_SUCCESS 에서 
			* state.pagination.page_type(마지막 요청 page_type 값)과 응답API(vods.action.js)에서 넘어온 payload.page_type 과 비교하여 같을 경우에만 data 갱신
			*/
			state.pagination.page_type = payload.page_type;
			return;

		// VOD 리스트 조회 성공
		case FETCH_VODS_SUCCESS:
			state.status = SUCCESS;
			const pagination = payload;

			if(state.pagination.page_type === payload.page_type){
				// 더보기 시 뒤에 붙여지는 경우(infinity scroll)
				if (state.append) {
					pagination.data = [...state.pagination.data, ...payload.data];
					// 전체 VOD에서 더보기 시 최상단 캐치스토리까지 노출시켜줘야 함
					if(state.pagination.page_type == "all"){
						pagination.catch_story = state.pagination.catch_story ? [...state.pagination.catch_story] : [];
						pagination.catch_story_total = state.pagination.catch_story_total ? state.pagination.catch_story_total : 0;
					}
				}

				state.pagination = pagination;
				state.pagination.page_type = payload.page_type;
			}
			
			return;

		// VOD 리스트 조회 실패
		case FETCH_VODS_FAILURE:
			state.status = ERROR;
			state.error = payload;
			return;

		// VOD 리스트 초기화
		case CLEAR_VODS:
			return INITIAL_STATE;

		// VOD 게시글 삭제 성공
		case DELETE_POST_SUCCESS:
			if (state.status !== SUCCESS) {
				return;
			}

			// PC 에서는 게시글 리로드
			if (!isMobile) {
				state.status = CLEAR;
				return;
			}

			// 모바일에서는 해당 게시글 제거
			state.pagination.data = state.pagination.data.filter((vod) => vod.title_no !== payload.title_no);
			return;

		// 재생목록 리스트 삭제 성공
		case DELETE_PLAYLIST_SUCCESS:
			if (state.status !== SUCCESS) {
				return;
			}

			// PC 에서는 재생목록 리로드
			if (!isMobile) {
				state.status = CLEAR;
				return;
			}

			// 모바일에서는 해당 재생목록 제거
			state.pagination.data = state.pagination.data.filter((value) => value.list_idx !== payload.playlist_idx);
			return;

		// 홈에 고정 업데이트
		case STORE_PIN_SUCCESS:
			if (state.status !== SUCCESS) {
				return;
			}

			// 동영상만 업데이트
			if (payload.board_type !== UCC) {
				return;
			}

			state.pagination.data = state.pagination.data.map((vod) => {
				const { authority = {} } = vod;
				const pinable = authority.is_pinable, // 핀 고정 권한을 갖고 있는지 여부
					pinFixed = vod.title_no === payload.title_no; // 현재 고정 시킨 게시글

				vod.pin = pinFixed ? { reg_date: moment().format("YYYY-MM-DD HH:mm:ss"), from_reducer: true } : null;
				vod.authority.is_pin_fixable = pinable && !pinFixed;
				vod.authority.is_pin_cancelable = pinable && pinFixed;
				return vod;
			});
			return;

		// 홈에 고정 해제
		case DESTORY_PIN_SUCCESS:
			if (state.status !== SUCCESS) {
				return;
			}

			state.pagination.data = state.pagination.data.map((vod) => {
				if (vod.title_no !== payload.title_no) {
					return vod;
				}

				let { authority = {} } = vod;
				if (authority.is_pinable) {
					vod.authority.is_pin_fixable = true;
					vod.authority.is_pin_cancelable = false;
					vod.pin = null;
				}
				return vod;
			});
			return;

		default:
			return;
	}
}, INITIAL_STATE);

export default vodsReducer;
