import React, { Component } from "react";
import {StationError} from "pages/station/error/StationError";
import reportSentry from "libs/sentry";

class Sentry extends Component  {
    state = {
        error: false
    };

    componentDidCatch(error, errorInfo) {
        reportSentry(error, errorInfo);

        this.setState({
            error: true
        });
    }

    render()    {
        const { error } = this.state;

        if (error)  {
            // 오류 발생 시
            return (
                <div id="bs-wrap">
                    <StationError error={{code: 9003}}/>
                </div>
            );
        }

        // 정상 동작 시
        return this.props.children;
    }

}

export default Sentry;