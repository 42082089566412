import axios from "libs/axios";

import { SEARCH_ALL, TITLE_CONTENTS } from "constant/search";
import { ORDER_REG_DATE } from "constant/order";
import {ALL_MONTHS} from "constant/month";

export const FETCH_SEARCH = "FETCH_SEARCH";
export const FETCH_SEARCH_SUCCESS = "FETCH_SEARCH_SUCCESS";
export const FETCH_SEARCH_FAILURE = "FETCH_SEARCH_FAILURE";

/**
 * 채널 검색
 * @param key
 * @param page
 * @param perPage
 * @param field
 * @param keyword
 * @param type
 * @param orderBy
 * @param months
 * @param append
 * @returns {function(...[*]=)}
 */
export const fetchSearch = (
	key = null,
	{ page = 1, perPage = 20, field = TITLE_CONTENTS, keyword = "", type = SEARCH_ALL, orderBy = ORDER_REG_DATE, months = ALL_MONTHS },
	append = false,
) => async (dispatch) => {
	// 검색 로딩
	dispatch({ type: FETCH_SEARCH, payload: { key, append } });

	// 검색 parameter
	const params = {
		page,
		per_page: perPage,
		field,
		keyword,
		type,
		orderby: orderBy,
		months
	};

	try {
		const response = await axios.get(`/${global.bj_id}/search`, { params });

		dispatch({
			type: FETCH_SEARCH_SUCCESS,
			payload: response,
		});
	} catch (error) {
		dispatch({
			type: FETCH_SEARCH_FAILURE,
			payload: error,
		});
	}
};
