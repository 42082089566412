import { adBannerControl } from "libs/scriptLoad";

export const AD_ON_OFF_INFO = "AD_ON_OFF_INFO";

export const adBannerInfo = () => {
   return async (dispatch) => {
        await dispatch(adBannerControl());
   }

}
