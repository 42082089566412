import produce from "immer";

import { FETCH_SAYBUZZ_AD, FETCH_SAYBUZZ_AD_SUCCESS, FETCH_SAYBUZZ_AD_FAILURE } from "actions/ad/saybuzz.action";

import { CLEAR, ERROR, LOADING, SUCCESS } from "constant/status";

const INITIAL_STATE = {
    status: CLEAR,
    error: null,
    data: {
        campaign_id: '',
        space_id: '',
        log : {},
        image_data : {},
        mobile_sub_data : {},
        mobile_main_data : {},
    },
};


const sayBuzzReducer = produce((state, { type, payload }) => {
    switch (type) {
        case FETCH_SAYBUZZ_AD:
            state.status = LOADING;
            return;

        case FETCH_SAYBUZZ_AD_SUCCESS:
            state.status = SUCCESS;

            state.data = {
                campaign_id: payload.campaign_id,
                space_id: payload.space_id,
                log: payload.log,
                image_data: payload.space_element[0],
                mobile_sub_data : payload.space_element[1],
                mobile_main_data : payload.space_element[2],
    }
            return;

        case FETCH_SAYBUZZ_AD_FAILURE:
            state.status = ERROR;
            state.error = payload;
            return;

        default:
    }
}, INITIAL_STATE);

export default sayBuzzReducer;
