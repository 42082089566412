import React, { Component } from "react";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import classNames from "classnames";

import { MODE_HOT_KEYWORD, MODE_LATEST_KEYWORD, MODE_RECOMMEND_KEYWORD, removeCSk } from "actions/afreeca.search.action";

import { JAVASCRIPT_VOID } from "constant/config";

class AutoCompleteBox extends Component {
	constructor(props) {
		super(props);
		this.state = {
			couserPosition: -1,
		};
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.keyMoveIndex !== nextProps.keyMoveIndex) {
			const { keywords = { data: null }, keyword, onChange } = nextProps;
			const { data, mode } = keywords;

			//없을경우
			if (!data || data.length === 0) {
				return;
			}
			//위로
			let position = this.state.couserPosition;
			let move = nextProps.keyMoveIndex - this.props.keyMoveIndex;
			if (move === -1) {
				//위로이동
				position--;
			} else if (move === 1) {
				//아래로 이동
				position++;
			}

			if (position < 0) {
				position = data.length;
			} else if (position >= data.length) {
				position = -1;
			}

			this.setState({
				couserPosition: position,
			});
			if (onChange) {
				if (position < 0 || position >= data.length) {
					return onChange(keyword, true);
				} else {
					var word = data[position];
					if (mode === MODE_HOT_KEYWORD) {
						return onChange(word.keyword);
					} else if (mode === MODE_LATEST_KEYWORD) {
						return onChange(word);
					} else if (mode === MODE_RECOMMEND_KEYWORD) {
						return onChange(word.d);
					}
				}
			}
		}
	}

	handleLatestKeywordDelete = (e, word) => {
		e.stopPropagation();
		e.preventDefault();
		this.props.removeCSk(word);
	};

	renderHeader(mode) {
		if (mode === MODE_HOT_KEYWORD) {
			return (
				<h3>
					<Trans>실시간 인기 검색어</Trans>
				</h3>
			);
		} else if (mode === MODE_LATEST_KEYWORD) {
			return (
				<h3>
					<Trans>최근 검색어</Trans>
				</h3>
			);
		} else if (mode === MODE_RECOMMEND_KEYWORD) {
			return (
				<h3 className="blind">
					<Trans>검색어 자동완성</Trans>
				</h3>
			);
		}
	}
	renderRow(index, mode, word) {
		if (mode === MODE_HOT_KEYWORD) {
			return (
				<a href={JAVASCRIPT_VOID} onClick={() => this.props.onClick(word.keyword)}>
					<em className="num">{index + 1}</em>
					{word.keyword}
					<span className={classNames("rank", word.updown, { new: word.show_text === "new" })}>
						{word.show_text !== "new" && word.show_text !== "-" && <em />}
						{word.show_text.toUpperCase()}
					</span>
				</a>
			);
		} else if (mode === MODE_LATEST_KEYWORD) {
			return (
				<a href={JAVASCRIPT_VOID} onClick={() => this.props.onClick(word)}>
					{word}
					<button type="button" className="btn_delete" onClick={(e) => this.handleLatestKeywordDelete(e, word)}>
						<span>
							<Trans>삭제</Trans>
						</span>
					</button>
				</a>
			);
		} else if (mode === MODE_RECOMMEND_KEYWORD) {
			return (
				<a href={JAVASCRIPT_VOID} onClick={() => this.props.onClick(word.d)}>
					{word.d}
				</a>
			);
		}
	}

	handleMouseOver = (e) => {
		if (this.props.onMouseOver) {
			this.props.onMouseOver(e);
		}
	};
	handleMouseOut = (e) => {
		if (this.props.onMouseOut) {
			this.props.onMouseOut(e);
		}
	};
	handleMouseLeave = (e) => {
		if (this.props.onMouseLeave) {
			this.props.onMouseLeave(e);
		}
	};

	render() {
		const { couserPosition } = this.state;
		const { keywords = { data: null } } = this.props;
		const { data, mode } = keywords;
		return (
			<div
				id="search_type2"
				className={classNames("search_box")}
				onMouseOver={this.handleMouseOver}
				onMouseOut={this.handleMouseOut}
				onMouseLeave={this.handleMouseLeave}
			>
				<div className="inner_area">
					{this.renderHeader(mode)}
					<ul className="list">
						{data &&
							data.map((word, index) => (
								<li key={index} className={classNames({ hv: couserPosition === index })}>
									{this.renderRow(index, mode, word)}
								</li>
							))}
					</ul>
				</div>
			</div>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		removeCSk: (word) => {
			dispatch(removeCSk(word));
		},
	};
};
export default connect(
	undefined,
	mapDispatchToProps,
)(AutoCompleteBox);
