import { SCH_DOMAIN } from "constant/config";
import { toastr } from "components/toastr";

import axios from "libs/axios";

export const TAG_SEARCH_START = "TAG_SEARCH_START";
export const TAG_SEARCH_LOADING = "TAG_SEARCH_LOADING";
export const TAG_SEARCH_DONE = "TAG_SEARCH_DONE";
export const TAG_SEARCH_DONE_MORE = "TAG_SEARCH_DONE_MORE";
export const TAG_SEARCH_FAIL = "TAG_MODE_LATEST_KEYWORD";
export const TAG_SEARCH_END = "TAG_SEARCH_END";
export const TAG_SEARCH_INIT = "TAG_SEARCH_INIT";
export const TAG_SELECT_CHECK = "TAG_SELECT_CHECK";


/**
 * BJ 리스트 검색 API
 * @param {string} userId 
 * @param {number} page 
 * @returns 
 */
export const getBjSearch = (userId, page) => async(dispatch) => {
    const params = {
        m : 'bjList',
        v : '2.0',
        w : 'webk',
        szKeyword: userId,
        nPageNo : page,
        nListCnt :15
    }
    dispatch({type: TAG_SEARCH_START});
    try {
        if(page === 1) {
            dispatch({type: TAG_SEARCH_LOADING});
        }
        const response = await axios.get(`${SCH_DOMAIN}/api.php`, {params, withCredentials: false});

        if(response.RESULT < 0) {
            dispatch({type: TAG_SEARCH_FAIL});
        }
        // 1 page DONE
        if(page === 1) {
            dispatch({type: TAG_SEARCH_DONE, payload: response});
        } else { // MORE DONE
            dispatch({type:TAG_SEARCH_DONE_MORE, payload: response})
        }

        dispatch({type: TAG_SEARCH_END});
    } catch (error) {
        dispatch({type: TAG_SEARCH_END});
    }

}
