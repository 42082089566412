import { Map, List } from "immutable";
import { FETCH_REPORT_SUCCESS, FETCH_REPORT_FAILURE } from "../actions/report.action";

const INITIAL_STATE = Map({
	error: null,
	reportReason: List([]),
});

export default function reportReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case FETCH_REPORT_SUCCESS:
			return state.set("reportReason", List(action.payload));
		case FETCH_REPORT_FAILURE:
			return state.set("error", action.payload);
		default:
			return state;
	}
}
