import { AD_ON_OFF_INFO } from "actions/adBanner.action";

const INITIAL_STATE = {
	error: null,
	loading: false,
	bannerControlArr: {}
};

export function adBannerReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case AD_ON_OFF_INFO:
			return {
				...state,
				loading: true,
				bannerControlArr : action.payload,
			}
		default:
			return state;
	}
}

export default adBannerReducer;
