import {
    CLEAR_POST,
    DELETE_POST_LIKE_SUCCESS,
    DELETE_POST_SUCCESS,
    FETCH_POST,
    FETCH_POST_FAILURE,
    FETCH_POST_SUCCESS,
    STORE_POST_LIKE_SUCCESS,
    CANCEL_POST_NOTICE_SUCCESS
} from "actions/post.action";
import { CLEAR, ERROR, LOADING, SUCCESS } from "constant/status";
import produce from "immer";
import moment from "moment";

const INITIAL_STATE = {
    status: CLEAR,
    deleted: false,
    error: null,
    data: null,
};

const postReducer = produce((state, { type, payload }) => {
    switch (type) {
        // 게시글 로딩
        case FETCH_POST:
            state.deleted = false;
            state.status = LOADING;
            return;

        // 게시글 로딩 완료
        case FETCH_POST_SUCCESS:
            state.status = SUCCESS;
            state.data = payload;
            return;

        // 게시글 로딩 실패
        case FETCH_POST_FAILURE:
            state.status = ERROR;
            state.error = payload;
            return;

        // 게시글 데이터 초기화
        case CLEAR_POST:
            return INITIAL_STATE;

        // 게시글 삭제
        case DELETE_POST_SUCCESS:
            if (state.status !== SUCCESS) {
                return;
            }

            state.deleted = true;
            return;

        // 게시글 좋아요 등록
        case STORE_POST_LIKE_SUCCESS: {
            if (state.status !== SUCCESS || !state.data) {
                return;
            }

            const { user_id, copyright } = payload;
            const { user_id: copyright_id, user_nick: copyright_nick } = copyright || {};

            state.data.like = {
                reg_date: moment().format("YYYY-MM-DD HH:mm:ss"),
            };
            if (user_id === copyright_id) {
                state.data.copyright.like = {
                    reg_date: moment().format("YYYY-MM-DD HH:mm:ss")
                };

                state.data.copyright.like_id = copyright_id;
                state.data.copyright.like_nick = copyright_nick;
            }
            if (global.bj_id === user_id) {
                state.data.bjlike = {
                    reg_date: moment().format("YYYY-MM-DD HH:mm:ss"),
                };
            }

            return;
        }

        // 게시글 좋아요 취소
        case DELETE_POST_LIKE_SUCCESS: {
            if (state.status !== SUCCESS || !state.data) {
                return;
            }
            const { user_id, copyright } = payload;
            const { user_id: copyright_id } = copyright || {};

            state.data.like = null;
            if (global.bj_id === user_id) {
                state.data.bjlike = null;
            }

            if (user_id === copyright_id) {
                state.data.copyright.like = null;
            }
            return;
        }

        // 게시글 공지 해제
		case CANCEL_POST_NOTICE_SUCCESS:
			if (state.status !== SUCCESS) {
				return;
			}

            state.data.notice_yn = 0;
            
			return;
        default:
    }
}, INITIAL_STATE);

export default postReducer;
