import querystring from "querystring";
import i18next from "i18next";
import { FORM_CONTENT_TYPE } from "./config";
import { BJ_API_DOMAIN } from "constant/config";
import { handleFetchErrors, handleFailedToFetch } from "helpers";
import { DELETE, POST } from "constant/method";
import cookie from "react-cookies";
import { isMobile } from "react-device-detect";
import { toastr } from "components/toastr";

export const FETCH_VODEDITOR = "FETCH_VODEDITOR";
export const FETCH_VODEDITOR_SUCCESS = "FETCH_VODEDITOR_SUCCESS";
export const FETCH_VODEDITOR_FAILURE = "FETCH_VODEDITOR_FAILURE";

export const STORE_VODEDITOR = "STORE_VODEDITOR";
export const STORE_VODEDITOR_SUCCESS = "STORE_VODEDITOR_SUCCESS";
export const STORE_VODEDITOR_FAILURE = "STORE_VODEDITOR_FAILURE";

export const DELETE_VODEDITOR = "DELETE_VODEDITOR";
export const DELETE_VODEDITOR_SUCCESS = "DELETE_VODEDITOR_SUCCESS";
export const DELETE_VODEDITOR_FAILURE = "DELETE_VODEDITOR_FAILURE";

export const SET_VODEDITOR_POST = "SET_VODEDITOR_POST";

/**
 * 편집각 유저 리스트 가져오기
 * @returns {Function}
 */
export function fetchVodeditor() {
	return (dispatch) => {
		dispatch({ type: FETCH_VODEDITOR });

		fetch(`${BJ_API_DOMAIN}/${global.bj_id}/vodeditor`, {
			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then((response) => {
				dispatch({
					type: FETCH_VODEDITOR_SUCCESS,
					payload: response,
				});
			})
			.catch((error) => {
				dispatch({
					type: FETCH_VODEDITOR_FAILURE,
					payload: error ? error : { code: 0, message: error },
				});
			});
	};
}

/**
 * 편집각 유저 권한 삭제
 * @param idx	삭제할 idx
 * @returns {Function}
 */
export function deleteVodeditor(idx) {
	return (dispatch) => {
		fetch(`${BJ_API_DOMAIN}/${global.bj_id}/vodeditor/${idx}`, {
			method: POST,
			headers: FORM_CONTENT_TYPE,
			body: querystring.stringify({ _token: cookie.load("XSRF-TOKEN"), _method: DELETE }),
			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then((response) => {
				toastr.success(i18next.t("해제되었습니다."), {
					timeOut: 2000,
				});
				dispatch({
					type: DELETE_VODEDITOR_SUCCESS,
					payload: response,
				});
			})
			.catch((error) => {
				toastr.error(error.message, {
					timeOut: 2000,
				});
				dispatch({
					type: DELETE_VODEDITOR_FAILURE,
					payload: error ? error : { code: 0, message: error },
				});
			});
	};
}

/**
 * 편집각 유저 추가
 * @param data	유저 객체
 * @returns {Function}
 */
export function storeVodeditor(data) {
	return (dispatch) => {
		fetch(`${BJ_API_DOMAIN}/${global.bj_id}/vodeditor`, {
			method: POST,
			headers: FORM_CONTENT_TYPE,
			body: querystring.stringify({ _token: cookie.load("XSRF-TOKEN"), user_id: data.user_id }),
			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then((response) => {
				dispatch({
					type: STORE_VODEDITOR_SUCCESS,
					payload: response,
				});

				if (isMobile) {
					dispatch(setVodeditorPost(false));
				}

				toastr.success(i18next.t("편집각 권한이 추가됐습니다."), {
					timeOut: 2000,
				});
			})
			.catch((error) => {
				toastr.error(error.message, {
					timeOut: 2000,
				});
				dispatch({
					type: STORE_VODEDITOR_FAILURE,
					payload: error ? error : { code: 0, message: error },
				});
			});
	};
}

/**
 * 추가 페이지 세팅
 * @param bPost	추가 페이지 여부
 * @returns {{payload: *, type: string}}
 */
export const setVodeditorPost = (bPost) => ({ type: SET_VODEDITOR_POST, payload: bPost });
