import classNames from "classnames";
import Setting from "pages/setting/Setting";
import Station from "pages/station/Station";
import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import { connect } from "react-redux";
import { BrowserRouter, HashRouter, Route, Switch } from "react-router-dom";
import { authenticationCheck } from "./actions/authentication.action";
import { REACT_POPUP_OPEN } from "./actions/station.action";
import "./App.css";
import Header from "./components/header/Header";
import ReduxToastr from "./components/toastr";
import TopButton from "./components/TopButton";
import { isWebview } from "./helpers";
import "./libs/toastr/react-redux-toastr.css";
import NotFound from "./pages/station/error/NotFound";

const isBrowserHistory = window.history.pushState;
const Router = isBrowserHistory ? BrowserRouter : HashRouter;

class App extends Component {
    constructor(props) {
        super(props);
        this.props.authenticationCheck();
        this.state = {
            random: Math.random()
                .toString(36)
                .replace(/[^a-z]+/g, "")
                .substr(0, 20),
        };
    }

    handleDocumentClick = (e) => {
        this.props.documentClick();
    };
    render() {
        const { match, pageReducer } = this.props;
        const { random } = this.state;
        const { bsWrapClassName } = pageReducer;
        if (isMobile) {
            return (
                <Router>
                    <div id="bs-wrap" className={classNames(bsWrapClassName, isWebview && "webview")} onClick={this.handleDocumentClick}>
                        <img src={`/station.png?type=mobile&${random}`} style={{ display: "none" }} />
                        <ReduxToastr showCloseButton={false} closeOnToastrClick={true} />

                        <Switch>
                            <Route exact path="/" component={NotFound} />
                            <Route path="/:id/setting" component={Setting} />
                            <Route path="/:id" component={Station} />
                        </Switch>
                    </div>
                    <TopButton />
                </Router>
            );
        } else {
            return (
                <Router>
                    <div id="bs-wrap" className={classNames({ [bsWrapClassName]: true })} onClick={this.handleDocumentClick}>
                        <img src={`/station.png?type=pc&${random}`} style={{ display: "none" }} />
                        <ReduxToastr showCloseButton={false} />
                        <Header />
                        <Switch>
                            <Route exact path="/" component={NotFound} />
                            <Route path="/:id/setting" component={Setting} />
                            <Route path="/:id" component={Station} />
                        </Switch>
                    </div>
                    <TopButton />
                </Router>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        stationInfo: state.stationReducer.stationInfo,
        pageReducer: state.pageReducer,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        documentClick: () => {
            dispatch({
                type: REACT_POPUP_OPEN,
                id: 0,
            });
        },
        authenticationCheck: () => {
            return dispatch(authenticationCheck());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
