import cn from "classnames";
import PropTypes from "prop-types";
import React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import * as actions from "./actions";
import ToastrBox from "./ToastrBox";
import { EE } from "./toastrEmitter";
import { updateConfig } from "./utils";

const toastrDom = document.getElementById("toastr");

export class ReduxToastr extends React.Component {
	static displayName = "ReduxToastr";

	static propTypes = {
		toastr: PropTypes.object,
		newestOnTop: PropTypes.bool,
		timeOut: PropTypes.number,
		preventDuplicates: PropTypes.bool,
		closeOnToastrClick: PropTypes.bool,
	};

	static defaultProps = {
		newestOnTop: true,
		timeOut: 5000,
		preventDuplicates: false,
		closeOnToastrClick: false,
	};

	toastrFired = {};

	constructor(props) {
		super(props);
		updateConfig(props);
	}

	componentDidMount() {
		const { add, clean, removeByType, remove } = this.props;
		EE.on("add/toastr", add);
		EE.on("clean/toastr", clean);
		EE.on("removeByType/toastr", removeByType);
		EE.on("remove/toastr", remove);
	}

	componentWillUnmount() {
		EE.removeListener("add/toastr");
		EE.removeListener("clean/toastr");
		EE.removeListener("removeByType/toastr");
		EE.removeListener("remove/toastr");
		this.toastrFired = {};
	}

	_addToMemory(id) {
		this.toastrFired[id] = true;
	}

	_renderToastrForPosition() {
		const { toastrs } = this.props.toastr;

		if (toastrs) {
			return toastrs.map((item) => {
				const mergedItem = {
					...item,
					options: {
						closeOnToastrClick: this.props.closeOnToastrClick,
						...item.options,
					},
				};

				return (
					<ToastrBox
						key={item.id}
						inMemory={this.toastrFired}
						addToMemory={() => this._addToMemory(item.id)}
						item={mergedItem}
						{...this.props}
					/>
				);
			});
		}
	}

	render() {
		const { className } = this.props;
		return ReactDOM.createPortal(
			<div className={cn("toast-box", className)} aria-live="assertive">
				{this._renderToastrForPosition()}
			</div>,
			toastrDom,
		);
	}
}

export default connect(
	(state) => ({
		toastr: state.toastr ? state.toastr : state.get("toastr"),
	}),
	actions,
)(ReduxToastr);
