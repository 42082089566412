import { WRAP_CLASSNAME } from "../actions/page.action";

const INITIAL_STATE = {
	bsWrapClassName: "",
};

export default function pageReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case WRAP_CLASSNAME:
			return {
				...state,
				bsWrapClassName: action.bsWrapClassName,
			};
		default:
			return state;
	}
}
