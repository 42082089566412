import qs from "qs";
import i18next from "i18next";
import axios from "libs/axios";
import querystring from "querystring";
import cookie from "react-cookies";
import { toastr } from "../components/toastr";
import { DELETE, POST, PUT } from "../constant/method";
import { handleFailedToFetch, handleFetchErrors } from "../helpers";
import { BJ_API_DOMAIN } from "constant/config";

//댓글리스트
export const FETCH_COMMENTS = "FETCH_COMMENTS";
export const FETCH_COMMENTS_SUCCESS = "FETCH_COMMENTS_SUCCESS";
export const FETCH_COMMENTS_MORE_SUCCESS = "FETCH_COMMENTS_MORE_SUCCESS";
export const FETCH_COMMENTS_PREVIOUS_MORE_SUCCESS = "FETCH_COMMENTS_PREVIOUS_MORE_SUCCESS";

export const FETCH_COMMENTS_FAILURE = "FETCH_COMMENTS_FAILURE";

export const DELETE_COMMENT = "DELETE_COMMENT";
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";
export const DELETE_COMMENT_FAILURE = "DELETE_COMMENT_FAILURE";

//댓글쓰기 완료
export const STORE_COMMENT_SUCCESS = "STORE_COMMENT_SUCCESS";
//댓글수정 완료
export const UPDATE_COMMENT_SUCCESS = "UPDATE_COMMENT_SUCCESS";

export const COMMENT_HIDDEN = "COMMENT_HIDDEN";
export const COMMENT_HIDDEN_CLEAR = "COMMENT_HIDDEN_CLEAR";

export const REPLY_HIDDEN = "REPLY_HIDDEN";
export const REPLY_HIDDEN_CLEAR = "REPLY_HIDDEN_CLEAR";

let commentLoading = false;

export function fetchComments({ title_no, page, orderBy, more = false, p_comment_no, c_comment_no, p_highlight_no, c_highlight_no }) {
    return (dispatch) => {
        if (commentLoading) {
            return;
        }

        commentLoading = true;
        if (!more) {
            dispatch({ type: FETCH_COMMENTS });
        }

        //로딩
        axios
            .get(`${BJ_API_DOMAIN}/${global.bj_id}/title/${title_no}/comment`, {
                params: {
                    page,
                    orderby: orderBy,
                    p_comment_no,
                    c_comment_no,
                    p_highlight_no: p_highlight_no || '',
                    c_highlight_no: c_highlight_no || ''
                },
            })
            .then((response) => {
                commentLoading = false;
                let type = 0;
                if (more == 0) {
                    type = FETCH_COMMENTS_SUCCESS;
                } else if (more === 1) {
                    type = FETCH_COMMENTS_MORE_SUCCESS;
                } else if (more === -1) {
                    type = FETCH_COMMENTS_PREVIOUS_MORE_SUCCESS;
                }

                dispatch({
                    type,
                    payload: response,
                });
            })
            .catch((error) => {
                commentLoading = false;
                dispatch({
                    type: FETCH_COMMENTS_FAILURE,
                    payload: error ? error : { code: 0, message: error },
                });
            });
    };
}

export function storeComment(props, title_no) {
    return fetch(`${BJ_API_DOMAIN}/${global.bj_id}/title/${title_no}/comment`, {
        method: POST,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: querystring.stringify({ ...props, _token: cookie.load("XSRF-TOKEN") }),
        credentials: "include",
    });
}
export function updateComment(props, title_no, p_comment_no) {
    return fetch(`${BJ_API_DOMAIN}/${global.bj_id}/title/${title_no}/comment/${p_comment_no}`, {
        method: POST,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: querystring.stringify({ ...props, _token: cookie.load("XSRF-TOKEN"), _method: PUT }),
        credentials: "include",
    });
}

export function deleteComment(title_no, p_comment_no) {
    return (dispatch) => {
        dispatch({ type: DELETE_COMMENT });

        //로딩
        fetch(`${BJ_API_DOMAIN}/${global.bj_id}/title/${title_no}/comment/${p_comment_no}`, {
            method: POST,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: querystring.stringify({ _token: cookie.load("XSRF-TOKEN"), _method: DELETE }),
            credentials: "include",
        })
            .then(handleFetchErrors, handleFailedToFetch)
            .then((response) => response.json())
            .then((response) => {
                dispatch({
                    type: DELETE_COMMENT_SUCCESS,
                    p_comment_no,
                    payload: response,
                });
            })
            .catch((error) => {
                dispatch({
                    type: DELETE_COMMENT_FAILURE,
                    p_comment_no,
                    payload: error ? error : { code: 0, message: error },
                });
            });
    };
}

//댓글리스트
export const FETCH_REPLY = "FETCH_REPLY";
export const FETCH_REPLY_SUCCESS = "FETCH_REPLY_SUCCESS";
export const FETCH_REPLY_FAILURE = "FETCH_REPLY_FAILURE";

export const DELETE_REPLY = "DELETE_REPLY";
export const DELETE_REPLY_SUCCESS = "DELETE_REPLY_SUCCESS";
export const DELETE_REPLY_FAILURE = "DELETE_REPLY_FAILURE";

//댓글쓰기 완료
export const STORE_REPLY_SUCCESS = "STORE_REPLY_SUCCESS";
//댓글수정 완료
export const UPDATE_REPLY = "UPDATE_REPLY";
export const UPDATE_REPLY_CANCEL = "UPDATE_REPLY_CANCEL";
export const UPDATE_REPLY_SUCCESS = "UPDATE_REPLY_SUCCESS";
/**
 * 답글 리스트
 * @param {*} title_no
 * @param {*} p_comment_no
 */
export function fetchCommentReplies(title_no, p_comment_no, c_highlight_no) {
    return (dispatch) => {
        dispatch({
            type: FETCH_REPLY,
            p_comment_no,
        });

        const szParameter = querystring.stringify({
            c_highlight_no: c_highlight_no || ''
        });

        //로딩
        fetch(`${BJ_API_DOMAIN}/${global.bj_id}/title/${title_no}/comment/${p_comment_no}/reply?${szParameter}`, {
            credentials: "include",
        })
            .then(handleFetchErrors, handleFailedToFetch)
            .then((response) => response.json())
            .then((response) => {
                dispatch({
                    type: FETCH_REPLY_SUCCESS,
                    p_comment_no,
                    payload: response,
                });
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_REPLY_FAILURE,
                    p_comment_no,
                    payload: error ? error : { code: 0, message: error },
                });
            });
    };
}

/**
 * 답글 저장
 * @param {*} props
 * @param {*} title_no
 */
export function storeReply(props, title_no, p_comment_no) {
    return fetch(`${BJ_API_DOMAIN}/${global.bj_id}/title/${title_no}/comment/${p_comment_no}/reply`, {
        method: POST,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: querystring.stringify({ ...props, _token: cookie.load("XSRF-TOKEN") }),
        credentials: "include",
    });
}
/**
 * 답글 수정
 * @param {*} props
 * @param {*} title_no
 */
export function updateReply(props, title_no, p_comment_no, c_comment_no) {
    return fetch(`${BJ_API_DOMAIN}/${global.bj_id}/title/${title_no}/comment/${p_comment_no}/reply/${c_comment_no}`, {
        method: POST,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: querystring.stringify({ ...props, _token: cookie.load("XSRF-TOKEN") }),
        credentials: "include",
    });
}

/**
 * 답글 삭제
 * @param {*} title_no
 * @param {*} p_comment_no
 * @param {*} c_comment_no
 */
export function deleteReply(title_no, p_comment_no, c_comment_no) {
    return (dispatch) => {
        dispatch({ type: DELETE_REPLY });

        //로딩
        fetch(`${BJ_API_DOMAIN}/${global.bj_id}/title/${title_no}/comment/${p_comment_no}/reply/${c_comment_no}`, {
            method: POST,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: querystring.stringify({ _token: cookie.load("XSRF-TOKEN"), _method: DELETE }),
            credentials: "include",
        })
            .then(handleFetchErrors, handleFailedToFetch)
            .then((response) => response.json())
            .then(() => {
                dispatch({
                    type: DELETE_REPLY_SUCCESS,
                    p_comment_no,
                    c_comment_no,
                });
            })
            .catch((error) => {
                dispatch({
                    type: DELETE_REPLY_FAILURE,
                    payload: error ? error : { code: 0, message: error },
                });
            });
    };
}

//게시글 좋아요 추가
export const STORE_COMMENT_LIKE = "STORE_COMMENT_LIKE";
export const STORE_COMMENT_LIKE_SUCCESS = "STORE_COMMENT_LIKE_SUCCESS";
//게시글 좋아요 삭제
export const DESTROY_COMMENT_LIKE = "DESTROY_COMMENT_LIKE";
export const DESTROY_COMMENT_LIKE_SUCCESS = "DESTROY_COMMENT_LIKE_SUCCESS";

/**
 * 댓글 UP 추가
 * @param {*} bj_id
 */
export function storeCommentLike(p_comment_no, board_type) {
    return (dispatch) => {
        dispatch({ type: STORE_COMMENT_LIKE });
        //로딩
        fetch(`${BJ_API_DOMAIN}/${global.bj_id}/comment/${p_comment_no}/like`, {
            method: POST,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: querystring.stringify({ board_type, _token: cookie.load("XSRF-TOKEN") }),
            credentials: "include",
        })
            .then(handleFetchErrors, handleFailedToFetch)
            .then((response) => response.json())
            .then((response) => {
                dispatch({
                    type: STORE_COMMENT_LIKE_SUCCESS,
                    payload: response,
                });
                toastr.success(response.message, {
                    timeOut: 2000,
                });
            })
            .catch((error) => {
                toastr.error(error.message, {
                    timeOut: 2000,
                });
            });
    };
}

/**
 * 댓글 UP 해제
 * @param {*} bj_id
 */
export function destroyCommentLike(p_comment_no, board_type) {
    return (dispatch) => {
        dispatch({ type: DESTROY_COMMENT_LIKE });

        //로딩
        fetch(`${BJ_API_DOMAIN}/${global.bj_id}/comment/${p_comment_no}/like/1`, {
            method: POST,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: querystring.stringify({ _method: DELETE, board_type, _token: cookie.load("XSRF-TOKEN") }),
            credentials: "include",
        })
            .then(handleFetchErrors, handleFailedToFetch)
            .then((response) => response.json())
            .then((response) => {
                dispatch({
                    type: DESTROY_COMMENT_LIKE_SUCCESS,
                    payload: response,
                });
                toastr.info(response.message, {
                    timeOut: 2000,
                });
            })
            .catch((error) => {
                toastr.error(error.message, {
                    timeOut: 2000,
                });
            });
    };
}

/**
 * 댓글 쓰기폼 초기화
 */
export const COMMENT_FORM_RESET = "COMMENT_FORM_RESET";

//댓글 고정 추가
export const STORE_COMMENT_PIN_SUCCESS = "STORE_COMMENT_PIN_SUCCESS";
//댓글 고정 삭제
export const DESTROY_COMMENT_PIN_SUCCESS = "DESTROY_COMMENT_PIN_SUCCESS";

/**
 * 댓글 고정 추가
 * @param {*} title_no
 * @param {*} p_comment_no
 */
export function storeCommentPin(title_no, p_comment_no, orderBy) {
    return (dispatch) => {
        //로딩
        fetch(`${BJ_API_DOMAIN}/${global.bj_id}/title/${title_no}/comment/${p_comment_no}/pin`, {
            method: POST,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: querystring.stringify({ _token: cookie.load("XSRF-TOKEN") }),
            credentials: "include",
        })
            .then(handleFetchErrors, handleFailedToFetch)
            .then((response) => response.json())
            .then((response) => {
                toastr.success(i18next.t("댓글이 고정되었습니다."), {
                    timeOut: 2000,
                });
                //댓글리스트 다시 불러오기
                if (response.result === 1) {
                    dispatch(fetchComments({ title_no, page: 1, orderBy }));
                }
            })
            .catch((error) => {
                toastr.error(error.message, {
                    timeOut: 2000,
                });
            });
    };
}

/**
 * 댓글 고정 해제
 * @param {*} title_no
 * @param {*} p_comment_no
 */
export function destroyCommentPin(title_no, p_comment_no, orderBy) {
    return (dispatch) => {
        //로딩
        fetch(`${BJ_API_DOMAIN}/${global.bj_id}/title/${title_no}/comment/${p_comment_no}/pin/1`, {
            method: POST,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: querystring.stringify({ _method: DELETE, _token: cookie.load("XSRF-TOKEN") }),
            credentials: "include",
        })
            .then(handleFetchErrors, handleFailedToFetch)
            .then((response) => response.json())
            .then((response) => {
                toastr.success(i18next.t("댓글 고정이 해제되었습니다."), {
                    timeOut: 2000,
                });
                //댓글리스트 다시 불러오기
                if (response.result === 1) {
                    dispatch(fetchComments({ title_no, page: 1, orderBy }));
                }
            })
            .catch((error) => {
                toastr.error(error.message, {
                    timeOut: 2000,
                });
            });
    };
}



/**
 * 댓글 숨기기 처리
 * @param titleNo
 * @param p_comment_no
 * @returns {function(...[*]=)}
 */
 export const actionCommentHidden = (titleNo, commentNo, writerId, actionType) => async (dispatch) => {

    const aActionTypes = ['blind', 'clear'];
    
    // 댓글 숨김 처리 (blind), 해제 처리 (clear)
    if (!aActionTypes.includes(actionType.toLowerCase())) return ;
    const actions = actionType.toLowerCase();

	try {
		const response = await axios.post(
			`/${global.bj_id}/title/${titleNo}/comment/${actions}`,
			qs.stringify({ comment_no: commentNo, writer_id: writerId}),
		);

        if (actions ===  'blind') {
            dispatch({
                type: COMMENT_HIDDEN,
                payload: commentNo,
            });
        } else {
            dispatch({
                type: COMMENT_HIDDEN_CLEAR,
                payload: commentNo,
            });
        }

		/*
		* toastr.success(response.message, {
			timeOut: 2000,
		});*/
	} catch (error) {
		/*
		*toastr.error(error.message, {
			timeOut: 2000,
		});*/
	}
};



/**
 * 댓글 숨기기 처리
 * @param titleNo
 * @param p_comment_no
 * @returns {function(...[*]=)}
 */
 export const actionReplyHidden = (titleNo, pCommentNo, cCommentNo, writerId, actionType) => async (dispatch) => {

    const aActionTypes = ['blind', 'clear'];
    
    // 댓글 숨김 처리 (blind), 해제 처리 (clear)
    if (!aActionTypes.includes(actionType.toLowerCase())) return ;
    const actions = actionType.toLowerCase();

	try {
		const response = await axios.post(
			`/${global.bj_id}/title/${titleNo}/comment/${pCommentNo}/reply/${actions}`,
			qs.stringify({ p_comment_no: pCommentNo, c_comment_no:cCommentNo,  writer_id: writerId}),
		);

        if (actions ===  'blind') {
            dispatch({
                type: REPLY_HIDDEN,
                c_comment_no: cCommentNo,
                p_comment_no: pCommentNo,
            });
        } else {
            dispatch({
                type: REPLY_HIDDEN_CLEAR,
                c_comment_no: cCommentNo,
                p_comment_no: pCommentNo,
            });
        }

		/*
		* toastr.success(response.message, {
			timeOut: 2000,
		});*/
	} catch (error) {
		/*
		* toastr.error(error.message, {
			timeOut: 2000,
		});*/
	}
};