import { toastr } from "../components/toastr";
import querystring from "querystring";
import cookie from "react-cookies";
import { BJ_API_DOMAIN } from "constant/config";
import { handleFetchErrors, handleFailedToFetch } from "../helpers";
import { POST, PUT } from "../constant/method";

export const FETCH_SEARCH_PROFILE = "FETCH_SEARCH_PROFILE";
export const FETCH_SEARCH_PROFILE_SUCCESS = "FETCH_SEARCH_PROFILE_SUCCESS";
export const FETCH_SEARCH_PROFILE_FAILURE = "FETCH_SEARCH_PROFILE_FAILURE";

export const FETCH_STATION_TITLE_SUCCESS = "FETCH_STATION_TITLE_SUCCESS";
export const UPDATE_STATION_TITLE_SUCCESS = "UPDATE_STATION_TITLE_SUCCESS";

export function fetchSearchProfile() {
	return (dispatch) => {
		const url = `${BJ_API_DOMAIN}/${global.bj_id}/setting/searchprofile`;
		dispatch({ type: FETCH_SEARCH_PROFILE });
		fetch(url, {
			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then((response) => {
				dispatch({
					type: FETCH_SEARCH_PROFILE_SUCCESS,
					payload: response,
				});
			})
			.catch((error) => {
				dispatch({
					type: FETCH_SEARCH_PROFILE_FAILURE,
					payload: error ? error : { code: 0, message: error },
				});
			});
	};
}

export function fetchStationTitle() {
	return (dispatch) => {
		const url = `${BJ_API_DOMAIN}/${global.bj_id}/setting/station/title`;
		fetch(url, {
			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then((response) => {
				dispatch({
					type: FETCH_STATION_TITLE_SUCCESS,
					payload: response,
				});
			})
			.catch((error) => {
				dispatch({
					type: FETCH_SEARCH_PROFILE_FAILURE,
					payload: error ? error : { code: 0, message: error },
				});
			});
	};
}

export function updateStationTitle(title) {
	return (dispatch) => {
		const url = `${BJ_API_DOMAIN}/${global.bj_id}/setting/station/title/1`;

		fetch(url, {
			method: POST,
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: querystring.stringify({ _token: cookie.load("XSRF-TOKEN"), _method: PUT, title }),
			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then((response) => {
				toastr.success(response.message, {
					timeOut: 2000,
				});
			})
			.catch((error) => {
				toastr.error(error.message, {
					timeOut: 2000,
				});
			});
	};
}
