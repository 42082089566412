import i18next from "i18next";

export const MAIN_STATION = "MAIN";
export const SEARCH_MENU = "SEARCH_MENU";
export const SEARCH_STATION = "SEARCH_STATION";

export const TITLE_TAIL_MAIN_STATION = () => i18next.t("SOOP");
export const TITLE_TAIL_SEARCH_MENU = () => i18next.t("SOOP 채널 메뉴 검색");
export const TITLE_TAIL_SEARCH_STATION = () => i18next.t("SOOP 채널 검색");
export const TITLE_TAIL_PLAIN = () => i18next.t("SOOP 채널");
