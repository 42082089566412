export const WYSIWYG_MODE = "wysiwyg";
export const SOURCE_MODE = "source";
export const EDITTOR_SET_DATA = "EDITTOR_SET_DATA";
export const EDITTOR_INSERT_HTML = "EDITTOR_INSERT_HTML";
export const EDITTOR_REMOVE_IMAGE_TAG = "EDITTOR_REMOVE_IMAGE_TAG";
export const EDITTOR_COMMAND = "EDITTOR_COMMAND";

export function setData(data) {
	return {
		type: EDITTOR_SET_DATA,
		payload: data,
	};
}
export function editorInsertHtml(tag) {
	return {
		type: EDITTOR_INSERT_HTML,
		payload: tag,
	};
}

export function editorRemoveImageTag(filename) {
	return {
		type: EDITTOR_REMOVE_IMAGE_TAG,
		payload: filename,
	};
}

export function editorCommand(command) {
	return {
		type: EDITTOR_COMMAND,
		payload: command,
	};
}
