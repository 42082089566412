import querystring from "querystring";
import i18next from "i18next";
import { BJ_API_DOMAIN } from "constant/config";
import cookie from "react-cookies";
import { POST, DELETE, PUT, GET } from "../constant/method";
import { handleFetchErrors, handleFailedToFetch, goLogin } from "../helpers";
import { toastr } from "../components/toastr";
import store from "../store";
import axios from "libs/axios";

export const REACT_POPUP_OPEN = "REACT_POPUP_OPEN";

//Station Info
export const FETCH_STATION = "FETCH_STATION";
export const FETCH_STATION_SUCCESS = "FETCH_STATION_SUCCESS";
export const FETCH_STATION_FAILURE = "FETCH_STATION_FAILURE";

//BJ Info
export const FETCH_BJ = "FETCH_BJ";
export const FETCH_BJ_SUCCESS = "FETCH_BJ_SUCCESS";
export const FETCH_BJ_FAILURE = "FETCH_BJ_FAILURE";

//즐겨찾기리스트
export const FETCH_FAVORITE = "FETCH_FAVORITE";
export const FETCH_FAVORITE_SUCCESS = "FETCH_FAVORITE_SUCCESS";
export const FETCH_FAVORITE_FAILURE = "FETCH_FAVORITE_FAILURE";
//즐겨찾기 추가
export const STORE_FAVORITE_SUCCESS = "STORE_FAVORITE_SUCCESS";
//즐겨찾기 삭제
export const DESTROY_FAVORITE_SUCCESS = "DESTROY_FAVORITE_SUCCESS";

//랭킹
export const FETCH_DETAIL = "FETCH_DETAIL";
export const FETCH_DETAIL_SUCCESS = "FETCH_DETAIL_SUCCESS";
export const FETCH_DETAIL_FAILURE = "FETCH_DETAIL_FAILURE";

export const FETCH_NOTICE_SUCCESS = "FETCH_NOTICE_SUCCESS";

//즐겨찾기 푸시추가
export const STORE_FAVORITE_PUSH_SUCCESS = "STORE_FAVORITE_PUSH_SUCCESS";
//즐겨찾기 푸시삭제
export const DESTROY_FAVORITE_PUSH_SUCCESS = "DESTROY_FAVORITE_PUSH_SUCCESS";

/**
 * 채널 정보
 */
export function fetchStationInfo() {
    return async (dispatch) => {
        dispatch({ type: FETCH_STATION });

        try {
            const response = await axios.get(`/${global.bj_id}/station`);

            dispatch({
                type: FETCH_STATION_SUCCESS,
                payload: response,
            });
        } catch (error) {
            dispatch({
                type: FETCH_STATION_FAILURE,
                payload: error ? error : { code: 0, message: error },
            });
        }
    };
}

/**
 * 채널 BJ정보
 * @param {*} bj_id
 */
export function fetcBjInfo() {
    return (dispatch) => {
        //로딩
        fetch(`${BJ_API_DOMAIN}/${global.bj_id}/station/bj`, {
            credentials: "include",
        })
            .then(handleFetchErrors, handleFailedToFetch)
            .then((response) => response.json())
            .then((response) => {
                dispatch({
                    type: FETCH_BJ_SUCCESS,
                    payload: response,
                });
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_BJ_FAILURE,
                    payload: error ? error : { code: 0, message: error },
                });
            });
    };
}
/**
 * 채널 공지사항
 * @param {*} bj_id
 */
export function fetchNotice() {
    return (dispatch) => {
        //로딩
        fetch(`${BJ_API_DOMAIN}/${global.bj_id}/station/notice`, {
            credentials: "include",
        })
            .then(handleFetchErrors, handleFailedToFetch)
            .then((response) => response.json())
            .then((response) => {
                dispatch({
                    type: FETCH_NOTICE_SUCCESS,
                    payload: response,
                });
            })
            .catch((error) => {});
    };
}

/**
 * 즐겨찾기 추가
 * @param {*} bj_id
 */
export function fetchFavorites() {
    return (dispatch) => {
        dispatch({
            type: FETCH_FAVORITE,
        });
        //로딩
        fetch(`${BJ_API_DOMAIN}/favorite`, {
            credentials: "include",
        })
            .then(handleFetchErrors, handleFailedToFetch)
            .then((response) => response.json())
            .then((response) => {
                dispatch({
                    type: FETCH_FAVORITE_SUCCESS,
                    payload: response,
                });
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_FAVORITE_FAILURE,
                    payload: error ? error : { code: 0, message: error },
                });
            });
    };
}

/**
 * 즐겨찾기 추가
 * @param {*} bj_id
 */
export function storeFavorite(bj_id) {
    return (dispatch) => {
        let data;
        if (bj_id) {
            data = querystring.stringify({ bj_id, _token: cookie.load("XSRF-TOKEN") });
        } else {
            data = querystring.stringify({ bj_id: global.bj_id, _token: cookie.load("XSRF-TOKEN") });
        }
        //로딩
        fetch(`${BJ_API_DOMAIN}/favorite`, {
            method: POST,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: data,
            credentials: "include",
        })
            .then(handleFetchErrors, handleFailedToFetch)
            .then((response) => response.json())
            .then((response) => {
                dispatch({
                    type: STORE_FAVORITE_SUCCESS,
                    payload: response,
                });
                toastr.success(response.message);
            })
            .catch((error) => {
                if (error.code === 6301) {
                    dispatch({
                        type: STORE_FAVORITE_SUCCESS,
                        payload: "",
                    });
                }
                toastr.error(error.message, {
                    timeOut: 2000,
                });
            });
    };
}

/**
 * 즐겨찾기 삭제
 * @param {*} bj_id
 */
export function destroyFavorite(bj_id = null) {
    return (dispatch) => {
        if (!bj_id) {
            bj_id = global.bj_id;
        }

        if (window.confirm(i18next.t("해당 스트리머를 즐겨찾기에서 삭제하시겠습니까?"))) {
            //로딩
            fetch(`${BJ_API_DOMAIN}/favorite/${bj_id}`, {
                method: POST,
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: querystring.stringify({ _method: DELETE, _token: cookie.load("XSRF-TOKEN") }),
                credentials: "include",
            })
                .then(handleFetchErrors, handleFailedToFetch)
                .then((response) => response.json())
                .then((response) => {
                    dispatch({
                        type: DESTROY_FAVORITE_SUCCESS,
                        payload: response,
                    });
                    toastr.info(response.message);
                })
                .catch((error) => {
                    toastr.error(error.message, {
                        timeOut: 2000,
                    });
                });
        }

        
    };
}

/**
 * 일반 유저 즐겨차기
 * @param {*} bj_id
 */
export function userFavorite(bj_id, callback) {
    const { is_login } = store.getState().authenticationReducer;
    if (is_login) {
        //로딩
        fetch(`${BJ_API_DOMAIN}/favorite`, {
            method: POST,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: querystring.stringify({ bj_id, _token: cookie.load("XSRF-TOKEN") }),
            credentials: "include",
        })
            .then(handleFetchErrors, handleFailedToFetch)
            .then((response) => response.json())
            .then((response) => {
                toastr.success(response.message);
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                toastr.error(error.message, {
                    timeOut: 2000,
                });
            });
    } else {
        if (window.confirm(i18next.t("로그인이 필요합니다. 로그인 하시겠습니까?"))) {
            goLogin();
        }
    }
}

/**
 * 즐겨찾기 여부
 * @param {*} user_id
 */
export function showFavorite(user_id, callback) {
    const { is_login } = store.getState().authenticationReducer;
    if (is_login) {
        //로딩
        fetch(`${BJ_API_DOMAIN}/favorite/${user_id}`, {
            method: GET,
            credentials: "include",
        })
            .then(handleFetchErrors, handleFailedToFetch)
            .then((response) => response.json())
            .then((response) => {
                if (callback) {
                    callback(response);
                }
            })
            .catch((error) => {
                if (callback) {
                    callback({ is_favorite: false });
                }
            });
    }
}

/**
 * 랭킹정보
 */
export function fetchDetail() {
    return (dispatch) => {
        dispatch({ type: FETCH_DETAIL });
        //로딩
        fetch(`${BJ_API_DOMAIN}/${global.bj_id}/station/detail`, {
            credentials: "include",
        })
            .then(handleFetchErrors, handleFailedToFetch)
            .then((response) => response.json())
            .then((response) => {
                dispatch({
                    type: FETCH_DETAIL_SUCCESS,
                    payload: response,
                });
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_DETAIL_FAILURE,
                    payload: error ? error : { code: 0, message: error },
                });
            });
    };
}

export const PUT_PROFILE_TEXT_SUCCESS = "PUT_PROFILE_TEXT_SUCCESS";

export function updateProfileText(profile_text) {
    return (dispatch) => {
        //로딩
        fetch(`${BJ_API_DOMAIN}/${global.bj_id}/setting/profile/text`, {
            method: POST,

            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: querystring.stringify({ _method: PUT, profile_text }),
            credentials: "include",
        })
            .then(handleFetchErrors, handleFailedToFetch)
            .then((response) => response.json())
            .then((response) => {
                dispatch({
                    type: PUT_PROFILE_TEXT_SUCCESS,
                    payload: response,
                });
                toastr.success(response.message, {
                    timeOut: 2000,
                });
            })
            .catch((error) => {
                toastr.error(error.message, {
                    timeOut: 2000,
                });
            });
    };
}

/**
 * 즐겨찾기 푸시 SWITCH
 * @param {*} bj_id
 */
export function storeFavoritePush(bj_id = null) {
    return (dispatch) => {
        if (!bj_id) {
            bj_id = global.bj_id;
        }

        //로딩
        fetch(`${BJ_API_DOMAIN}/favorite/push`, {
            method: POST,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: querystring.stringify({ bj_id, _token: cookie.load("XSRF-TOKEN") }),
            credentials: "include",
        })
            .then(handleFetchErrors, handleFailedToFetch)
            .then((response) => response.json())
            .then((response) => {
                dispatch({
                    type: STORE_FAVORITE_PUSH_SUCCESS,
                    payload: response,
                });
                toastr.info(response.message);
            })
            .catch((error) => {
                toastr.error(error.message, {
                    timeOut: 2000,
                });
            });
    };
}

/**
 * 즐겨찾기 푸시 SWITCH
 * @param {*} bj_id
 */
export function destroyFavoritePush(bj_id = null) {
    return (dispatch) => {
        if (!bj_id) {
            bj_id = global.bj_id;
        }

        //로딩
        fetch(`${BJ_API_DOMAIN}/favorite/push/${bj_id}`, {
            method: POST,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: querystring.stringify({ bj_id, _method: DELETE, _token: cookie.load("XSRF-TOKEN") }),
            credentials: "include",
        })
            .then(handleFetchErrors, handleFailedToFetch)
            .then((response) => response.json())
            .then((response) => {
                dispatch({
                    type: DESTROY_FAVORITE_PUSH_SUCCESS,
                    payload: response,
                });
                toastr.info(response.message);
            })
            .catch((error) => {
                toastr.error(error.message, {
                    timeOut: 2000,
                });
            });
    };
}
