import querystring from "querystring";
import { BJ_API_DOMAIN } from "constant/config";
import { POST } from "../constant/method";
import { handleFetchErrors, handleFailedToFetch } from "../helpers";
import cookie from "react-cookies";

export const FETCH_REPORT_SUCCESS = "FETCH_REPORT_SUCCESS";
export const FETCH_REPORT_FAILURE = "FETCH_REPORT_FAILURE";

export function fetchReport(isReportStation = false) {
	return (dispatch) => {
		const szParameter = querystring.stringify({
			is_report_station: isReportStation
        });

		fetch(`${BJ_API_DOMAIN}/${global.bj_id}/report?${szParameter}`, {
			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then((response) => {
				dispatch({
					type: FETCH_REPORT_SUCCESS,
					payload: response,
				});
			})
			.catch((error) => {
				dispatch({
					type: FETCH_REPORT_FAILURE,
					payload: error ? error : { code: 0, message: error.message },
				});
			});
	};
}

export function storeReport(props) {
	props._token = cookie.load("XSRF-TOKEN");
	return fetch(`${BJ_API_DOMAIN}/${global.bj_id}/report`, {
		method: POST,
		headers: { "Content-Type": "application/x-www-form-urlencoded" },
		body: querystring.stringify(props),
		credentials: "include",
	});
}
