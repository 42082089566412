import React from "react";
import { findDOMNode } from "react-dom";
import { connect } from "react-redux";
import { wrapClassName } from "../../actions/page.action";
import { REACT_POPUP_OPEN } from "../../actions/station.action";

class ReactPopup extends React.PureComponent {
	static defaultProps = {
		children: () => <span>Children</span>,
		trigger: null,
		onOpen: () => {},
		onClose: () => {},
		defaultOpen: false,
		open: false,
		closeOnDocumentClick: true,
		closeOnEscape: true,
		on: ["click"],
		overlayStyle: {},
		className: "",
		coverClassName: false,
		offsetX: 0,
		offsetY: 0,
		mouseEnterDelay: 100,
		mouseLeaveDelay: 100,
		stopPropagation: false,
	};

	constructor(props) {
		super(props);
		this.setTriggerRef = (r) => (this.TriggerEl = r);
		this.timeOut = 0;
		this.state = {
			isOpen: this.props.open || this.props.defaultOpen,
		};
		this.id = Math.random();
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.reactPopupId !== nextProps.reactPopupId && this.id !== nextProps.reactPopupId) {
			this.closePopup();
		}
		if (this.props.open === nextProps.open) return;
		if (nextProps.open) this.openPopup();
		else this.closePopup();
	}
	componentWillUnmount() {
		clearTimeout(this.timeOut);
	}
	togglePopup = (event) => {

		if(this.props.onTriggerClick){
			this.props.onTriggerClick();
		}
		if (this.state.isOpen) {
			this.closePopup();
		} else {
			event.stopPropagation();
			// event.nativeEvent.stopImmediatePropagation();
			this.openPopup();
		}
	};
	openPopup = () => {
		if (this.state.isOpen) return;
		if (!this.props.stopPropagation) {
			this.props.reactPopup(this.id);
		}
		this.setState({ isOpen: true }, () => {
			this.props.onOpen();
		});
	};
	closePopup = () => {
		this.props.onClose();
		if (!this.state.isOpen) return;
		this.setState({ isOpen: false });
	};
	onMouseEnter = () => {
		clearTimeout(this.timeOut);
		const { mouseEnterDelay } = this.props;
		this.timeOut = setTimeout(() => this.openPopup(), mouseEnterDelay);
	};
	onMouseLeave = () => {
		clearTimeout(this.timeOut);
		const { mouseLeaveDelay } = this.props;
		this.timeOut = setTimeout(() => this.closePopup(), mouseLeaveDelay);
	};

	renderTrigger = () => {
		const triggerProps = { key: "T" };
		const { on, trigger } = this.props;
		const onAsArray = Array.isArray(on) ? on : [on];
		for (let i = 0, len = onAsArray.length; i < len; i++) {
			switch (onAsArray[i]) {
				case "click":
				default:
					triggerProps.onClick = this.togglePopup;
					break;
			}
		}

		if (typeof trigger === "function") {
			return React.cloneElement(trigger(this.state.isOpen), triggerProps);
		} else if (trigger.length > 1) {
			return React.Children.map(trigger, (child, index) => React.cloneElement(child, { ...triggerProps, key: index }));
		} else {
			return React.cloneElement(trigger, triggerProps);
		}
	};

	renderContent = () => {
		return typeof this.props.children === "function" ? this.props.children(this.closePopup, this.state.isOpen) : this.props.children;
	};

	render() {
		return [
			!!this.props.trigger && (
				<Ref innerRef={this.setTriggerRef} key="R">
					{this.renderTrigger()}
				</Ref>
			),
			this.state.isOpen && this.renderContent(),
		];
	}
}

if (process.env.NODE_ENV !== "production") {
	const PropTypes = require("prop-types");
	const TRIGGER_TYPES = ["hover", "click", "focus"];

	ReactPopup.propTypes = {
		overlayStyle: PropTypes.object,
		className: PropTypes.string,
		coverClassName: PropTypes.bool,
		closeOnDocumentClick: PropTypes.bool,
		offsetX: PropTypes.number,
		offsetY: PropTypes.number,
		mouseEnterDelay: PropTypes.number,
		mouseLeaveDelay: PropTypes.number,
		onOpen: PropTypes.func,
		onClose: PropTypes.func,
		open: PropTypes.bool,
		defaultOpen: PropTypes.bool,
		trigger: PropTypes.oneOfType([PropTypes.func, PropTypes.element, PropTypes.node]), // for uncontrolled component we don't need the trigger Element
		on: PropTypes.oneOfType([PropTypes.oneOf(TRIGGER_TYPES), PropTypes.arrayOf(PropTypes.oneOf(TRIGGER_TYPES))]),
		children: PropTypes.oneOfType([PropTypes.func, PropTypes.element, PropTypes.string]).isRequired,
	};
}

const mapStateToProps = (state) => {
	return { reactPopupId: state.stationReducer.reactPopupId };
};

const mapDispatchToProps = (dispatch) => {
	return {
		wrapClassName: (classname) => {
			dispatch(wrapClassName(classname));
		},
		reactPopup: (id) => {
			dispatch({
				type: REACT_POPUP_OPEN,
				id,
			});
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ReactPopup);

class Ref extends React.PureComponent {
	componentDidMount() {
		const { innerRef } = this.props;
		if (innerRef) innerRef(findDOMNode(this));
	}
	render() {
		const { children } = this.props;
		if (React.Children.count(children) > 1) {
			return React.Children.map(children, (child, index) => React.cloneElement(child, { key: index }));
		} else {
			return React.Children.only(children);
		}
	}
}
