import querystring from "querystring";
import cookie from "react-cookies";
import i18next from "i18next";
import { FORM_CONTENT_TYPE } from "./config";
import { BJ_API_DOMAIN } from "constant/config";
import { POST, PUT } from "../constant/method";
import { toastr } from "../components/toastr";
import { handleFetchErrors, handleFailedToFetch } from "../helpers";

export const FETCH_MEDAL = "FETCH_MEDAL";
export const FETCH_MEDAL_SUCCESS = "FETCH_MEDAL_SUCCESS";
export const FETCH_MEDAL_FAILURE = "FETCH_MEDAL_FAILURE";

export const UPDATE_MEDAL = "UPDATE_MEDAL";
export const UPDATE_MEDAL_SUCCESS = "UPDATE_MEDAL_SUCCESS";
export const UPDATE_MEDAL_FAILURE = "UPDATE_MEDAL_FAILURE";

export function fetchMedal() {
	return (dispatch) => {
		dispatch({ type: FETCH_MEDAL });
		fetch(`${BJ_API_DOMAIN}/${global.bj_id}/setting/medal`, {
			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then((response) => {
				dispatch({
					type: FETCH_MEDAL_SUCCESS,
					payload: response,
				});
			})
			.catch((error) => {
				dispatch({
					type: FETCH_MEDAL_FAILURE,
					payload: error ? error : { code: 0, message: error.message },
				});
			});
	};
}

export function updateMedal(medal_order) {
	return (dispatch) => {
		dispatch({ type: UPDATE_MEDAL });
		fetch(`${BJ_API_DOMAIN}/${global.bj_id}/setting/medal/1`, {
			method: POST,
			headers: FORM_CONTENT_TYPE,
			body: querystring.stringify({ medal_order: medal_order, _method: PUT, _token: cookie.load("XSRF-TOKEN") }),
			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then((response) => {
				toastr.success(i18next.t("저장 되었습니다."), {
					timeOut: 2000,
				});
				dispatch({
					type: UPDATE_MEDAL_SUCCESS,
					payload: response,
				});
			})
			.catch((error) => {
				toastr.error(error.message, {
					timeOut: 2000,
				});
				dispatch({
					type: UPDATE_MEDAL_FAILURE,
					payload: error ? error : { code: 0, message: error.message },
				});
			});
	};
}
