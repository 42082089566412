import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import cookie from "react-cookies";

import { REMOVE_COOKIE_ITEM, SET_COOKIE_ITEM } from "actions/use.cookie.action";

const useCookie = (key, initialValue = null) => {
	const dispatch = useDispatch();

	// 쿠키 값
	const value = useSelector(({ useCookieReducer }) => (useCookieReducer[key] !== undefined ? useCookieReducer[key] : initialValue));

	// 쿠키 세팅
	const handleSetValue = useCallback(
		(value = null, options = []) => {
			cookie.save(key, value, options);
			dispatch({
				type: SET_COOKIE_ITEM,
				payload: { key, value },
			});
		},
		[dispatch, key],
	);

	// 쿠키 제거
	const handleRemoveValue = useCallback(
		(options = []) => {
			cookie.remove(key, options);
			dispatch({
				type: REMOVE_COOKIE_ITEM,
				payload: { key },
			});
		},
		[dispatch, key],
	);

	// 쿠키 등록
	useEffect(() => {
		const value = cookie.load(key);
		if (value !== undefined && value !== null) {
			dispatch({
				type: SET_COOKIE_ITEM,
				payload: { key, value },
			});
		}
	}, [dispatch, key]);

	return [value, handleSetValue, handleRemoveValue];
};

export default useCookie;
