import { hostGenerator, scheme } from "@afreecatv/domains"

console.log("env: ", process.env);

const nodeEnv = process.env.BUILD_ENV;

export const HOST = {
	...hostGenerator({
	  environment: nodeEnv || 'production',
	  currentProtocol: document.location.protocol,
	  hostname: document.location.hostname
	})
  };

export const AFREECATV_DOMAIN = HOST.AFREECATV_ROOT_DOMAIN
export const DOMAIN = HOST.DOMAIN;
export const DOMAIN_URL = HOST.WWW_AFREECATV;

export const MOBILE_DOMAIN = HOST.M_AFREECATV;
export const MOBILE_AUTH_DOMAIN = HOST.AUTH_M_AFREECATV;

export const IMG_DOMAIN = HOST.STIMG_AFREECATV;
export const PROFILE_IMG_DOMAIN = HOST.PROFILE_IMG_AFREECATV;
export const OLD_STATION_DOMAIN = HOST.ST_AFREECATV;
export const OLD_LIVE_DOMAIN = HOST.LIVE_AFREECATV;

export const LOGIN_DOMAIN = HOST.LOGIN_AFREECATV;
export const LOGOUT_URL = HOST.LOGIN_AFREECATV+"/app/LogOut.php";

export const RES_DOMAIN = HOST.RES_AFREECATV;
// VOD_SHARE_URL v.afree.ca/ST 제거
export const VOD_PLAYER_DOMAIN = HOST.VOD_AFREECATV+"/player";
export const VOD_DOMAIN = HOST.VOD_AFREECATV;
export const VOD_EDITOR_DOMAIN = HOST.VEDITOR_AFREECATV;
export const NOTE_DOMAIN =  HOST.NOTE_AFREECATV;
export const STATION_DOMAIN =  HOST.BJ_AFREECATV;

// export const PROFILE_DEFUALT_IMG_URL =  HOST."//res-cf.afreecatv.com/mybs/img/default_small_re.gif";

export const ITEM_MOBILE_DOMAIN =  HOST.ITEM_M_AFREECATV;

//PC URL
export const BJ_DOMAIN = HOST.BJ_AFREECATV;
export const MY_DOMAIN =  HOST.MY_AFREECATV;
export const USER_INFO_DOMAIN =  HOST.MEMBER_AFREECATV+"/app/user_info.php";
// export const SUBSCRIPTION_URL =  HOST."https://point.afreecatv.com/Subscription/SubscriptionList.asp HOST.";
export const ITEM_DOMAIN =  HOST.ITEM_AFREECATV;
export const BROARD_STATISCS_DOMAIN =  HOST.BROADSTATISTIC_AFREECATV;
export const BEST_BJ_DOMAIN =  HOST.BESTBJ_AFREECATV;
export const PARTNER_BJ_DOMAIN =  HOST.PARTNERBJ_AFREECATV;
export const SUPPORTERS_DOMAIN =  HOST.SUPPORTERS_AFREECATV;
export const MY_REVENUE_DOMAIN =  HOST.MYREVENUE_AFREECATV;
export const ADBALLOON_DOMAIN =  HOST.ADBALLOON_AFREECATV;
export const ESPORTS_DOMAIN =  HOST.GAMETV_AFREECATV;
// export const EVENT_DOMAIN =  HOST."//afevent.afreecatv.com HOST.";
export const EVENT2_DOMAIN =  HOST.AFEVENT2_AFREECATV;
export const DASHBOARD_DOMAIN =  HOST.DASHBOARD_AFREECATV;
export const POINT_DOMAIN =  HOST.POINT_AFREECATV;
export const MY_POINT_DOMAIN =  HOST.MYPOINT_AFREECATV+"/index.php"
export const SOTONG_DOMAIN =  HOST.SOTONG_AFREECATV;

export const LIVEIMG_DOMAIN =  HOST.LIVEIMG_AFREECATV;
export const SCKETC_DOMAIN =  HOST.SCKETC_AFREECATV;
export const JAVASCRIPT_VOID =  "javascript:void(0)";

export const VOTE_DOMAIN =  HOST.VOTE_AFREECATV;
export const VOTE_PROTOCAL_DOMAIN = (() => {
    const url = new URL(window.location.href);
    return url.protocol + "//vote." + AFREECATV_DOMAIN;
})();

export const STBBS_DOMAIN =  HOST.STBBS_AFREECATV;


export const SCH_DOMAIN =  HOST.SCH_AFREECATV;

export const ISSUE_DOMAIN =  HOST.ISSUE_AFREECATV;
export const PLAY_DOMAIN =  HOST.PLAY_AFREECATV;

export const EVENT_API_DOMAIN =  HOST.EVENTAPI_AFREECATV;


export const BJ_API_DOMAIN = HOST.BJAPI_AFREECATV+"/api";

export const OGQ_MARKET_DOMAIN =  HOST.OGQ_MARKET_AFREECATV;

export const STATIC_FILE_DOMAIN =  HOST.STATIC_FILE_AFREECATV;
export const STATIC_DOMAIN =  HOST.STATIC_AFREECATV;

export const SHOPFREECA_DOMAIN =  HOST.SHOPFREECA_AFREECATV;
export const HELP_DOMAIN =  HOST.HELP_AFREECATV+"/atv.php";

export const ANALYSIS_DOMAIN =  HOST.ANALYSIS_AFREECATV;

export const CINETY_DOMAIN =  HOST.CINETY_AFREECATV;

// GPT- PA광고 서버 도메인
export const PA_DOMAIN =  HOST.PA_AFREECATV;

// 광고형식 조회 도메인
export const DEAPI_DOMAIN =  ['development', 'staging'].includes(nodeEnv) ? HOST.DEAPIDEV_AD_AFREECATV  : HOST.DEAPI_AD_AFREECATV;
export const LINK_DOMAIN =  HOST.LINK_AFREECATV;

export const MEMBER_DOMAIN =  HOST.MEMBER_AFREECATV;
export const ADREVENUE_DOMAIN =  HOST.ADREVENUE_AFREECATV;

export const AQUA_DOMAIN =  HOST.AQUA_AFREECATV;
export const NEW_BJ_DOMAIN =  HOST.NEWBJ_AFREECATV;

export const APP_SCHEME = scheme.SERVICE_APP_SCHEME;