import { BJ_API_DOMAIN } from "constant/config";
import { handleFetchErrors, handleFailedToFetch } from "../helpers";

export const FETCH_CLIP_FILTER = "FETCH_CLIP_FILTER";
export const FETCH_CLIP_FILTER_SUCCESS = "FETCH_CLIP_FILTER_SUCCESS";
export const FETCH_CLIP_FILTER_FAILURE = "FETCH_CLIP_FILTER_FAILURE";

export function fetchClipFilterList(type = "") {
	return (dispatch) => {
		dispatch({ type: FETCH_CLIP_FILTER });

		fetch(`${BJ_API_DOMAIN}/${global.bj_id}/clip/${type}/filter`, {
			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then((response) => {
				dispatch({
					type: FETCH_CLIP_FILTER_SUCCESS,
					payload: response,
				});
			})
			.catch((error) => {
				dispatch({
					type: FETCH_CLIP_FILTER_FAILURE,
					payload: error ? error : { code: 0, message: error },
				});
			});
	};
}
