import produce from "immer";

import { CLEAR_NEARBY_POST, FETCH_NEARBY_POST, FETCH_NEARBY_POST_FAILURE, FETCH_NEARBY_POST_SUCCESS } from "actions/post.nearby.action";

import { CLEAR, ERROR, LOADING, SUCCESS } from "constant/status";

const INITIAL_STATE = {
	status: CLEAR,
	data: null,
	error: null,
};

const postNearbyReducer = produce((state, { type, payload }) => {
	switch (type) {
		// 근처 게시글 로딩
		case FETCH_NEARBY_POST:
			state.status = LOADING;
			return;

		// 근처 게시글 로딩 완료
		case FETCH_NEARBY_POST_SUCCESS:
			state.status = SUCCESS;
			state.data = payload;
			return;

		// 근처 게시글 로딩 실패
		case FETCH_NEARBY_POST_FAILURE:
			state.status = ERROR;
			state.error = payload;
			return;

		// 근처 게시글 초기화
		case CLEAR_NEARBY_POST:
			return INITIAL_STATE;

		default:
	}
}, INITIAL_STATE);

export default postNearbyReducer;
