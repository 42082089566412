import React from "react";
import Header from "components/header/Header";

export const SubPageMobile = ({ children }) => {
    return (
        <>
            <Header />
            <div id="bs-container">
                <div id="bs-contents">
                    {children}
                </div>
            </div>
        </>
    );
};
