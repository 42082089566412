import { Map, List, fromJS } from "immutable";
import {
	FETCH_STATION_INFO,
	FETCH_STATION_INFO_SUCCESS,
	FETCH_STATION_INFO_FAILURE,
	CHECK_STATION_NAME_SUCCESS,
	CHECK_STATION_NAME_FAILURE,
	PUT_STATION_NAME_SUCCESS,
	PUT_PROFILE_IMAGE_SUCCESS,
	STORE_STATION_INFO_SUCCESS,
	CHECK_USER_NICK_SUCCESS,
	CHECK_USER_NICK_FAILURE,
	PUT_USER_NICK_SUCEESS,
	PUT_PROFILE_TEXT_SUCCESS,
	INIT_PROFILE_IMAGE_SUCCESS,
} from "actions/setting.station.action";
import {
	FETCH_BANWORD,
	FETCH_BANWORD_SUCCESS,
	FETCH_BANWORD_FAILURE,
	STORE_BANWORD_SUCCESS,
	DELETE_BANWORD_SUCCESS,
	UPDATE_BANWORD_STATUS_SUCCESS,
} from "actions/setting.banword.action";
import { FETCH_MEDAL, FETCH_MEDAL_FAILURE, FETCH_MEDAL_SUCCESS } from "actions/setting.medal.action";
import { FETCH_BADGE, FETCH_BADGE_FAILURE, FETCH_BADGE_SUCCESS } from "actions/setting.badge.action";

// 편집자
import {
	FETCH_VODEDITOR,
	FETCH_VODEDITOR_SUCCESS,
	FETCH_VODEDITOR_FAILURE,
	STORE_VODEDITOR_SUCCESS,
	DELETE_VODEDITOR_SUCCESS,
	SET_VODEDITOR_POST,
} from "actions/vodeditor.action";
// 구독팬
import { FETCH_SUBSCRIPT, FETCH_SUBSCRIPT_SUCCESS, FETCH_SUBSCRIPT_FAILURE } from "actions/subscript.action";
// 서포터
import { FETCH_SUPPORTER, FETCH_SUPPORTER_SUCCESS, FETCH_SUPPORTER_FAILURE } from "actions/supporter.action";
//팬클럽
import {
	FETCH_FANCLUB,
	FETCH_FANCLUB_SUCCESS,
	FETCH_FANCLUB_FAILURE,
	STORE_MANAGER_FAILURE,
	DESTROY_MANAGER_SUCCESS,
	DESTROY_MANAGER_FAILURE,
	UPDATE_NOTICE_FAILURE,
	UPDATE_NOTICE_SUCCESS,
	UPDATE_AUTO_MANAGER_SUCCESS,
	UPDATE_AUTO_MANAGER_FAILURE,
	FETCH_MANAGER,
	FETCH_MANAGER_SUCCESS,
	FETCH_MANAGER_FAILURE,
	FETCH_AUTO_MANAGER,
	FETCH_AUTO_MANAGER_SUCCESS,
	FETCH_AUTO_MANAGER_FAILURE,
	DESTROY_MANAGER_MOBILE_SUCCESS,
	UPDATE_AUTO_MANAGER_MOBILE_SUCCESS,
	STORE_MANAGER_SUCCESS,
	STORE_MANAGER_MOBILE_SUCCESS,
} from "actions/fanclub.action";
// 블랙리스트
import {
	FETCH_BLACKLIST,
	FETCH_BLACKLIST_SUCCESS,
	FETCH_BLACKLIST_FAILURE,
	DELETE_BLACKLIST_SUCCESS,
	UPDATE_BLACKLIST_SUCCESS,
	STORE_BLACKLIST_SUCCESS,
	SET_BLACKLIST_POST,
	SET_BLACKLIST_MEMO,
} from "actions/blacklist.action";
// 검색 정보 관리
import {
	FETCH_SEARCH_PROFILE,
	FETCH_SEARCH_PROFILE_SUCCESS,
	FETCH_SEARCH_PROFILE_FAILURE,
	FETCH_STATION_TITLE_SUCCESS,
} from "actions/setting.searchprofile.action";
// 외부 링크 관리
import { FETCH_LINK, FETCH_LINK_SUCCESS, FETCH_LINK_FAILURE, MODIFY_LINK, DELETE_LINK, UPDATE_LINK_SUCCESS } from "actions/setting.link.action";
// 방송 통계
import { FETCH_STATISTIC, FETCH_STATISTIC_SUCCESS, FETCH_STATISTIC_FAILURE } from "actions/setting.statistic.action";

const INITIAL_STATE = Map({
	error: null,
	loading: true,
	name: "",
	settingInfo: Map({
		data: List(),
	}),
});

export default function settingReducer(state = INITIAL_STATE, action) {
	const time_stamp = Math.floor(Date.now() / 1000);
	switch (action.type) {
		case FETCH_STATION_INFO:
			const stationInit = Map({
				profile_image: "",
				profile_text: "",
				station_name: "",
				user_nick: "",
			});
			return state
				.set("error", null)
				.set("loading", true)
				.set("name", "station_info")
				.setIn(["settingInfo", "data"], stationInit);
		case FETCH_STATION_INFO_SUCCESS:
			return state
				.set("error", null)
				.set("loading", false)
				.set("name", "station_info")
				.set("StationNameValidate", Map({ status: "init", value: action.payload.data.station_name }))
				.set("UserNickValidate", Map({ status: "init", value: action.payload.data.user_nick }))
				.setIn(["settingInfo", "data"], Map(action.payload.data));
		case FETCH_STATION_INFO_FAILURE:
			return state
				.set("loading", false)
				.set("name", "station_info")
				.set("error", action.payload);
		case CHECK_STATION_NAME_SUCCESS:
			return state.set("StationNameValidate", Map({ status: "success", value: action.payload.data.station_name }));
		case CHECK_STATION_NAME_FAILURE:
			return state.set("StationNameValidate", "fail");
		case PUT_STATION_NAME_SUCCESS:
			return state
				.set("StationNameValidate", Map({ status: "init", value: action.payload.data.station_name }))
				.setIn(["settingInfo", "data", "station_name"], action.payload.data.station_name);
		case PUT_PROFILE_IMAGE_SUCCESS:
			return state.setIn(["settingInfo", "data", "profile_image"], action.payload.data.profile_image);
		case PUT_PROFILE_TEXT_SUCCESS:
			return state.setIn(["settingInfo", "data", "profile_text"], action.payload.profile_text);
		case STORE_STATION_INFO_SUCCESS:
			return state;
		case CHECK_USER_NICK_SUCCESS:
			//return state;
			return state.set("UserNickValidate", Map({ status: "success", value: action.payload }));
		case CHECK_USER_NICK_FAILURE:
			return state.set("UserNickValidate", "fail");
		case PUT_USER_NICK_SUCEESS:
			return state.set("UserNickValidate", Map({ status: "init", value: action.payload }));
		case FETCH_BANWORD:
			return state
				.set("error", null)
				.set("loading", true)
				.set("name", "banword")
				.set("settingInfo", Map({}));
		case FETCH_BANWORD_SUCCESS:
			return state
				.set("error", null)
				.set("loading", false)
				.set("name", "banword")
				.set("settingInfo", Map(fromJS(action.payload)))
				.set("time_stamp", time_stamp);
		case FETCH_BANWORD_FAILURE:
			return state
				.set("loading", false)
				.set("name", "banword")
				.set("error", action.payload);
		case STORE_BANWORD_SUCCESS:
		case DELETE_BANWORD_SUCCESS:
		case UPDATE_BANWORD_STATUS_SUCCESS: {
			return state.set("name", "banword").set("settingInfo", Map(fromJS(action.payload)));
		}
		case FETCH_MEDAL:
			return state
				.set("error", null)
				.set("loading", true)
				.set("name", "medal");
		case FETCH_MEDAL_SUCCESS:
			return state
				.set("error", null)
				.set("loading", false)
				.set("name", "medal")
				.set("settingInfo", Map(fromJS(action.payload)));
		case FETCH_MEDAL_FAILURE:
			return state
				.set("loading", false)
				.set("name", "medal")
				.set("error", action.payload);
		case FETCH_BADGE:
			return state
				.set("error", null)
				.set("loading", true)
				.set("name", "badge");
		case FETCH_BADGE_SUCCESS:
			return state
				.set("error", null)
				.set("loading", false)
				.set("name", "badge")
				.set("settingInfo", Map(fromJS(action.payload)));
		case FETCH_BADGE_FAILURE:
			return state
				.set("loading", false)
				.set("name", "badge")
				.set("error", action.payload);		
		//편집자
		case FETCH_VODEDITOR:
			return state
				.set("error", null)
				.set("loading", true)
				.set("name", "vodeditor");
		case FETCH_VODEDITOR_SUCCESS:
			return state
				.set("error", null)
				.set("loading", false)
				.set("name", "vodeditor")
				.set("settingInfo", Map(fromJS(action.payload)));
		case FETCH_VODEDITOR_FAILURE:
			return state.set("loading", false).set("error", action.payload);
		case STORE_VODEDITOR_SUCCESS:
			const editorList = List(state.getIn(["settingInfo", "data"])).unshift(Map(fromJS(action.payload)));
			const nPerPage = parseInt(state.getIn(["settingInfo", "meta", "per_page"]), 10);
			return state
				.setIn(["settingInfo", "data"], editorList.slice(0, nPerPage))
				.updateIn(["settingInfo", "meta", "total"], (total) => total + 1);
		case DELETE_VODEDITOR_SUCCESS:
			const nIdx = action.payload.idx;
			const deleteList = List(state.getIn(["settingInfo", "data"])).filterNot((data) => data.get("idx") === parseInt(nIdx, 10));
			return state.setIn(["settingInfo", "data"], deleteList).updateIn(["settingInfo", "meta", "total"], (total) => total - 1);
		case SET_VODEDITOR_POST:
			return state.setIn(["settingInfo", "isPost"], action.payload);

		//구독팬
		case FETCH_SUBSCRIPT:
			return state
				.set("error", null)
				.set("loading", true)
				.set("name", "subscript");
		case FETCH_SUBSCRIPT_SUCCESS:
			return state
				.set("error", null)
				.set("loading", false)
				.set("name", "subscript")
				.set("settingInfo", Map(fromJS(action.payload)));
		case FETCH_SUBSCRIPT_FAILURE:
			return state.set("loading", false).set("error", action.payload);

		//서포터
		case FETCH_SUPPORTER:
			return state
				.set("error", null)
				.set("loading", true)
				.set("name", "supporter");
		case FETCH_SUPPORTER_SUCCESS:
			return state
				.set("error", null)
				.set("loading", false)
				.set("name", "supporter")
				.set("settingInfo", Map(fromJS(action.payload)));
		case FETCH_SUPPORTER_FAILURE:
			return state.set("loading", false).set("error", action.payload);

		// 팬클럽
		case FETCH_FANCLUB:
			return state
				.set("error", null)
				.set("loading", true)
				.set("name", "fanclub");
		case FETCH_FANCLUB_SUCCESS:
			return state
				.set("error", null)
				.set("loading", false)
				.set("name", "fanclub")
				.set("settingInfo", Map(fromJS(action.payload)));
		case FETCH_FANCLUB_FAILURE:
			return state.set("loading", false).set("error", action.payload);

		//매니저
		case FETCH_MANAGER:
			return state
				.set("error", null)
				.set("loading", true)
				.set("name", "manager");
		case FETCH_MANAGER_SUCCESS:
			return state
				.set("error", null)
				.set("loading", false)
				.set("name", "manager")
				.set("settingInfo", Map(fromJS(action.payload)));
		case FETCH_MANAGER_FAILURE:
			return state.set("loading", false).set("error", action.payload);

		// 자동 매니저
		case FETCH_AUTO_MANAGER:
			return state
				.set("error", null)
				.set("loading", true)
				.set("name", "automanager");
		case FETCH_AUTO_MANAGER_SUCCESS:
			return state
				.set("error", null)
				.set("loading", false)
				.set("name", "automanager")
				.set("settingInfo", Map(fromJS(action.payload)));
		case FETCH_AUTO_MANAGER_FAILURE:
			return state.set("loading", false).set("error", action.payload);

		//블랙 리스트
		case FETCH_BLACKLIST:
			return state
				.set("error", null)
				.set("loading", true)
				.set("name", "blacklist");
		case FETCH_BLACKLIST_SUCCESS:
			return state
				.set("error", null)
				.set("loading", false)
				.set("name", "blacklist")
				.set("settingInfo", Map(fromJS(action.payload)));
		case FETCH_BLACKLIST_FAILURE:
			return state.set("loading", false).set("error", action.payload);
		case STORE_BLACKLIST_SUCCESS:
		case DELETE_BLACKLIST_SUCCESS:
		case UPDATE_BLACKLIST_SUCCESS:
			return state.set("settingInfo", Map(fromJS(action.payload)));
		case SET_BLACKLIST_POST:
			return state.setIn(["settingInfo", "isPost"], action.payload);
		case SET_BLACKLIST_MEMO:
			return state.setIn(["settingInfo", "isMemo"], action.payload);

		case STORE_MANAGER_SUCCESS: {
			const _data = List(state.getIn(["settingInfo", "data"]));
			return state.setIn(
				["settingInfo", "data"],
				_data.update(
					_data.findIndex((manager) => {
						return Map(manager).get("user_id") === action.payload.manager_id;
					}),
					(manager) => {
						return Map(manager)
							.set("is_manager", true)
							.set("is_notice", action.payload.notice_flag === "Y" ? true : false);
					},
				),
			);
		}
		case STORE_MANAGER_FAILURE: {
			const _data = List(state.getIn(["settingInfo", "data"]));
			return state.setIn(
				["settingInfo", "data"],
				_data.update(
					_data.findIndex((manager) => {
						return Map(manager).get("user_id") === action.payload.manager_id;
					}),
					(manager) => {
						return Map(manager).set("is_manager", manager.is_manager);
					},
				),
			);
		}
		case STORE_MANAGER_MOBILE_SUCCESS: {
			const manager_data = {
				is_notice: action.payload.notice_flag === "Y" ? true : false,
				user_id: action.payload.manager_id,
				user_nick: action.payload.user_nick,
			};
			const user_store_data = List(state.getIn(["settingInfo", "data"])).unshift(manager_data);
			return state.setIn(["settingInfo", "data"], user_store_data);
		}

		case DESTROY_MANAGER_SUCCESS: {
			const _data = List(state.getIn(["settingInfo", "data"]));
			return state.setIn(
				["settingInfo", "data"],
				_data.update(
					_data.findIndex((manager) => {
						return Map(manager).get("user_id") === action.payload.manager_id;
					}),
					(manager) => {
						return Map(manager)
							.set("is_manager", false)
							.set("is_notice", false);
					},
				),
			);
		}
		case DESTROY_MANAGER_FAILURE: {
			const _data = List(state.getIn(["settingInfo", "data"]));
			return state.setIn(
				["settingInfo", "data"],
				_data.update(
					_data.findIndex((manager) => {
						return Map(manager).get("user_id") === action.payload.manager_id;
					}),
					(manager) => {
						return Map(manager).set("is_manager", manager.is_manager);
					},
				),
			);
		}
		case DESTROY_MANAGER_MOBILE_SUCCESS: {
			const delete_data = List(state.getIn(["settingInfo", "data"])).filterNot(
				(manager) => Map(manager).get("user_id") === action.payload.manager_id,
			);
			return state.setIn(["settingInfo", "data"], delete_data);
		}

		case UPDATE_NOTICE_SUCCESS: {
			const _data = List(state.getIn(["settingInfo", "data"]));
			return state.setIn(
				["settingInfo", "data"],
				_data.update(
					_data.findIndex((manager) => {
						return Map(manager).get("user_id") === action.payload.manager_id;
					}),
					(manager) => {
						return Map(manager).set("is_notice", action.payload.notice_flag === "Y" ? true : false);
					},
				),
			);
		}
		case UPDATE_NOTICE_FAILURE: {
			const _data = List(state.getIn(["settingInfo", "data"]));
			return state.setIn(
				["settingInfo", "data"],
				_data.update(
					_data.findIndex((manager) => {
						return Map(manager).get("user_id") === action.payload.manager_id;
					}),
					(manager) => {
						return Map(manager).set("is_notice", manager.is_notice);
					},
				),
			);
		}

		case UPDATE_AUTO_MANAGER_SUCCESS: {
			const _data = List(state.getIn(["settingInfo", "data"]));
			return state.setIn(
				["settingInfo", "data"],
				_data.update(
					_data.findIndex((manager) => {
						return Map(manager).get("user_id") === action.payload.user_id;
					}),
					(manager) => {
						return Map(manager).set("auto_manager", action.payload.auto_manager);
					},
				),
			);
		}
		case UPDATE_AUTO_MANAGER_FAILURE: {
			const _data = List(state.getIn(["settingInfo", "data"]));
			return state.setIn(
				["settingInfo", "data"],
				_data.update(
					_data.findIndex((manager) => {
						return Map(manager).get("user_id") === action.payload.user_id;
					}),
					(manager) => {
						return Map(manager).set("auto_manager", manager.auto_manager);
					},
				),
			);
		}
		case UPDATE_AUTO_MANAGER_MOBILE_SUCCESS: {
			if (action.payload.auto_manager === "N") {
				const delete_data = List(state.getIn(["settingInfo", "data"])).filterNot(
					(manager) => Map(manager).get("user_id") === action.payload.user_id,
				);
				return state.setIn(["settingInfo", "data"], delete_data);
			} else {
				const manager_data = {
					user_id: action.payload.user_id,
					user_nick: action.payload.user_nick,
				};
				const user_store_data = List(state.getIn(["settingInfo", "data"])).unshift(manager_data);
				return state.setIn(["settingInfo", "data"], user_store_data);
			}
		}
		case FETCH_SEARCH_PROFILE:
			return state
				.set("error", null)
				.set("loading", true)
				.set("name", "searchprofile");
		case FETCH_SEARCH_PROFILE_SUCCESS:
			return state
				.set("error", null)
				.set("loading", false)
				.set("name", "searchprofile")
				.set("settingInfo", Map(fromJS(action.payload)));
		case FETCH_SEARCH_PROFILE_FAILURE:
			return state
				.set("loading", false)
				.set("name", "searchprofile")
				.set("error", action.payload);
		case FETCH_LINK:
			return state
				.set("error", null)
				.set("loading", true)
				.set("name", "link");
		case FETCH_LINK_SUCCESS:
			return state
				.set("error", null)
				.set("loading", false)
				.set("name", "link")
				.set("settingInfo", Map(fromJS(action.payload)))
				.set("time_stamp", time_stamp);
		case FETCH_LINK_FAILURE:
			return state
				.set("loading", false)
				.set("name", "link")
				.set("error", action.payload);
		case MODIFY_LINK:
			const upload_link_data = List(state.getIn(["settingInfo", "data"]));
			return state.setIn(
				["settingInfo", "data"],
				upload_link_data.update(
					action.payload.idx,
					() => fromJS(action.payload.link)
				)
			);
		case DELETE_LINK: {
			/* const delete_data = List(state.getIn(["settingInfo", "data"])).delete(action.payload.idx);
			return state.setIn(["settingInfo", "data"], delete_data); */
			const delete_link_data = List(state.getIn(["settingInfo", "data"]));
			return state.setIn(
				["settingInfo", "data"],
				delete_link_data.update(
					action.payload.idx,
					() => fromJS(action.payload.link)
				)
			);
		}
		case UPDATE_LINK_SUCCESS:
			return state.set("settingInfo", Map(fromJS(action.payload))).set("time_stamp", time_stamp);
		case FETCH_STATISTIC:
			return state
				.set("error", null)
				.set("loading", true)
				.set("name", "statistic");
		case FETCH_STATISTIC_SUCCESS:
			return state
				.set("error", null)
				.set("loading", false)
				.set("name", "statistic")
				.set("settingInfo", Map(fromJS(action.payload)));
		case FETCH_STATISTIC_FAILURE:
			return state
				.set("loading", false)
				.set("name", "statistic")
				.set("error", action.payload);

		case FETCH_STATION_TITLE_SUCCESS:
			return state.set("statinTitle", Map(fromJS(action.payload)));
		case INIT_PROFILE_IMAGE_SUCCESS:
			return state.setIn(["settingInfo", "data", "profile_image"], action.payload.data.profile_image);
		default:
			return state;
	}
}
