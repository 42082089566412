import React, {Fragment} from "react";

const LoadingMobile = () => {
	return (
		<Fragment>
			<header id="bs-header" className="m_loading-header">
				<div className="tit" />
				<div className="right">
					<span />
					<span />
				</div>
			</header>
			<div id="bs-container">
				<div id="bs-contents">
					<div className="m_af_loading">
						<div>
							<span />
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default LoadingMobile;