import produce from "immer";

import {
	FETCH_CREATE_FORM,
	FETCH_CREATE_POST_ERROR,
	FETCH_CREATE_FORM_SUCCESS,
	FETCH_TEMPORARY_SUCCESS,
	FETCH_TEMPORARY,
	CLEAR_CREATE_FORM,
	SET_DISABLE_SAVE_BTN,
	RESET_DISABLE_SAVE_BTN,
} from "actions/post.write.action";

const INITIAL_STATE = {
	createFormData: {
		init: false,
		payload: {},
		error: null,
		loading: true,
	},
	temporaryFormData: null,
	disableSaveBtn: false,
};

const postWriteReducer = produce((state, { type, payload }) => {
	switch (type) {
		// 게시글 쓰기 폼 로딩
		case FETCH_CREATE_FORM:
			state.createFormData = {
				init: false,
				payload: {},
				error: null,
				loading: true,
			};
			return;

		// 게시글 쓰기 폼 로딩 성공
		case FETCH_CREATE_FORM_SUCCESS:
			state.createFormData = {
				init: true,
				payload,
				error: null,
				loading: false,
			};
			return;

		// 게시글 쓰기 폼 로딩 실패
		case FETCH_CREATE_POST_ERROR:
			state.createFormData = {
				init: false,
				payload,
				error: payload,
				loading: false,
			};
			return;

		// 게시글 쓰기 초기화
		case CLEAR_CREATE_FORM:
			return INITIAL_STATE;

		// 임시 저장글 로딩 시작
		case FETCH_TEMPORARY:
			state.temporaryFormData = null;
			return;

		// 임시 저장글 로딩 완료
		case FETCH_TEMPORARY_SUCCESS:
			state.temporaryFormData = payload;
			return;

		case SET_DISABLE_SAVE_BTN:
			state.disableSaveBtn = true;
			return;

		case RESET_DISABLE_SAVE_BTN:
			state.disableSaveBtn = false;
			return;

		default:
	}
}, INITIAL_STATE);

export default postWriteReducer;
