import {
	CHANNELART_IMAGE_UPLOAD,
	CHANNELART_IMAGE_UPLOAD_FAILURE,
	CHANNELART_IMAGE_UPLOAD_PROGRESS,
	CHANNELART_IMAGE_UPLOAD_SUCCESS,
	CHANNELART_CHANGE_STEP,
	CHANNELART_CROP_COMPLETE,
	CHANNELART_RESET,
	FETCH_CHANNELART_SUCCESS,
	CHANNELART_SAVE_COMPLETE,
	CHANNELART_INIT_SUCCESS,
	CHANNELART_INIT_FAILURE,
	CHANNELART_COLOR_CLICK,
} from "../actions/setting.channelart.action";

const INITIAL_STATE = {};

export default function settingChannelArtReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case FETCH_CHANNELART_SUCCESS:
			return {
				...state,
				channelart: action.payload,
				clickedColor: '',
			};
		case CHANNELART_CHANGE_STEP:
			return {
				...state,
				step: action.step,
			};
		case CHANNELART_IMAGE_UPLOAD: //이미지 업로드 시작
			return {
				...state,
				type: action.type,
				filename: action.filename,
				progress: 0,
			};
		case CHANNELART_IMAGE_UPLOAD_PROGRESS: //이미지 업로드중		
			return {
				...state,
				type: action.type,
				progress: action.progress,
			};
		case CHANNELART_IMAGE_UPLOAD_SUCCESS: //이미지 등록
			return {
				...state,
				type: action.type,
				progress: 100,
				info: action.payload,
			};
		case CHANNELART_IMAGE_UPLOAD_FAILURE: //이미지 업로드 실패
			return {
				...state,
				type: action.type,
				progress: 100,
				info: action.payload,
			};
		case CHANNELART_CROP_COMPLETE: //이미지 업로드 실패
			return {
				...state,
				type: action.type,
				crop: action.payload,
			};

		case CHANNELART_SAVE_COMPLETE:
			return {
				...state,
				channelart: action.payload,
			};

		case CHANNELART_RESET:
			return {
				channelart: state.channelart,
			};
		case CHANNELART_INIT_SUCCESS:
			return {
				...state,
				channelart: action.payload,
			};
		case CHANNELART_INIT_FAILURE:
			return {
				...state,
				info: action.payload,
			};
		case CHANNELART_COLOR_CLICK:
			return {
				...state,
				clickedColor: action.payload,
			};
		default:
			return state;
	}
}
