import produce from "immer";
import {
	FETCH_BANWORD,  //fetch 
	FETCH_BANWORD_SUCCESS, //fetch success
	FETCH_BANWORD_FAILURE,  //fetch fail
	STORE_BANWORD_SUCCESS,
    DELETE_BANWORD_SUCCESS,
	UPDATE_BANWORD_STATUS_SUCCESS
} from "actions/setting.banword.action";

const INITIAL_STATE = {
	error: null,
	loading: true,
	name: "",
	banwordInfo: []
};

const settingBanWordReducer = produce((state, action) => {
    const time_stamp = Math.floor(Date.now() / 1000);
    switch (action.type) {
        case FETCH_BANWORD:
            state.name = "banword";
            state.loading = true;
            break;
        case FETCH_BANWORD_SUCCESS:
            state.loading = false;
            state.name = "banword";
            state.banwordInfo = action.payload;
            state.time_stamp = time_stamp;
            break;
        case FETCH_BANWORD_FAILURE:
            state.loading = false;
            state.name = "banword";
            state.error = action.payload;
            break;
        case STORE_BANWORD_SUCCESS:
        case DELETE_BANWORD_SUCCESS:
        case UPDATE_BANWORD_STATUS_SUCCESS: {
            state.name = "banword";
            state.banwordInfo = action.payload;
            break;
        }
        default:
			return;
    }
}, INITIAL_STATE);


export default settingBanWordReducer;