import axios from "libs/axios";

import { TITLE_CONTENTS } from "constant/search";
import { ALL_MONTHS } from "constant/month";

//Post list
export const FETCH_VODS = "FETCH_VODS";
export const FETCH_VODS_SUCCESS = "FETCH_VODS_SUCCESS";
export const FETCH_VODS_FAILURE = "FETCH_VODS_FAILURE";

export const CLEAR_VODS = "CLEAR_VODS";

/**
 * 게시판리스트 불러오기
 * @param params
 * @param append
 */
export const fetchVods = (
	{ pageType = "", created = false, catchCreated = false , page = 1, perPage = 20, orderBy = null, field = TITLE_CONTENTS, keyword = "", months = ALL_MONTHS },
	append = false,
) => async (dispatch) => {
	dispatch({ type: FETCH_VODS, payload: { append, page_type: pageType } });

	// url 세팅
	let url = `/${global.bj_id}/vods`;
	pageType && (url += `/${pageType}`);

	// 파라미터 세팅
	const params = {
		page,
		per_page: perPage,
		orderby: orderBy,
		field,
		created,
        catchCreated,
		keyword,
		months,
	};

	try {
		// api 호출
		const response = await axios.get(url, { params });

		dispatch({
			type: FETCH_VODS_SUCCESS,
			payload: {
				...response,
				page_type: pageType,
			},
		});
	} catch (error) {
		dispatch({
			type: FETCH_VODS_FAILURE,
			payload: error ? error : { code: 0, message: error },
		});
	}
};
