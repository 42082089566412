export const SUCCEED = "SUCCEED";
export const ENCODING = "ENCODING";
export const ENCODING_WAIT = "ENCODING_WAIT";
export const ENCODING_FAIL = "ENCODING_FAIL";
export const UPLOADING = "UPLOADING";
export const UPLOAD_FAIL = "UPLOAD_FAIL";
export const ADMIN_DELETE = "ADMIN_DELETE";
export const FILTERING = "FILTERING";
export const TIMEOUT_DELETE = "TIMEOUT_DELETE";
export const BJ_HIDDEN = "BJ_HIDDEN";
