import { FETCH_CLIP_FILTER, FETCH_CLIP_FILTER_SUCCESS, FETCH_CLIP_FILTER_FAILURE } from "../actions/clip.filter.action";
import { ERROR, SUCCESS, LOADING } from "constant/status";
import { produce } from "immer";

const INITIAL_STATE = {
    status: SUCCESS,
    error: false,
    data: [],
};

const clipFilterReducer = produce((state, { type, payload, placement, creatives }) => {
    switch (type) {
        case FETCH_CLIP_FILTER:
            state.status = LOADING;
            return;

        case FETCH_CLIP_FILTER_SUCCESS:
            state.status = SUCCESS;
            state.data = payload.filter_list_id;

            return;

        case FETCH_CLIP_FILTER_FAILURE:
            state.status = ERROR;
            state.error = payload;
            return;

        default:
            return;
    }
}, INITIAL_STATE);

export default clipFilterReducer;
