import { FETCH_BANNER_FAILURE, FETCH_BANNER_SUCCESS } from "../actions/banner.action";

const INITIAL_STATE = {
	error: null,
	loading: false,
};

export default function bannerReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case FETCH_BANNER_SUCCESS:
			return {
				...state,
				banners: action.payload,
				loading: true,
			};
		case FETCH_BANNER_FAILURE:
			return {
				...state,
				loading: false,
				banner: [],
			};
		default:
			return state;
	}
}
