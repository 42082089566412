import { ADBALLOON_DOMAIN } from "constant/config";
import querystring from "querystring";
import axios from "libs/axios";
import cookie from "react-cookies";

export const EVENT_FETCH = "EVENT_FETCH";
export const EVENT_FETCH_SUCCESS = "EVENT_FETCH_SUCCESS";
export const EVENT_FETCH_ERROR = "EVENT_FETCH_ERROR";

export const EVENT_ACTIVE_CHECK = "EVENT_ACTIVE_CHECK";
export const EVENT_ACTIVE_FETCH_SUCCESS = "EVENT_ACTIVE_FETCH_SUCCESS";
export const EVENT_ACTIVE_FETCH_ERROR = "EVENT_ACTIVE_FETCH_ERROR";

/**
 * 진행중인 선물 이벤트 내용 가져오기
 * @param page
 * @returns {Function}
 */

export const fetchGiftEvent = (dispatch) => {
    dispatch({type: EVENT_FETCH});
    const params = querystring.stringify({ type: "active_bj_info", event_no : 1, bj_id: global.bj_id });
    let url = `${ADBALLOON_DOMAIN}/app/api/adballoon_event.php?${params}`;

    return fetch(url, {
        credentials: "include",
    })
        .then((response) => response.json())
        .then((response) => {
            dispatch({
                type: EVENT_FETCH_SUCCESS,
                payload: response,
            });
        }).catch((error) => {
            dispatch({
                type: EVENT_FETCH_ERROR,
                payload: error ? error : {code: 0, message: error.message}
            });                
        });

}
