import i18next from "i18next";
import qs from "qs";

import axios from "libs/axios";

import { FORM_CONTENT_TYPE } from "actions/config";
import { deleteVodAuth } from "actions/setting.vodauth.list.action";

import { DOWN_REJECT, DOWNLOADER } from "constant/vodAuth";
import { DELETE } from "constant/method";

import { toastr } from "components/toastr";

export const FETCH_VOD_DOWN_REJECT_LIST = "FETCH_VOD_DOWN_REJECT_LIST";
export const FETCH_VOD_DOWN_REJECT_LIST_SUCCESS = "FETCH_VOD_DOWN_REJECT_LIST_SUCCESS";
export const FETCH_VOD_DOWN_REJECT_LIST_FAILURE = "FETCH_VOD_DOWN_REJECT_LIST_FAILURE";

export const CLEAR_VOD_DOWN_REJECT_LIST = "CLEAR_VOD_DOWN_REJECT_LIST";

/**
 * 다운로드 제한 리스트 조회
 * @param page
 * @param word
 * @param type
 * @returns {function(...[*]=)}
 */
export const fetchVodDownReject = (page = 1, word = "", type = "user_id") => async (dispatch) => {
    dispatch({ type: FETCH_VOD_DOWN_REJECT_LIST });

    try {
        const params = { page, word, type };
        const response = await axios.get(`/${global.bj_id}/vodreject`, { params });

        dispatch({
            type: FETCH_VOD_DOWN_REJECT_LIST_SUCCESS,
            payload: response,
        });
    } catch (error) {
        dispatch({
            type: FETCH_VOD_DOWN_REJECT_LIST_FAILURE,
            payload: error ? error : { code: 0, message: error },
        });
    }
};

/**
 * 다운로드 제한 추가
 * @param userId
 * @returns {function(...[*]=)}
 */
export const storeDownRejectAuth = (userId) => async (dispatch) => {
    try {
        // 권한 추가
        await axios.post(`/${global.bj_id}/vodauth`, qs.stringify({ user_id: userId, type: DOWN_REJECT }), {
            headers: FORM_CONTENT_TYPE,
        });

        toastr.success(i18next.t("추가되었습니다."), {
            timeOut: 2000,
        });

        // 리스트 초기화
        dispatch({ type: CLEAR_VOD_DOWN_REJECT_LIST });
    } catch (error) {
        // 다운로드 권한이 있을 경우
        if (error.code === 5001) {
            if (window.confirm(error.message)) {
                await dispatch(deleteVodAuth(userId, DOWNLOADER)); // 다운로드 권한 제거
                await dispatch(storeDownRejectAuth(userId)); // 저장 다시 시도
            }
            return;
        }

        toastr.error(error.message, {
            timeOut: 2000,
        });
    }
};

/**
 * 다운로드 제한 해제
 * @param userId {string[]|string}
 * @returns {function(...[*]=)}
 */
export const deleteDownRejectAuth = (userId) => async (dispatch) => {
    if (Array.isArray(userId)) {
        userId = userId.join();
    }

    try {
        // 다운로드 제한 해제
        await axios.post(`/${global.bj_id}/vodauth/${DOWN_REJECT}`, qs.stringify({ _method: DELETE, user_id: userId }), {
            headers: FORM_CONTENT_TYPE,
        });

        // 리스트 초기화
        dispatch({ type: CLEAR_VOD_DOWN_REJECT_LIST });
    } catch (error) {
        toastr.error(error.message);
    }
};

/**
 * 다운로드 제한 전체 해제
 * @returns {function(...[*]=)}
 */
export const deleteDownRejectAuthAllApi = () => async (dispatch) => {
    try {
        // 다운로드 권한 전체 삭제
        await axios.post(`/${global.bj_id}/vodauth/${DOWN_REJECT}`, qs.stringify({ _method: DELETE, all: true }), {
            headers: FORM_CONTENT_TYPE,
        });

        toastr.success(i18next.t("해제되었습니다."), {
            timeOut: 2000,
        });

        // 리스트 초기화
        dispatch({ type: CLEAR_VOD_DOWN_REJECT_LIST });
    } catch (error) {
        toastr.error(error.message);

        throw error;
    }
};

/**
 * 다운로드 제한 리스트 초기화
 * @returns {{type: string}}
 */
export const clearVodDownRejectList = () => ({ type: CLEAR_VOD_DOWN_REJECT_LIST });
