import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import Header from "../../../components/header/Header";
import { StationError } from "./StationError";

class NotFound extends Component {
	render() {
		if (isMobile) {
			return <div id="bs-wrap">Not Found</div>;
		} else {
			return (
				<div id="bs-wrap">
					<StationError error={{ code: 9000 }} />
				</div>
			);
		}
	}
}

export default NotFound;
