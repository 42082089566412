import { produce } from "immer";

import {
    FETCH_VODAUTHLIST,
    FETCH_VODAUTHLIST_FAILURE,
    FETCH_VODAUTHLIST_SUCCESS,
    CLEAR_VODAUTHLIST,
    STORE_VOD_AUTH_SUCCESS,
    DELETE_VOD_AUTH_SUCCESS,
} from "actions/setting.vodauth.list.action";

import { UPLOADER } from "constant/vodAuth";
import { CLEAR, ERROR, LOADING, SUCCESS } from "constant/status";

const INITIAL_STATE = {
    status: CLEAR,
    error: false,
    pagination: [],
};

const settingVodAuthListReducer = produce((state, { type, payload }) => {
    switch (type) {
        // vod 유저 권한 리스트 조회 시작
        case FETCH_VODAUTHLIST:
            state.status = LOADING;
            break;

        // vod 유저 권한 리스트 조회 성공
        case FETCH_VODAUTHLIST_SUCCESS:
            state.status = SUCCESS;
            state.pagination = payload;
            return;

        // vod 유저 권한 리스트 조회 실패
        case FETCH_VODAUTHLIST_FAILURE:
            state.status = ERROR;
            state.error = payload;
            return;

        // vod 유저 권한 리스트 초기화
        case CLEAR_VODAUTHLIST:
            state = Object.assign(state, INITIAL_STATE);
            return;

        // vod 권한 변경
        case STORE_VOD_AUTH_SUCCESS:
        case DELETE_VOD_AUTH_SUCCESS:
            if (state.status !== SUCCESS || !state.pagination || !state.pagination.data) {
                return;
            }

            const authType = payload.type === UPLOADER ? "is_uploader" : "is_downloader";

            // vod 권한 세팅
            state.pagination.data = state.pagination.data.map((item) => {
                const { user_id: userId } = item;

                if (userId === payload.user_id) {
                    item.auth = {
                        ...item.auth,
                        [authType]: type === STORE_VOD_AUTH_SUCCESS,
                    };
                }

                return item;
            });

            break;

        default:
            return;
    }
}, INITIAL_STATE);

export default settingVodAuthListReducer;
