import axios from "axios";
import cookie from "react-cookies";
import i18next from "i18next";
import { arrayPush, arrayRemoveAll } from "redux-form";
import { POST } from "../constant/method";
import { BJ_API_DOMAIN } from "constant/config";
//Delete post
export const COMMENT_IMAGE_UPLOAD = "COMMENT_IMAGE_UPLOAD";
export const COMMENT_IMAGE_UPLOAD_PROGRESS = "COMMENT_IMAGE_UPLOAD_PROGRESS";
export const COMMENT_IMAGE_UPLOAD_SUCCESS = "COMMENT_IMAGE_UPLOAD_SUCCESS";
export const COMMENT_IMAGE_UPLOAD_FAILURE = "COMMENT_IMAGE_UPLOAD_FAILURE";
export const COMMENT_IMAGE_DELETE = "COMMENT_IMAGE_DELETE";
export const COMMENT_IMAGE_RESET = "COMMENT_IMAGE_RESET";

export function commentImageUpload(file, type, title_no, p_comment_no, c_comment_no, form) {
    return (dispatch) => {
        if (file.size > 20 * 1024 * 1024) {
            alert(i18next.t("20M가 초과하는 이미지는 업로드 하실수 없습니다."));
            return;
        }
        dispatch({
            type: COMMENT_IMAGE_UPLOAD,
            p_comment_no,
            c_comment_no,
        });
        const data = new FormData();
        data.append("file", file);
        data.append("_token", cookie.load("XSRF-TOKEN"));
        //로딩
        axios({
            method: POST,
            data,
            url: `${BJ_API_DOMAIN}/${global.bj_id}/title/${title_no}/comment/image`,
            withCredentials: true,
            onUploadProgress(progressEvent) {
                dispatch({
                    type: COMMENT_IMAGE_UPLOAD_PROGRESS,
                    comment_type: type,
                    p_comment_no,
                    c_comment_no,
                    progress: Math.floor((progressEvent.loaded * 100) / progressEvent.total),
                });
            },
        })
            .then((response) => {
                dispatch({
                    type: COMMENT_IMAGE_UPLOAD_SUCCESS,
                    comment_type: type,
                    p_comment_no,
                    c_comment_no,
                    payload: response.data,
                });
                dispatch(arrayRemoveAll(form, "imageinfo"));
                console.log("imageinfo", form, response.data);
                dispatch(arrayPush(form, "imageinfo", response.data));
            })
            .catch((error) => {
                alert(error.response ? error.response.data.message : error.message);
                dispatch({
                    type: COMMENT_IMAGE_UPLOAD_FAILURE,
                    comment_type: type,
                    p_comment_no,
                    c_comment_no,
                });
            });
    };
}

export function commentImageDelete(type, p_comment_no, c_comment_no) {
    return (dispatch) => {
        dispatch({
            type: COMMENT_IMAGE_DELETE,
            comment_type: type,
            p_comment_no,
            c_comment_no,
        });
    };
}
