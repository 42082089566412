export const PAGE_TYPE_ALL = "all";
export const PAGE_TYPE_REVIEW = "review";
export const PAGE_TYPE_HIGHLIGHT = "highlight";
export const PAGE_TYPE_NORMAL = "normal";
export const PAGE_TYPE_USER = "user";
export const PAGE_TYPE_PLAYLIST = "list";
export const PAGE_TYPE_CLIP = "clip";
export const PAGE_TYPE_MY_CLIP = "myclip";
export const PAGE_TYPE_STARBALLOON_CLIP = "balloonclip";
export const PAGE_TYPE_CATCH = "catch";
export const PAGE_TYPE_CATCH_STORY = "catchstory";

/**
 * ucc page type 전체 리스트
 * @returns {(string)[]}
 */
export const getPageTypes = () => [
	PAGE_TYPE_ALL,
	PAGE_TYPE_REVIEW,
	PAGE_TYPE_HIGHLIGHT,
	PAGE_TYPE_NORMAL,
	PAGE_TYPE_USER,
	PAGE_TYPE_PLAYLIST,
	PAGE_TYPE_CLIP,
	PAGE_TYPE_MY_CLIP,
	PAGE_TYPE_STARBALLOON_CLIP,
    PAGE_TYPE_CATCH,
    PAGE_TYPE_CATCH_STORY,
];

/**
 * 유요한 page type 조회
 * @param pageType
 * @returns {string}
 */
export const getPageType = (pageType) => {
	const types = getPageTypes();
	return types.find((type) => type === pageType) || types[0];
};
