import produce from "immer";

import {
	DESTORY_PIN_SUCCESS,
	FETCH_HOME,
	FETCH_HOME_FAILURE,
	FETCH_HOME_SUCCESS,
	HOME_DID_MOUNT,
	HOME_WILL_UNMOUNT,
	MOBILE_FLOATING_CLOSE,
	MOBILE_FLOATING_OEPN,
	STORE_PIN_SUCCESS,
	COMMENTING,
} from "actions/home.action";

const INITIAL_STATE = {
	error: null,
	loading: true,
	removePin: 0,
	home: true,
	floating: false,
	commenting: false,
};

export default function homeReducer(state = INITIAL_STATE, action) {
	////console.log(action.type);
	switch (action.type) {
		case HOME_DID_MOUNT:
			console.log("HOME_DID_MOUNT");
			return {
				...state,
				home: true,
			};
		case HOME_WILL_UNMOUNT:
			console.log("HOME_WILL_UNMOUNT");
			return {
				...state,
				home: false,
			};
		case MOBILE_FLOATING_OEPN:
			return {
				...state,
				floating: true,
			};
		case MOBILE_FLOATING_CLOSE:
			return {
				...state,
				floating: false,
			};
		//게시글 리스트
		case FETCH_HOME:
			return {
				...state,
				home: state.home + 1,
				//loading: true,
			};
		case FETCH_HOME_SUCCESS:
			return {
				...state,
				payload: action.payload,
				loading: false,
			};
		case FETCH_HOME_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			};

		case COMMENTING:
			return {
				...state,
				commenting: action.payload,
			};

		/**
		 * 홈에 고정 업데이트
		 */
		case STORE_PIN_SUCCESS: {
			if (!state.payload) {
				return state;
			}

			// 기존 홈 고정 값 변경
			const payload = produce(state.payload, (payload) => {
				const { boards, vods } = payload;
				vods.forEach((vod) => (vod.is_pin = vod.title_no === action.payload.title_no)); // vod 홈 고정 값 변경
				boards.forEach((board) => (board.is_pin = board.title_no === action.payload.title_no)); // 게시글 홈 고정 값 변경
			});

			return {
				...state,
				payload,
			};
		}

		/**
		 * 고정해제
		 */
		case DESTORY_PIN_SUCCESS: {
			if (!state.payload) {
				return state;
			}

			// 기존 홈 고정 값 해제
			const payload = produce(state.payload, (payload) => {
				const { boards, vods } = payload;
				vods.forEach((vod) => vod.title_no === action.payload.title_no && (vod.is_pin = false)); // vod 홈 고정 해제
				boards.forEach((board) => board.title_no === action.payload.title_no && (board.is_pin = false)); // 게시글 홈 고정 해제
			});

			return {
				...state,
				payload,
			};
		}
		default:
			return state;
	}
}
