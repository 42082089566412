import React from "react";
import { withRouter } from "react-router";
import { isMobile } from "react-device-detect";
import { getLoginUrl } from "helpers";

export const ButtonLink = withRouter(({ history, to, target, state, children, className, replace, tip, isLogin }) => {
	if (isMobile) {
		return (
			<button
				type="button"
				onMouseDown={() => {
					// 로그인 체크 로직 추가
					if (isLogin == false && isLogin !== "undefined") {
						window.location.replace(getLoginUrl());
					} else {
						if (target) {
							window.open(to);
						} else {
							if (replace) {
								history.replace(to, state);
							} else {
								history.push(to, state);
							}
						}
					}
					
				}}
				className={className}
			>
				{children}
			</button>
		);
	} else {
		return (
			<button
				type="button"
				tip={tip}
				onClick={() => {
					// 로그인 체크 로직 추가
					if (isLogin == false && isLogin !== "undefined") { 
						window.location.replace(getLoginUrl());
					} else {
						if (target) {
							window.open(to);
						} else {
							history.push(to, state);
						}
					}
					
				}}
				className={className}
			>
				{children}
			</button>
		);
	}
});
