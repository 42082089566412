import { DOMAIN, SCKETC_DOMAIN } from "constant/config";
import { handleFetchErrors, handleFailedToFetch } from "../helpers";

//Post list
export const MODE_HOT_KEYWORD = "MODE_HOT_KEYWORD";
export const MODE_RECOMMEND_KEYWORD = "MODE_RECOMMEND_KEYWORD";
export const MODE_LATEST_KEYWORD = "MODE_LATEST_KEYWORD";

export const FETCH_AUTO_COMPLETE_SUCCESS = "FETCH_AUTO_COMPLETE_SUCCESS";
export const FETCH_AUTO_COMPLETE_FAILURE = "FETCH_AUTO_COMPLETE_FAILURE";

/*
 최근 검색어 정책(2023-03-07)
 - 해쉬태그 1차개선 작업으로 인해 _csk 구조가
 문자String.fromCharCode(12)문자String.fromCharCode(12)문자에서
 문자String.fromCharCode(12)falseString.fromCharCode(11)문자String.fromCharCode(12)true 형태로 변경
 그렇다보니 _csk 통검이랑 채널 검색 동시에 이슈 사항 발생할 수 있음 (아니 왜 여기 공통으로 써...)

*/
const RECENT_COOKIE_KEY = "_csk";



/**
 * 게시판리스트 불러오기
 * @param {*} bbs_no
 */
function setCookie(name, value, days, domain = DOMAIN) {
	var expires = "";
	if (days) {
		var date = new Date();
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
		expires = "; expires=" + date.toUTCString();
	}
	document.cookie = name + "=" + (value || "") + expires + "; path=/; domain=" + domain;
}
function getCookie(name) {
	var nameEQ = name + "=";
	var ca = document.cookie.split(";");
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == " ") c = c.substring(1, c.length);
		if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
	}
	return null;
}

export function fetchSearchHotKeyword() {
	return (dispatch) => {
		//최근검색어가 있는지
		//let csk = cookie.load(RECENT_COOKIE_KEY);
		let csk = getCookie(RECENT_COOKIE_KEY);
		// if (csk === "" || csk === undefined) {
		// 	//실시간 핫이슈
		// 	dispatch({ type: MODE_HOT_KEYWORD });
		//
		// 	const url = `${SCKETC_DOMAIN}/api.php?m=hotKeyword&v=1.0&c=UTF-8&_=${new Date().getTime()}`;
		// 	//로딩
		// 	fetch(url, {
		// 		credentials: "include",
		// 	})
		// 		.then(handleFetchErrors, handleFailedToFetch)
		// 		.then((response) => response.json())
		// 		.then((response) => {
		// 			dispatch({
		// 				type: FETCH_AUTO_COMPLETE_SUCCESS,
		// 				payload: {
		// 					mode: MODE_HOT_KEYWORD,
		// 					data: response.HOT,
		// 				},
		// 			});
		// 		})
		// 		.catch((error) => {
		// 			dispatch({
		// 				type: FETCH_AUTO_COMPLETE_FAILURE,
		// 				payload: error ? error : { code: 0, message: error },
		// 			});
		// 		});
		// } else {
		//최근 검색어
		if (csk === "" || csk === undefined || csk === null) {
			return;
		} else {
			const szCKKeyword = unescape(csk);
			const latestKeywordList = (szCKKeyword.indexOf(String.fromCharCode(11)) >= 0 || szCKKeyword.split(String.fromCharCode(12)).length === 2)   ? szCKKeyword.split(String.fromCharCode(11)) : szCKKeyword.split(String.fromCharCode(12));
			const latestKeyword = [];
			if(szCKKeyword.indexOf(String.fromCharCode(11)>=0|| szCKKeyword.split(String.fromCharCode(12)).length === 2)){
				latestKeywordList.map((keyword, index) => {
					latestKeyword.push(keyword.split(String.fromCharCode(12))[0]);
				});
			}
			else{
				let convertKeywordList = latestKeywordList.split(String.fromCharCode(12));
				convertKeywordList.map((keyword, index) => {
					latestKeyword.push(keyword);
				});
			}
			var uniqKeyword = latestKeyword.reduce(function(a, b) {
				if (a.indexOf(b) < 0) a.push(b);
				return a;
			}, []);
			
			dispatch({
				type: FETCH_AUTO_COMPLETE_SUCCESS,
				payload: {
					mode: MODE_LATEST_KEYWORD,
					data: uniqKeyword.splice(0, 10),
				},
			});
		}
	};
}

export function appendCSk(keyword) {
	keyword = keyword.trim();
	if (keyword === "" || keyword === undefined) {
		return;
	}
	let csk = getCookie(RECENT_COOKIE_KEY);
	let szCKKeyword,
		latestKeyword = [],
		latestKeywordJson = [];
	if (csk !== "" && csk !== undefined && csk !== null) {
		szCKKeyword = unescape(csk);
		if(szCKKeyword.indexOf(String.fromCharCode(11)) >= 0 || szCKKeyword.split(String.fromCharCode(12)).length === 2){
			const keywordList = szCKKeyword.split(String.fromCharCode(11))

			keywordList.map((keyword, index) => {
				latestKeyword.push(keyword.split(String.fromCharCode(12))[0]);
				latestKeywordJson.push(keyword);
			});
		}
		else{
			const keywordList = szCKKeyword.split(String.fromCharCode(12))

			keywordList.map((keyword, index) => {
				latestKeyword.push(keyword);
				latestKeywordJson.push(keyword+String.fromCharCode(12)+"false");
			});
		}

	}
	//추가
	latestKeyword.unshift(keyword);
	latestKeywordJson.unshift(keyword+String.fromCharCode(12)+"false");
	// var uniqKeyword = latestKeyword.reduce(function(a, b) {
	// 	if (a.indexOf(b) < 0) a.push(b);
	// 	return a;
	// }, []);

	var uniqKeywordList = latestKeywordJson.reduce(function(a,b){
		if(a.indexOf(b)<0) a.push(b);
		return a;
	}, [])

	uniqKeywordList = uniqKeywordList.splice(0, 10);
	szCKKeyword = uniqKeywordList.join(String.fromCharCode(11));
	csk = escape(szCKKeyword);

	// const expires = new Date();
	// expires.setDate(expires.getDate() + 1);
	// cookie.save(RECENT_COOKIE_KEY, csk, { domain: DOMAIN, path: "/", expires });
	setCookie(RECENT_COOKIE_KEY, csk, 1);
}

export function removeCSk(keyword) {
	return (dispatch) => {
		if (keyword === "" || keyword === undefined) {
			return;
		}
		let csk = getCookie(RECENT_COOKIE_KEY);
		let szCKKeyword,
			latestKeyword = [],
			latestKeywordJson = [];
		let removeKeywordJson = "";
		if (csk !== "" && csk !== undefined && csk !== null) {
			szCKKeyword = unescape(csk);
			const keywordList =  (szCKKeyword.indexOf(String.fromCharCode(11)) >= 0 || szCKKeyword.split(String.fromCharCode(12)).length === 2) ? szCKKeyword.split(String.fromCharCode(11)) : szCKKeyword.split(String.fromCharCode(12));
			if(szCKKeyword.indexOf(String.fromCharCode(11)) >= 0  || szCKKeyword.split(String.fromCharCode(12)).length === 2 ){
				keywordList.map((word, index) => {
					let splitword = word.split(String.fromCharCode(12));
					if(splitword[0] === keyword){
						removeKeywordJson = keyword+String.fromCharCode(12)+splitword[1];
					} 
					latestKeyword.push(word.split(String.fromCharCode(12))[0]);
					latestKeywordJson.push(word);
				});
			}
			else{
				keywordList.map((word, index) => {
					let splitword = word
					if(splitword === keyword){
						removeKeywordJson = keyword+String.fromCharCode(12)+"false";
					} 
					latestKeyword.push(word);
					latestKeywordJson.push(word+String.fromCharCode(12)+"false");
				});
			}
		}

		//제거
		const newLatestKeyword = latestKeywordJson.filter((keywordJson) => {
			return !(keywordJson === removeKeywordJson);
		});
		szCKKeyword = newLatestKeyword.join(String.fromCharCode(11));
		csk = escape(szCKKeyword);
		setCookie(RECENT_COOKIE_KEY, csk, 1);

		let convertNewLatestKeyword
		convertNewLatestKeyword = newLatestKeyword.map((keyword, index)=>{
			return keyword.split(String.fromCharCode(12))[0];
		})

		var uniqKeyword = convertNewLatestKeyword.reduce(function(a, b) {
			if (a.indexOf(b) < 0) a.push(b);
			return a;
		}, []);

	

		dispatch({
			type: FETCH_AUTO_COMPLETE_SUCCESS,
			payload: {
				mode: MODE_LATEST_KEYWORD,
				data: uniqKeyword,
			},
		});
	};
}

export function fetchSearchRecommendKeyword(keword) {
	return (dispatch) => {
		dispatch({ type: MODE_RECOMMEND_KEYWORD });

		const url = `${SCKETC_DOMAIN}/api.php?m=autoJaso&v=1.0&c=UTF-8&d=${keword}&_=${new Date().getTime()}`;
		//로딩
		fetch(url, {
			credentials: "include",
		})
			.then(handleFetchErrors, handleFailedToFetch)
			.then((response) => response.json())
			.then((response) => {
				dispatch({
					type: FETCH_AUTO_COMPLETE_SUCCESS,
					payload: {
						mode: MODE_RECOMMEND_KEYWORD,
						data: response.list,
					},
				});
			})
			.catch((error) => {
				dispatch({
					type: FETCH_AUTO_COMPLETE_FAILURE,
					payload: error ? error : { code: 0, message: error },
				});
			});
	};
}
