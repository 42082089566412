import produce from "immer";
import {
	FETCH_USERCLIP_BLACKLIST,
	FETCH_USERCLIP_BLACKLIST_SUCCESS,
    FETCH_USERCLIP_BLACKLIST_FAILURE,
    STORE_USERCLIP_BLACKLIST_SUCCESS,
    DELETE_USERCLIP_BLACKLIST_SUCCESS,
    STORE_USERCLIP_BLACKLIST_FAILURE,
    DELETE_USERCLIP_BLACKLIST_FAILURE
} from "actions/setting.userclip.blacklist.action";

const INITIAL_STATE = {
	error: null,
	loading: true,
	name: "",
	userClipInfo: {
        meta: {
            current_page : 1,
            per_page : 20,
            total : 0
        }
    }
};


const settingUserClipBlackListReducer = produce((state, action) => {
    switch (action.type) {
		case FETCH_USERCLIP_BLACKLIST:
            state.error = null;
            state.loading = true;
            break;
		case FETCH_USERCLIP_BLACKLIST_SUCCESS:
        case STORE_USERCLIP_BLACKLIST_SUCCESS:
        case DELETE_USERCLIP_BLACKLIST_SUCCESS:
            state.error = null;
            state.loading = false;
            state.userClipInfo = action.payload;
            break;
        case FETCH_USERCLIP_BLACKLIST_FAILURE:
        case STORE_USERCLIP_BLACKLIST_FAILURE:
        case DELETE_USERCLIP_BLACKLIST_FAILURE:
            state.loading = false;
            state.error = action.payload;
            break;
        default:
			return;
    }
}, INITIAL_STATE);

export default settingUserClipBlackListReducer;
