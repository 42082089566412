import classNames from "classnames";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ProfileImage2 } from "components/ImageHandle";
import { SUPPORTER } from "constant/position.path";
import Nickname from "components/contents/Nickname";
import { JAVASCRIPT_VOID } from "constant/config";

const StickerTopMore = (props) => {
	
	const { t } = useTranslation();

	const [ open_id, setOpen_id ] = useState(null);
	const { sticker_top } = props;
	const sticker_top10 = sticker_top.slice(0, 10);
	const sticker_top20 = sticker_top.slice(10, 20);
	
	const handleProfileOpen = (id) => {
		setOpen_id(id)
	};

	return (
		<div
			className="ui-pop layer_rank-list active"
			style={{ position: "absolute", top: 58, left: 0 }}
			onClick={(e) => {
				e.stopPropagation();
				e.nativeEvent.stopImmediatePropagation();
				setOpen_id(null);
			}}
		>
			<div className="pop-title">
				<h3>
					<Trans>서포터</Trans>
				</h3>
			</div>
			<div className="pop-body rank-list">
				<ul>
					{sticker_top10.map((sticker, idx) => {
						return (
							<li key={idx} className={classNames({ first: idx === 0, top: idx >= 0 && idx < 5 })}>
								<ProfileImage2 userId={sticker.user_id} className="thum" src={sticker.profile_image} position={SUPPORTER}/>
								<Nickname
									open={sticker.user_id === open_id}
									onOpen={handleProfileOpen}
									stopPropagation={true}
									item={{
										user_id: sticker.user_id,
										user_nick: sticker.user_nick,
									}}
									open_id={open_id}
								>
									<a href={JAVASCRIPT_VOID}>{sticker.user_nick}</a>
								</Nickname>
								<em>{idx === 0 ? t("회장") : (idx === 1 ? t("부회장") : t("{{num}}등", { num: idx + 1 }))}</em>
							</li>
						);
					})}
				</ul>
				{sticker_top20.length > 0 && (
					<ul>
						{sticker_top20.map((sticker, idx) => {
							return (
								<li key={idx} className="thum">
									<ProfileImage2 userId={sticker.user_id} className="thum" src={sticker.profile_image} position={SUPPORTER}/>
									<Nickname
										open={sticker.user_id === open_id}
										onOpen={handleProfileOpen}
										stopPropagation={true}
										item={{
											user_id: sticker.user_id,
											user_nick: sticker.user_nick,
										}}
										open_id={open_id}
									>
										<a href={JAVASCRIPT_VOID}>{sticker.user_nick}</a>
									</Nickname>
									<em>{t("{{num}}등", { num: idx + 11 })}</em>
								</li>
							);
						})}
					</ul>
				)}
			</div>
			<a href={JAVASCRIPT_VOID} className="pop-close" onClick={props.close}>
				<span>
					<Trans>닫기</Trans>
				</span>
			</a>
		</div>
	);

	
}

export default StickerTopMore;